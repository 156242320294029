// Generated by nuxt-prepare
export const preparedConfigurations = {
  "sk": {
    "store.name": "FIREMNEREALITY",
    "text.general.phone": "+421 908 293 038",
    "text.index.services.title": "Sme experti v oblasti komerčných nehnuteľností",
    "text.estateListingBanner.office.title": "Poraďte sa s odborníkom",
    "text.services.mainContent": "<p>CBRE je najväčšou svetovou poradenskou a investičnou spoločnosťou v oblasti komerčných nehnuteľností. Na Slovensku <strong>pôsobíme viac ako 22 rokov</strong> a zamestnávame viac ako 300 profesionálov v Bratislave, Košiciach a Banskej Bystrici. <br />Celkovým <strong>obratom viac ako 30 miliónov eur</strong> sa radí medzi <strong>top 3 konzultačné spoločnosti pôsobiace na Slovensku.</strong></p>\r\n<p>Ak vás zaujíma svet nehnuteľností, pridajte sa k nám a rozvíjajte svoju kariéru v <a href=\"https://www.cbre.sk/kariera\" target=\"_blank\" rel=\"noopener\">úspešnej globálnej spoločnosti.</a></p>\r\n<p>K dokonalému výsledku využívame aktuálne dáta a analýzy, znalosť trhu a overené riešenia, ktoré <strong>zvyšujú ziskovosť našich klientov.</strong> Sú nimi všetky spoločnosti, ktoré potrebujú alebo vlastnia kancelárie, obchodné prevádzky, nákupné centrá alebo skladové priestory. Do každého biznisového vzťahu vnášame overené globálne postupy, dlhoročné lokálne skúsenosti a profesionalitu. Poskytujeme komplexné služby <strong>pre investorov a nájomcov od malých podnikateľov, cez globálne firmy až po ikonické slovenské značky.</strong></p>\r\n<p><strong>Charakterizuje nás komplexný prístup tzv. „one stop shop“,</strong> všetko na jednom mieste. Ponúkame širokú škálu integrovaných služieb v oblasti komerčných realít, vrátane transakcií a projektového manažmentu, správy nehnuteľností, investičného manažmentu, posudzovania a ohodnocovania, lízingu nehnuteľností, strategického poradenstva a omnoho viac. V súčasnosti CBRE manažuje na Slovensku <strong>viac ako 875 tis. m<sup>2</sup> komerčných priestorov a spravujeme viac ako 45 budov.</strong></p>",
    "store.email": "sk.klientskecentrum@cbre.com",
    "text.general.email": "sk.klientskecentrum@cbre.com",
    "text.index.statistics.title": "Pridajte sa k viac ako 1 800 spokojným klientom",
    "text.estateListingBanner.office.content": "Naši špecialisti a konzulanti vám pomôžu vyjednať lepšie podmienky, zabezpečeria lepšie pracovné prostredie a omnoho viac.",
    "text.services.benefits": "<p>Dlhá pôsobnosť a viac ako 22 rokov skúseností</p>\r\n<p>Analýzy, prieskumy a dáta tvoria základ kvalitného riešenia</p>\r\n<p>Dlhodobo najvyšší market share</p>\r\n<p>Riešenia šité na mieru presne podľa vašich potrieb</p>\r\n<p>Regionálne pokrytie odborníkmi a regionálna znalosť</p>\r\n<p>Digitálne technológie a inovácie sú našou každodennou súčasťou</p>",
    "text.general.contact_days": "Pondelok - Piatok",
    "text.index.statistics.description": "Meníme vaše potreby na príležitosti. Za 22 rokov sme na Slovensku dosiahli rekordné výsledky. Hlavne vďaka vám, našim klientom.",
    "text.estateListingBanner.office.buttonText": "Zadajte vaše požiadavky",
    "text.general.contact_days_short": "Po - Pi",
    "text.index.bestOffers.title": "Najžiadanejšie ponuky na trhu",
    "text.estateListingBanner.retail.title": "Poraďte sa s odborníkom",
    "text.general.contact_hours": "9:00 - 17:00",
    "text.index.bestOffers.label": "CBRE Odporúča",
    "text.estateListingBanner.retail.content": "Naši špecialisti a konzulanti vám pomôžu vyjednať lepšie podmienky, zabezpečeria lepšie pracovné prostredie a omnoho viac.",
    "text.general.footer_quote": "<p>Realitno-konzultačná spoločnosť CBRE pôsobí na Slovensku už <strong>viac ako 22 rokov</strong> a zamestnáva viac ako <strong>300 odborníkov</strong>, ktorých expertíza, dlhodobé skúsenosti a dokonalá znalosť trhu sú predpokladom najlepšej <strong>finančnej návratnosti vašich priestorov.</strong></p>",
    "text.index.references.title": "Referencie",
    "text.estateListingBanner.retail.buttonText": "Zadajte vaše požiadavky",
    "text.index.references.label": "Úspešní klienti rastú s nami",
    "text.estateListingBanner.warehouse.title": "Poraďte sa s odborníkom",
    "text.index.articles.title": "CBRE Novinky",
    "text.estateListingBanner.warehouse.content": "Naši špecialisti a konzulanti vám pomôžu vyjednať lepšie podmienky, zabezpečeria lepšie pracovné prostredie a omnoho viac.",
    "store.notificationsEmail": "",
    "text.index.articles.label": "Novinky zo sveta komerčných realít",
    "text.estateListingBanner.warehouse.buttonText": "Zadajte vaše požiadavky",
    "text.index.clients.title": "Podporujeme pracovný svet budúcnosti aj týmto klientom",
    "store.phone": "+421 908 293 038",
    "text.index.clients.additionalText": "+ 1 800 ďalších",
    "store.company": "CBRE s.r.o.",
    "store.address": "Staromestská 3",
    "store.postCode": "811 03",
    "store.city": "Bratislava",
    "store.countryId": "Slovensko",
    "store.ico": "35819804",
    "store.dic": "2020285388",
    "store.icDph": "SK2020285388",
    "store.registerInfo": "Obchodný register Mestského súdu Bratislava III, oddiel: Sro, vložka č. 24869/B",
    "customEmailReplySubject": "Potvrdenie prijatia kontaktného formulára",
    "customEmailReplyText": "Dobry den\r\n\r\nĎakujeme!!!"
  },
  "en": {
    "store.name": "EN - FIREMNEREALITY",
    "text.general.phone": "+421 908 293 038",
    "text.index.services.title": "We are experts in the field of commercial real estate.",
    "text.estateListingBanner.office.title": "Consult with an expert",
    "text.services.mainContent": "<p>CBRE is the world's largest advisory and investment company in commercial real estate. Operating in Slovakia <strong>for over 22 years</strong>, we employ <strong>over 300</strong> professionals in Bratislava, Košice, and Banská Bystrica. With a total turnover <strong>of over 30 million €</strong>, we rank among the top 3 consulting companies operating in Slovakia.</p>\r\n<p>If you are interested in the world of real estate, join us and develop your career in a <a href=\"https://www.cbre.sk/kariera\">successful global company</a>.</p>\r\n<p>To achieve perfect results, we utilize current data and analyses, market knowledge, and proven solutions that <strong>enhance the profitability of our clients</strong>. These include all companies that need or own offices, commercial premises, shopping centers, or warehouse spaces. In every business relationship, we bring proven global practices, years of local experience, and professionalism. We provide comprehensive services <strong>for investors and tenants ranging from small businesses, through global corporations, to iconic Slovak brands.</strong></p>\r\n<p>We are characterized by a comprehensive \"one-stop-shop\" approach, offering everything in one place. We provide a wide range of integrated services in the field of commercial real estate, including transactions and project management, property management, investment management, assessment and valuation, property leasing, strategic consulting, and much more. CBRE currently manages <strong>more than 875,000 sqm of commercial space in Slovakia and we manage more than 45 buildings.</strong></p>",
    "store.email": "sk.klientskecentrum@cbre.com",
    "text.general.email": "sk.klientskecentrum@cbre.com",
    "text.index.statistics.title": "Join more than 1,800 satisfied clients.",
    "text.estateListingBanner.office.content": "Our specialists and consultants will help you negotiate better terms, ensure a better working environment, and much more.",
    "text.services.benefits": "<p>Long tenure and more than 22 years of experience</p>\r\n<p>Analyzes, surveys and data form the basis of a quality solution</p>\r\n<p>Long-term highest market share</p>\r\n<p>Tailor-made solutions exactly according to your needs</p>\r\n<p>Regional coverage by experts and regional knowledge</p>\r\n<p>Digital technologies and innovations are part of our everyday life</p>",
    "text.general.contact_days": "Monday - Friday",
    "text.index.statistics.description": "We turn your needs into opportunities. Over 22 years, we have achieved record results in Slovakia, mainly thanks to you, our clients.",
    "text.estateListingBanner.office.buttonText": "Enter your requirements",
    "text.general.contact_days_short": "Mon - Fri",
    "text.index.bestOffers.title": "The most sought-after offers on the market.",
    "text.estateListingBanner.retail.title": "Consult with an expert",
    "text.general.contact_hours": "9:00 - 17:00",
    "text.index.bestOffers.label": "CBRE Recommends",
    "text.estateListingBanner.retail.content": "Our specialists and consultants will help you negotiate better terms, secure a better working environment, and much more.",
    "text.general.footer_quote": "<p>The real estate consultancy company CBRE has been operating in Slovakia <strong>for over 22 years</strong> and employs more than <strong>300 professionals</strong>. Their expertise, extensive experience, and perfect market knowledge are prerequisites for the best <strong>financial returns on your spaces.</strong></p>",
    "text.index.references.title": "Case studies",
    "text.estateListingBanner.retail.buttonText": "Enter your requirements",
    "text.index.references.label": "Successful clients grow with us.",
    "text.estateListingBanner.warehouse.title": "Consult with an expert",
    "text.index.articles.title": "CBRE News",
    "text.estateListingBanner.warehouse.content": "Our specialists and consultants will help you negotiate better terms, secure a better working environment, and much more.",
    "store.notificationsEmail": "",
    "text.index.articles.label": "News from the world of commercial real estate.",
    "text.estateListingBanner.warehouse.buttonText": "Enter your requirements",
    "text.index.clients.title": "We support the future of the working world, including these clients.",
    "store.phone": "+421 908 293 038",
    "text.index.clients.additionalText": "+ 1 800 more",
    "store.company": "CBRE s.r.o.",
    "store.address": "Staromestská 3",
    "store.postCode": "811 03",
    "store.city": "Bratislava",
    "store.countryId": "Slovensko",
    "store.ico": "35819804",
    "store.dic": "2020285388",
    "store.icDph": "SK2020285388",
    "store.registerInfo": "Obchodný register Mestského súdu Bratislava III, oddiel: Sro, vložka č. 24869/B",
    "customEmailReplySubject": "Confirmation",
    "customEmailReplyText": "Thank you!!!"
  }
}
export type PreparedConfigurations = typeof preparedConfigurations
export const footerAdditionalLinksBox = {
  "sk": {
    "box_items": [
      {
        "id": 293,
        "box_id": 1,
        "name": "Office roka",
        "url": "https://www.officeroka.sk/",
        "image": "",
        "has_subitems": false,
        "subitems_type": null,
        "product_id": null,
        "category_id": null,
        "article_id": null,
        "section_id": null,
        "manufacturer_id": null,
        "brand_id": null,
        "campaign_id": null,
        "benefit_id": null,
        "present_id": null,
        "tag_id": null,
        "testimonial_id": null,
        "faq_id": null,
        "faq_section_id": null,
        "sort": 1,
        "active": true,
        "module_data": {
          "custom_is_external": "1"
        },
        "language_active": true,
        "valid_from": null,
        "valid_until": null,
        "annual_repetition": false,
        "box_subitems": []
      }
    ],
    "id": 1,
    "name": "Pätička - Dôležité odkazy, doplnkové",
    "type": "custom"
  },
  "en": {
    "box_items": [
      {
        "id": 293,
        "box_id": 1,
        "name": "Office of the year",
        "url": "https://www.officeroka.sk/",
        "image": "",
        "has_subitems": false,
        "subitems_type": null,
        "product_id": null,
        "category_id": null,
        "article_id": null,
        "section_id": null,
        "manufacturer_id": null,
        "brand_id": null,
        "campaign_id": null,
        "benefit_id": null,
        "present_id": null,
        "tag_id": null,
        "testimonial_id": null,
        "faq_id": null,
        "faq_section_id": null,
        "sort": 1,
        "active": true,
        "module_data": {
          "custom_is_external": "1"
        },
        "language_active": true,
        "valid_from": null,
        "valid_until": null,
        "annual_repetition": false,
        "box_subitems": [],
        "_locale": "en_US"
      }
    ],
    "id": 1,
    "name": "Pätička - Dôležité odkazy, doplnkové",
    "type": "custom"
  }
}
export type FooterAdditionalLinksBox = typeof footerAdditionalLinksBox
export const preparedRedirects = {
  "/en/offices/digital-park-phase-i-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "digital-park-phase-i-bratislava",
      "id": 112
    }
  },
  "/en/offices/cbc-iii-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-iii-bratislava",
      "id": 57
    }
  },
  "/en/offices/aupark-tower-ba-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aupark-tower-bratislava-bratislava",
      "id": 435
    }
  },
  "/en/offices/polus-tower-i-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "myhive-vajnorska-tower-i-bratislava",
      "id": 49
    }
  },
  "/en/offices/carlton-savoy-2-mostova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "carlton-savoy-2-bratislava",
      "id": 48
    }
  },
  "/en/offices/lakeside-park-01-tomasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "lakeside-park-01-bratislava",
      "id": 431
    }
  },
  "/en/offices/galvaniho-bc-v-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-v-bratislava",
      "id": 1242
    }
  },
  "/en/offices/galvaniho-bc-ii-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-ii-bratislava",
      "id": 12
    }
  },
  "/en/offices/aruba-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aruba-bratislava",
      "id": 104
    }
  },
  "/en/offices/galvaniho-bc-iv-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-iv-bratislava",
      "id": 11
    }
  },
  "/en/offices/galvaniho-bc-i-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-i-bratislava",
      "id": 401
    }
  },
  "/en/offices/cbc-iv-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-iv-bratislava",
      "id": 524
    }
  },
  "/en/offices/westend-gate-object-a-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-gate-object-a-bratislava",
      "id": 1255
    }
  },
  "/en/offices/polus-tower-ii-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "myhive-vajnorska-tower-ii-bratislava",
      "id": 380
    }
  },
  "/en/offices/digital-park-phase-ii-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "digital-park-phase-ii-bratislava",
      "id": 97
    }
  },
  "/en/offices/eurovea-central-2-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "eurovea-central-2-bratislava",
      "id": 1273
    }
  },
  "/en/offices/eurovea-central-3-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "eurovea-central-3-bratislava",
      "id": 1271
    }
  },
  "/en/offices/park-one-namestie-1-maja-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "park-one-bratislava",
      "id": 1519
    }
  },
  "/en/offices/jarosova-oc-jarosova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "jarosova-oc-sublease-bratislava",
      "id": 319
    }
  },
  "/en/offices/bbc-v-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bbc-v-bratislava",
      "id": 432
    }
  },
  "/en/offices/cbc-v-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-v-bratislava",
      "id": 58
    }
  },
  "/en/offices/trade-center-kosice-trieda-snp-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trade-center-kosice-kosice",
      "id": 220
    }
  },
  "/en/offices/aupark-tower-kosice-protifasistickych-bojovnikov-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aupark-tower-kosice-kosice",
      "id": 292
    }
  },
  "/en/offices/eurovea-central-1-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "eurovea-central-1-bratislava",
      "id": 82
    }
  },
  "/en/offices/bbc-i-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bbc-i-bratislava",
      "id": 433
    }
  },
  "/en/offices/bbc-1-plus-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bbc-1-plus-bratislava",
      "id": 434
    }
  },
  "/en/offices/apollo-bc-ii-block-a-b-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-bc-ii-block-a-b-bratislava",
      "id": 1266
    }
  },
  "/en/offices/apollo-bc-ii-block-c-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-bc-ii-block-c-bratislava",
      "id": 1268
    }
  },
  "/en/offices/apollo-bc-ii-block-e-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-bc-ii-block-e-bratislava",
      "id": 1406
    }
  },
  "/en/offices/green-point-offices-block-f-g-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-2-green-point-offices-block-f-g-bratislava",
      "id": 1417
    }
  },
  "/en/offices/green-point-offices-block-h-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-2-green-point-offices-block-h-bratislava",
      "id": 1416
    }
  },
  "/en/offices/westend-gate-object-b-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-gate-object-b-bratislava",
      "id": 392
    }
  },
  "/en/offices/westend-tower-dubravska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-tower-bratislava",
      "id": 430
    }
  },
  "/en/offices/tower-115-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tower-115-bratislava",
      "id": 26
    }
  },
  "/en/offices/westend-square-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-square-bratislava",
      "id": 391
    }
  },
  "/en/offices/h-business-center-roznavska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "h-business-center-bratislava",
      "id": 369
    }
  },
  "/en/offices/westend-court-dubravska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-court-bratislava",
      "id": 33
    }
  },
  "/en/offices/zuckermandel-river-side-zizkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zuckermandel-river-side-ca-bratislava",
      "id": 6
    }
  },
  "/en/offices/rosum-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "rosum-bratislava",
      "id": 101
    }
  },
  "/en/offices/cbc-i-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-i-bratislava",
      "id": 73
    }
  },
  "/en/offices/cbc-ii-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-ii-bratislava",
      "id": 1267
    }
  },
  "/en/offices/zelezniciarska-18-zelezniciarska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zelezniciarska-18-bratislava",
      "id": 2202
    }
  },
  "/en/offices/zamocka-34-zamocka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zamocka-34-bratislava",
      "id": 21
    }
  },
  "/en/offices/zochova-6-8-zochova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zochova-6-8-bratislava",
      "id": 497
    }
  },
  "/en/offices/zochova-5-zochova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zochova-5-bratislava",
      "id": 498
    }
  },
  "/en/offices/omnipolis-trnavska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "omnipolis-bratislava",
      "id": 168
    }
  },
  "/en/offices/ab-tuhovska-tuhovska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-tuhovska-bratislava",
      "id": 9
    }
  },
  "/en/offices/europeum-suche-myto-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "europeum-bratislava",
      "id": 29
    }
  },
  "/en/offices/teslova-19-teslova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "teslova-19-bratislava",
      "id": 203
    }
  },
  "/en/offices/lamacska-cesta-43-jaga-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "lamacska-cesta-45-jaga-bratislava",
      "id": 172
    }
  },
  "/en/offices/microstep-vajnorska-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "microstep-vajnorska-bratislava",
      "id": 2209
    }
  },
  "/en/offices/stefanikova-8-stefanikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stefanikova-8-bratislava",
      "id": 461
    }
  },
  "/en/offices/business-center-tesla-ii-moldavska-cesta-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-center-tesla-ii-kosice",
      "id": 1359
    }
  },
  "/en/offices/business-center-rozvojova-kosice-rozvojova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-center-rozvojova-kosice-kosice",
      "id": 1503
    }
  },
  "/en/offices/cassofin-ii-kosice-letna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cassofin-ii-kosice-kosice",
      "id": 1497
    }
  },
  "/en/offices/tabacka-kosice-strojarenska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tabacka-kosice-kosice",
      "id": 2216
    }
  },
  "/en/offices/administrativne-priestory-hlavna-kosice-hlavna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hlavna-117-kosice-kosice",
      "id": 1285
    }
  },
  "/en/offices/podnikatelske-centrum-kriva-ii-kosice-kriva-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-kriva-ii-kosice-kosice",
      "id": 1511
    }
  },
  "/en/offices/aircraft-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aircraft-bratislava",
      "id": 52
    }
  },
  "/en/offices/kerametal-jasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kerametal-bratislava",
      "id": 1565
    }
  },
  "/en/offices/air-offices-pestovatelska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "air-offices-bratislava",
      "id": 323
    }
  },
  "/en/offices/reding-tower-2-racianska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "reding-tower-2-bratislava",
      "id": 318
    }
  },
  "/en/offices/reding-tower-1-racianska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "reding-tower-1-bratislava",
      "id": 109
    }
  },
  "/en/offices/panorama-business-iii-landererova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "landererova-12-bratislava",
      "id": 502
    }
  },
  "/en/offices/gorkeho-4-gorkeho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gorkeho-4-bratislava",
      "id": 477
    }
  },
  "/en/offices/stein-office-part-blumentalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "steinerka-bratislava",
      "id": 1514
    }
  },
  "/en/offices/technopol-biznisuite-kutlikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "technopol-biznisuite-bratislava",
      "id": 147
    }
  },
  "/en/offices/blumental-office-part-phase-ii-radlinskeho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "blumental-office-part-phase-ii-bratislava",
      "id": 370
    }
  },
  "/en/offices/blumental-office-part-phase-i-mytna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "blumental-office-part-phase-i-bratislava",
      "id": 317
    }
  },
  "/en/offices/central-high-rise-metodova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "central-high-rise-bratislava",
      "id": 13
    }
  },
  "/en/offices/office-box-mokran-zahon-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "office-box-bratislava",
      "id": 1458
    }
  },
  "/en/offices/extrend-tomasikova-tomasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "best-tomasikova-bratislava",
      "id": 19
    }
  },
  "/en/offices/galvaniho-bc-iii-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-iii-bratislava",
      "id": 4
    }
  },
  "/en/offices/podnikatelske-centrum-kriva-i-kosice-kriva-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-kriva-i-kosice-kosice",
      "id": 1341
    }
  },
  "/en/offices/hpk-engineering-kosice-bozeny-nemcovej-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hpk-engineering-kosice-kosice",
      "id": 242
    }
  },
  "/en/offices/aston-building-kosice-werferova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aston-building-kosice-kosice",
      "id": 1496
    }
  },
  "/en/offices/zuckermandel-hill-side-zizkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zuckermandel-hill-side-cx-bratislava",
      "id": 40
    }
  },
  "/en/offices/castor-pollux-luxor-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "castor-pollux-luxor-bratislava",
      "id": 15
    }
  },
  "/en/offices/hlavne-namestie-5-kooperativa-building-hlavne-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "roland-offices-bratislava",
      "id": 476
    }
  },
  "/en/offices/panenska-13-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-13-bratislava",
      "id": 192
    }
  },
  "/en/offices/westend-plazza-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-plazza-bratislava",
      "id": 1390
    }
  },
  "/en/offices/twin-city-tower-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "twin-city-tower-bratislava",
      "id": 1433
    }
  },
  "/en/offices/twin-city-b-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "twin-city-b-bratislava",
      "id": 480
    }
  },
  "/en/offices/twin-city-a-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "twin-city-a-bratislava",
      "id": 454
    }
  },
  "/en/offices/sky-park-offices-culenova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sky-park-offices-bratislava",
      "id": 1383
    }
  },
  "/en/offices/einsteinova-business-center-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "einsteinova-business-center-bratislava",
      "id": 46
    }
  },
  "/en/offices/panenska-8-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-8-bratislava",
      "id": 114
    }
  },
  "/en/offices/bbc-iv-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pressburg-tower-bratislava",
      "id": 5
    }
  },
  "/en/offices/namestie-snp-15-namestie-snp-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "namestie-snp-15-bratislava",
      "id": 126
    }
  },
  "/en/offices/grosslingova-7-grosslingova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "grosslingova-7-bratislava",
      "id": 1548
    }
  },
  "/en/offices/central-v-sevcenkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "central-v-bratislava",
      "id": 148
    }
  },
  "/en/offices/hranicna-18-hranicna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hranicna-18-bratislava",
      "id": 1479
    }
  },
  "/en/offices/kolarska-6-kolarska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kolarska-6-bratislava",
      "id": 14
    }
  },
  "/en/offices/tower-5-kalinciakova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tower-5-bratislava",
      "id": 34
    }
  },
  "/en/offices/safranova-zahrada-ii-kosice-skladna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "safranova-zahrada-ii-kosice-kosice",
      "id": 1365
    }
  },
  "/en/offices/obuvnicka-basta-hviezdoslavovo-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "obuvnicka-basta-bratislava",
      "id": 162
    }
  },
  "/en/offices/bc-tehelne-pole-trnavska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bc-tehelne-pole-bratislava",
      "id": 138
    }
  },
  "/en/offices/laurinska-18-laurinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "laurinska-18-bratislava",
      "id": 1441
    }
  },
  "/en/offices/milton-house-soltesovej-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "milton-house-bratislava",
      "id": 103
    }
  },
  "/en/offices/office-142-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "office-142-bratislava",
      "id": 1296
    }
  },
  "/en/offices/administrativne-priestory-masiarska-masiarska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "masiarska-kosice-administrativne-priestory-kosice",
      "id": 1361
    }
  },
  "/en/offices/kancelarsky-priestor-stanicne-namestie-kosice-stanicne-namestie-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarsky-priestor-stanicne-namestie-kosice-kosice",
      "id": 1358
    }
  },
  "/en/offices/zilinska-7-9-zilinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zilinska-7-9-bratislava",
      "id": 281
    }
  },
  "/en/offices/vidor-palace-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vidor-palace-bratislava",
      "id": 102
    }
  },
  "/en/offices/pressburg-trade-center-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pressburg-trade-center-bratislava",
      "id": 39
    }
  },
  "/en/offices/michalska-burghers-house-michalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "michalska-burghers-house-bratislava",
      "id": 20
    }
  },
  "/en/offices/kosicka-56-kosicka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kosicka-56-bratislava",
      "id": 94
    }
  },
  "/en/offices/forum-bc-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "forum-bc-bratislava",
      "id": 7
    }
  },
  "/en/offices/fams-kosice-szakkayho-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "fams-kosice-kosice",
      "id": 1488
    }
  },
  "/en/offices/cubus-fazulova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cubus-bratislava",
      "id": 59
    }
  },
  "/en/offices/business-center-kosice-iii-sturova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-centre-kosice-etapa-ii-kosice",
      "id": 2223
    }
  },
  "/en/offices/stefanium-stefanikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stefanium-bratislava",
      "id": 164
    }
  },
  "/en/offices/administrativne-priestory-rozvojova-kosice-rozvojova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativne-priestory-rozvojova-kosice-kosice",
      "id": 1594
    }
  },
  "/en/offices/podnikatelske-centrum-gemerska-kosice-gemerska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-gemerska-kosice-kosice",
      "id": 2207
    }
  },
  "/en/offices/podnikatelske-centrum-skolpo-kosice-popradska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-skolpo-kosice-kosice",
      "id": 1568
    }
  },
  "/en/offices/podnikatelske-centrum-mei-kosice-letna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-mei-kosice-kosice",
      "id": 2203
    }
  },
  "/en/offices/kancelarie-kysucka-kosice-kysucka-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-kysucka-kosice-kosice",
      "id": 1576
    }
  },
  "/en/offices/biznis-park-rampova-kosice-rampova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "biznis-park-rampova-kosice-kosice",
      "id": 1362
    }
  },
  "/en/offices/kancelarske-priestory-hlavna-kosice-hlavna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hlavna-114-kosice-kosice",
      "id": 1345
    }
  },
  "/en/offices/ab-onyx-poprad-namestie-sv-egidia-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-onyx-poprad-poprad",
      "id": 1508
    }
  },
  "/en/offices/ab-slovenska-presov-slovenska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-slovenska-presov-presov",
      "id": 1340
    }
  },
  "/en/offices/kancelarie-stavoprojekt-presov-duchnovicovo-namestie-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-stavoprojekt-presov-presov",
      "id": 1484
    }
  },
  "/en/offices/kancelarske-priestory-levocska-poprad-levocska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarske-priestory-levocska-poprad-poprad",
      "id": 1337
    }
  },
  "/en/offices/administrativne-a-obchodne-priestory-vranov-nad-toplou-namestie-slobody-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativno-obchodne-centrum-vranov-nad-toplou-vranov-nad-toplou",
      "id": 1476
    }
  },
  "/en/offices/ab-vzorodev-presov-levocska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-center-presov-presov",
      "id": 1349
    }
  },
  "/en/offices/ab-intes-poprad-namestie-svateho-egidia-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-intes-poprad-poprad",
      "id": 1575
    }
  },
  "/en/offices/emm-building-sekurisova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "emm-building-bratislava",
      "id": 1537
    }
  },
  "/en/offices/solar-budatinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "solar-bratislava",
      "id": 1319
    }
  },
  "/en/offices/carlton-savoy-4-mostova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "carlton-savoy-4-bratislava",
      "id": 1277
    }
  },
  "/en/offices/river-park-block-f-dvorakovo-nabrezie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "river-park-block-f-bratislava",
      "id": 1258
    }
  },
  "/en/offices/panenska-5-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-5-bratislava",
      "id": 1529
    }
  },
  "/en/offices/einpark-offices-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "einpark-offices-bratislava",
      "id": 1243
    }
  },
  "/en/offices/slavicie-udolie-104-slavicie-udolie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "slavicie-udolie-106-bratislava",
      "id": 1251
    }
  },
  "/en/offices/za-tehelnou-9-za-tehelnou-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "za-tehelnou-9-bratislava",
      "id": 1352
    }
  },
  "/en/offices/rybnicna-40-rybnicna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "rybnicna-40-bratislava",
      "id": 54
    }
  },
  "/en/offices/bittner-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bittner-bratislava",
      "id": 1454
    }
  },
  "/en/offices/river-park-block-c-dvorakovo-nabrezie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "river-park-block-c-bratislava",
      "id": 25
    }
  },
  "/en/offices/ab-konventna-9-konventna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-konventna-9-bratislava",
      "id": 1287
    }
  },
  "/en/offices/pri-suchom-mlyne-pri-suchom-mlyne-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pri-suchom-mlyne-bratislava",
      "id": 1329
    }
  },
  "/en/offices/hodoninska-25-hodoninska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hodoninska-25-bratislava",
      "id": 1264
    }
  },
  "/en/offices/pors-west-pluhova-pluhova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pors-west-pluhova-bratislava",
      "id": 1279
    }
  },
  "/en/offices/sustekova-49-sustekova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sustekova-49-bratislava",
      "id": 1300
    }
  },
  "/en/offices/ac-petrzalka-rontgenova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ac-petrzalka-bratislava",
      "id": 28
    }
  },
  "/en/offices/panenska-7-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-7-bratislava",
      "id": 125
    }
  },
  "/en/offices/postova-office-center-postova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "postova-office-center-bratislava",
      "id": 152
    }
  },
  "/en/offices/incheba-viedenska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "incheba-bratislava",
      "id": 1395
    }
  },
  "/en/offices/trade-center-ii-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trade-center-ii-bratislava",
      "id": 451
    }
  },
  "/en/offices/seberiniho-1-seberiniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "seberiniho-1-bratislava",
      "id": 429
    }
  },
  "/en/offices/quadro-prievozska-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "quadro-prievozska-bratislava",
      "id": 452
    }
  },
  "/en/offices/michalska-9-michalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "michalska-9-bratislava",
      "id": 405
    }
  },
  "/en/offices/grosslingova-4-grosslingova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "grosslingova-4-bratislava",
      "id": 1411
    }
  },
  "/en/offices/lake-building-drienova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "lake-building-bratislava",
      "id": 153
    }
  },
  "/en/offices/panenska-23-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-23-bratislava",
      "id": 1396
    }
  },
  "/en/offices/aston-bajkalska-22-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aston-bajkalska-22-bratislava",
      "id": 1570
    }
  },
  "/en/offices/gogolova-18-gogolova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gogolova-18-bratislava",
      "id": 198
    }
  },
  "/en/offices/gorkeho-3-gorkeho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gorkeho-3-bratislava",
      "id": 324
    }
  },
  "/en/offices/kancelarie-grosslingova-5-grosslingova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "grosslingova-5-bratislava",
      "id": 53
    }
  },
  "/en/offices/dom-s-pasazou-hviezdoslavovo-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dom-s-pasazou-bratislava",
      "id": 1522
    }
  },
  "/en/offices/nobelova-34-nobelova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "nobelova-34-bratislava",
      "id": 139
    }
  },
  "/en/offices/racianska-77-racianska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "racianska-77-bratislava",
      "id": 142
    }
  },
  "/en/offices/mileticova-21-mileticova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "mileticova-21-bratislava",
      "id": 89
    }
  },
  "/en/offices/venturska-16-venturska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "venturska-16-bratislava",
      "id": 404
    }
  },
  "/en/offices/matador-bojnicka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "matador-bratislava",
      "id": 1401
    }
  },
  "/en/offices/biznis-centrum-jantar-kosice-jantarova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "biznis-centrum-jantar-kosice-kosice",
      "id": 260
    }
  },
  "/en/offices/ravak-building-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ravak-bc-bratislava",
      "id": 530
    }
  },
  "/en/offices/trnavska-cesta-business-center-trnavska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trnavska-cesta-business-center-bratislava",
      "id": 1245
    }
  },
  "/en/offices/odborarska-23-odborarska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "odborarska-21-bratislava",
      "id": 22
    }
  },
  "/en/offices/gagarinova-7-c-gagarinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gagarinova-7-c-bratislava",
      "id": 158
    }
  },
  "/en/offices/nehnutelnost-na-predaj-v-sirsom-centre-kosic-postova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "nehnutelnost-na-predaj-v-sirsom-centre-kosic-kosice",
      "id": 1363
    }
  },
  "/en/offices/venturska-1-venturska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "venturska-1-bratislava",
      "id": 1327
    }
  },
  "/en/offices/presovska-ulica-presovska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "presovska-ulica-bratislava",
      "id": 1304
    }
  },
  "/en/offices/kovoprojekt-ruzova-dolina-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kovoprojekt-bratislava",
      "id": 218
    }
  },
  "/en/offices/panska-14-panska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panska-14-house-of-golden-eagle-bratislava",
      "id": 436
    }
  },
  "/en/offices/hurbanovo-namestie-1-hurbanovo-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hurbanovo-namestie-1-bratislava",
      "id": 214
    }
  },
  "/en/offices/pestovatelska-9-ed-system-pestovatelska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pestovatelska-9-ed-system-bratislava",
      "id": 1328
    }
  },
  "/en/offices/nivy-tower-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "nivy-tower-bratislava",
      "id": 1270
    }
  },
  "/en/offices/spilka-blumentalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "spilka-offices-legionarska-bratislava",
      "id": 1400
    }
  },
  "/en/offices/pradiaren-1900-svatoplukova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pradiaren-1900-bratislava",
      "id": 1280
    }
  },
  "/en/offices/stara-vajnorska-21-stara-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stara-vajnorska-21-bratislava",
      "id": 1318
    }
  },
  "/en/offices/devin-palace-gundulicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "devin-palace-bratislava",
      "id": 1292
    }
  },
  "/en/offices/kappa-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vajnorska-137-kapa-bratislava",
      "id": 1374
    }
  },
  "/en/offices/floreat-piestany-krajinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "floreat-piestany-piestany",
      "id": 1369
    }
  },
  "/en/offices/postova-3-galeria-delta-postova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "postova-3-galeria-delta-bratislava",
      "id": 1405
    }
  },
  "/en/offices/klingerka-platenicka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "klingerka-bratislava",
      "id": 1249
    }
  },
  "/en/offices/nivy-frame-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-nivy-bratislava",
      "id": 1247
    }
  },
  "/en/offices/eurovea-2-central-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-40-bratislava",
      "id": 1248
    }
  },
  "/en/offices/sturova-11-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sturova-11-bratislava",
      "id": 108
    }
  },
  "/en/offices/cukrova-14-cukrova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cukrova-14-bratislava",
      "id": 1463
    }
  },
  "/en/offices/michalska-18-20-michalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "michalska-18-20-bratislava",
      "id": 16
    }
  },
  "/en/offices/palisady-47-palisady-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "palisady-47-bratislava",
      "id": 68
    }
  },
  "/en/offices/plus-centrum-panonska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plus-centrum-etapa-i-bratislava",
      "id": 1392
    }
  },
  "/en/offices/zamocka-4-zamocka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zamocka-4-bratislava",
      "id": 2219
    }
  },
  "/en/offices/p3-office-center-partizanska-cesta-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "interpolis-office-center-banska-bystrica",
      "id": 1372
    }
  },
  "/en/offices/spitalska-spitalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-spitalska-bratislava",
      "id": 1368
    }
  },
  "/en/offices/ecopoint-office-center-ii-magnezitarska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ecopoint-office-center-ii-kosice-kosice",
      "id": 1506
    }
  },
  "/en/offices/business-center-kosice-i-sturova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-centre-kosice-etapa-i-kosice",
      "id": 2214
    }
  },
  "/en/offices/pribinova-x-central-iv-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-34-bratislava",
      "id": 1377
    }
  },
  "/en/offices/kancelarie-dargov-sturova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-dargov-kosice",
      "id": 1348
    }
  },
  "/en/offices/kancelarie-dolna-ulica-banska-bystrica-dolna-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-dolna-ulica-banska-bystrica-banska-bystrica",
      "id": 1494
    }
  },
  "/en/offices/administrativna-budova-janka-krala-janka-krala-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-janka-krala-banska-bystrica",
      "id": 2204
    }
  },
  "/en/offices/vus-zvolenska-cesta-banska-bystrica-1": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vus-banska-bystrica",
      "id": 2206
    }
  },
  "/en/offices/kancelarie-kuzmanyho-banska-bystrica-kuzmanyho-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-kuzmanyho-banska-bystrica-banska-bystrica",
      "id": 1472
    }
  },
  "/en/offices/kancelarie-zvolensk8-cesta-banska-bystrica-zvolenska-cesta-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-zvolenska-cesta-banska-bystrica-banska-bystrica",
      "id": 1572
    }
  },
  "/en/offices/r2n-business-center-sladkovicova-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "r2n-business-center-banska-bystrica",
      "id": 1364
    }
  },
  "/en/offices/bottova-banska-bystrica-jana-bottu-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bottova-banska-bystrica-banska-bystrica",
      "id": 1469
    }
  },
  "/en/offices/sklad-c-7-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sklad-7-bratislava",
      "id": 1385
    }
  },
  "/en/offices/konventna-6-konventna-ulica-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "konventna-6-bratislava",
      "id": 1288
    }
  },
  "/en/offices/elektrarenska-6-elektrarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "elektrarenska-6-bratislava",
      "id": 1326
    }
  },
  "/en/offices/plus-centrum-etapa-ii-panonska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plus-centrum-etapa-ii-bratislava",
      "id": 1291
    }
  },
  "/en/offices/plus-centrum-etapa-iii-panonska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plus-centrum-etapa-iii-bratislava",
      "id": 2195
    }
  },
  "/en/offices/plynarenska-6-flexi-box-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plynarenska-6-flexi-box-bratislava",
      "id": 1334
    }
  },
  "/en/offices/bcm-ii-business-center-moldavska-moldavska-cesta-10-b-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bcm-business-center-moldavska-kosice-kosice",
      "id": 1336
    }
  },
  "/en/offices/bastion-office-center-kosice-tovarenska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bastion-office-center-kosice-kosice",
      "id": 1571
    }
  },
  "/en/offices/gagarinova-5-a-gagarinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gagarinova-5-a-bratislava",
      "id": 1380
    }
  },
  "/en/offices/pba-karloveska-karloveska-bratislava-1": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pba-karloveska-bratislava",
      "id": 129
    }
  },
  "/en/offices/kancelarie-prior-poprad-1-maja-poprad": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-prior-poprad-poprad",
      "id": 1501
    }
  },
  "/en/offices/karpatska-18-karpatska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "karpatska-18-bratislava",
      "id": 1533
    }
  },
  "/en/offices/strieborne-namestie-banska-bystrica-strieborne-namestie-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "strieborne-namestie-banska-bystrica-rekonstrukcia-banska-bystrica",
      "id": 1366
    }
  },
  "/en/offices/perla-ruzinova-kastielska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "perla-ruzinova-bratislava",
      "id": 1516
    }
  },
  "/en/offices/metropolitan-star-namestie-1-maja-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "metropolitan-star-bratislava",
      "id": 1415
    }
  },
  "/en/offices/cintorinska-9-cintorinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cintorinska-9-bratislava",
      "id": 1438
    }
  },
  "/en/offices/absolutio-teplicka-147-piestany-teplicka-piestany": {
    "name": "offices-slug-id",
    "params": {
      "slug": "absolutio-teplicka-147-piestany-piestany",
      "id": 1339
    }
  },
  "/en/offices/bct2-moldavska-cesta-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bct2-kosice",
      "id": 1499
    }
  },
  "/en/offices/panorama-business-ii-sublease-or-surrender-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-19-k-dispozicii-ihned-bratislava",
      "id": 1378
    }
  },
  "/en/offices/bardosova-bardosova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bardosova-2-a-bratislava",
      "id": 1418
    }
  },
  "/en/offices/laurinska-1-laurinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "laurinska-1-bratislava",
      "id": 617
    }
  },
  "/en/offices/dunajska-31-dunajska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dunajska-31-bratislava",
      "id": 1353
    }
  },
  "/en/offices/vlcie-hrdlo-business-park-ab-ii-vlcie-hrdlo-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-business-park-ab-ii-bratislava",
      "id": 1376
    }
  },
  "/en/offices/vlcie-hrdlo-business-park-ab-i-vlcie-hrdlo-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-business-park-ab-i-bratislava",
      "id": 1244
    }
  },
  "/en/offices/bts-cargo-budova-ab-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-ab-budova-bratislava",
      "id": 1462
    }
  },
  "/en/offices/bts-cargo-budova-ap-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-ap-bratislava",
      "id": 2196
    }
  },
  "/en/offices/bts-cargo-budova-ac-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-f-bratislava",
      "id": 1461
    }
  },
  "/en/offices/bts-cargo-budova-ac-ivanska-cesta-bratislava-1": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-ac-bratislava",
      "id": 2208
    }
  },
  "/en/offices/bts-cargo-budova-bcd-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-bcd-bratislava",
      "id": 1332
    }
  },
  "/en/offices/carlton-savoy-2a-mostova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "carlton-savoy-2a-bratislava",
      "id": 1276
    }
  },
  "/en/offices/rosum-sublease-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "rosum-sublease-bratislava",
      "id": 1286
    }
  },
  "/en/offices/prazska-11-prazska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "prazska-11-bratislava",
      "id": 1448
    }
  },
  "/en/offices/dominant-namestie-hraniciarov-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dominant-bratislava",
      "id": 1290
    }
  },
  "/en/offices/dca-park-ilava-l-stura-ilava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dca-park-ilava-ilava",
      "id": 1265
    }
  },
  "/en/offices/trnavska-cesta-32-trnavska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trnavska-cesta-32-bratislava",
      "id": 1414
    }
  },
  "/en/offices/stefanovicova-12-14-stefanovicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stefanovicova-12-14-bratislava",
      "id": 1423
    }
  },
  "/en/offices/cassovar-business-center-i-sublease-zriedlova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cassovar-business-center-sublease-kosice",
      "id": 1289
    }
  },
  "/en/offices/svatoplukova-31-unicredit-bank-svatoplukova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "svatoplukova-31-bratislava",
      "id": 1235
    }
  },
  "/en/offices/stengl-campus-stare-podhradie-zizkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stengl-campus-stare-podhradie-bratislava",
      "id": 1381
    }
  },
  "/en/offices/pribinova-19-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-19-bratislava",
      "id": 1379
    }
  },
  "/en/offices/tomasikova-30-tomasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tomasikova-30-bratislava",
      "id": 1283
    }
  },
  "/sk/kancelarie/digital-park-phase-i-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "digital-park-phase-i-bratislava",
      "id": 112
    }
  },
  "/sk/kancelarie/cbc-iii-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-iii-bratislava",
      "id": 57
    }
  },
  "/sk/kancelarie/aupark-tower-ba-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aupark-tower-bratislava-bratislava",
      "id": 435
    }
  },
  "/sk/kancelarie/polus-tower-i-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "myhive-vajnorska-tower-i-bratislava",
      "id": 49
    }
  },
  "/sk/kancelarie/carlton-savoy-2-mostova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "carlton-savoy-2-bratislava",
      "id": 48
    }
  },
  "/sk/kancelarie/lakeside-park-01-tomasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "lakeside-park-01-bratislava",
      "id": 431
    }
  },
  "/sk/kancelarie/galvaniho-bc-v-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-v-bratislava",
      "id": 1242
    }
  },
  "/sk/kancelarie/galvaniho-bc-ii-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-ii-bratislava",
      "id": 12
    }
  },
  "/sk/kancelarie/aruba-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aruba-bratislava",
      "id": 104
    }
  },
  "/sk/kancelarie/galvaniho-bc-iv-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-iv-bratislava",
      "id": 11
    }
  },
  "/sk/kancelarie/galvaniho-bc-i-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-i-bratislava",
      "id": 401
    }
  },
  "/sk/kancelarie/cbc-iv-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-iv-bratislava",
      "id": 524
    }
  },
  "/sk/kancelarie/westend-gate-object-a-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-gate-object-a-bratislava",
      "id": 1255
    }
  },
  "/sk/kancelarie/polus-tower-ii-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "myhive-vajnorska-tower-ii-bratislava",
      "id": 380
    }
  },
  "/sk/kancelarie/digital-park-phase-ii-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "digital-park-phase-ii-bratislava",
      "id": 97
    }
  },
  "/sk/kancelarie/eurovea-central-2-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "eurovea-central-2-bratislava",
      "id": 1273
    }
  },
  "/sk/kancelarie/eurovea-central-3-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "eurovea-central-3-bratislava",
      "id": 1271
    }
  },
  "/sk/kancelarie/park-one-namestie-1-maja-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "park-one-bratislava",
      "id": 1519
    }
  },
  "/sk/kancelarie/jarosova-oc-jarosova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "jarosova-oc-sublease-bratislava",
      "id": 319
    }
  },
  "/sk/kancelarie/bbc-v-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bbc-v-bratislava",
      "id": 432
    }
  },
  "/sk/kancelarie/cbc-v-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-v-bratislava",
      "id": 58
    }
  },
  "/sk/kancelarie/trade-center-kosice-trieda-snp-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trade-center-kosice-kosice",
      "id": 220
    }
  },
  "/sk/kancelarie/aupark-tower-kosice-protifasistickych-bojovnikov-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aupark-tower-kosice-kosice",
      "id": 292
    }
  },
  "/sk/kancelarie/eurovea-central-1-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "eurovea-central-1-bratislava",
      "id": 82
    }
  },
  "/sk/kancelarie/bbc-i-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bbc-i-bratislava",
      "id": 433
    }
  },
  "/sk/kancelarie/bbc-1-plus-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bbc-1-plus-bratislava",
      "id": 434
    }
  },
  "/sk/kancelarie/apollo-bc-ii-block-a-b-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-bc-ii-block-a-b-bratislava",
      "id": 1266
    }
  },
  "/sk/kancelarie/apollo-bc-ii-block-c-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-bc-ii-block-c-bratislava",
      "id": 1268
    }
  },
  "/sk/kancelarie/apollo-bc-ii-block-e-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-bc-ii-block-e-bratislava",
      "id": 1406
    }
  },
  "/sk/kancelarie/green-point-offices-block-f-g-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-2-green-point-offices-block-f-g-bratislava",
      "id": 1417
    }
  },
  "/sk/kancelarie/green-point-offices-block-h-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-2-green-point-offices-block-h-bratislava",
      "id": 1416
    }
  },
  "/sk/kancelarie/westend-gate-object-b-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-gate-object-b-bratislava",
      "id": 392
    }
  },
  "/sk/kancelarie/westend-tower-dubravska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-tower-bratislava",
      "id": 430
    }
  },
  "/sk/kancelarie/tower-115-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tower-115-bratislava",
      "id": 26
    }
  },
  "/sk/kancelarie/westend-square-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-square-bratislava",
      "id": 391
    }
  },
  "/sk/kancelarie/h-business-center-roznavska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "h-business-center-bratislava",
      "id": 369
    }
  },
  "/sk/kancelarie/westend-court-dubravska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-court-bratislava",
      "id": 33
    }
  },
  "/sk/kancelarie/zuckermandel-river-side-zizkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zuckermandel-river-side-ca-bratislava",
      "id": 6
    }
  },
  "/sk/kancelarie/rosum-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "rosum-bratislava",
      "id": 101
    }
  },
  "/sk/kancelarie/cbc-i-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-i-bratislava",
      "id": 73
    }
  },
  "/sk/kancelarie/cbc-ii-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cbc-ii-bratislava",
      "id": 1267
    }
  },
  "/sk/kancelarie/zelezniciarska-18-zelezniciarska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zelezniciarska-18-bratislava",
      "id": 2202
    }
  },
  "/sk/kancelarie/zamocka-34-zamocka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zamocka-34-bratislava",
      "id": 21
    }
  },
  "/sk/kancelarie/zochova-6-8-zochova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zochova-6-8-bratislava",
      "id": 497
    }
  },
  "/sk/kancelarie/zochova-5-zochova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zochova-5-bratislava",
      "id": 498
    }
  },
  "/sk/kancelarie/omnipolis-trnavska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "omnipolis-bratislava",
      "id": 168
    }
  },
  "/sk/kancelarie/ab-tuhovska-tuhovska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-tuhovska-bratislava",
      "id": 9
    }
  },
  "/sk/kancelarie/europeum-suche-myto-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "europeum-bratislava",
      "id": 29
    }
  },
  "/sk/kancelarie/teslova-19-teslova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "teslova-19-bratislava",
      "id": 203
    }
  },
  "/sk/kancelarie/lamacska-cesta-43-jaga-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "lamacska-cesta-45-jaga-bratislava",
      "id": 172
    }
  },
  "/sk/kancelarie/microstep-vajnorska-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "microstep-vajnorska-bratislava",
      "id": 2209
    }
  },
  "/sk/kancelarie/stefanikova-8-stefanikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stefanikova-8-bratislava",
      "id": 461
    }
  },
  "/sk/kancelarie/business-center-tesla-ii-moldavska-cesta-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-center-tesla-ii-kosice",
      "id": 1359
    }
  },
  "/sk/kancelarie/business-center-rozvojova-kosice-rozvojova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-center-rozvojova-kosice-kosice",
      "id": 1503
    }
  },
  "/sk/kancelarie/cassofin-ii-kosice-letna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cassofin-ii-kosice-kosice",
      "id": 1497
    }
  },
  "/sk/kancelarie/tabacka-kosice-strojarenska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tabacka-kosice-kosice",
      "id": 2216
    }
  },
  "/sk/kancelarie/administrativne-priestory-hlavna-kosice-hlavna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hlavna-117-kosice-kosice",
      "id": 1285
    }
  },
  "/sk/kancelarie/podnikatelske-centrum-kriva-ii-kosice-kriva-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-kriva-ii-kosice-kosice",
      "id": 1511
    }
  },
  "/sk/kancelarie/aircraft-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aircraft-bratislava",
      "id": 52
    }
  },
  "/sk/kancelarie/kerametal-jasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kerametal-bratislava",
      "id": 1565
    }
  },
  "/sk/kancelarie/air-offices-pestovatelska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "air-offices-bratislava",
      "id": 323
    }
  },
  "/sk/kancelarie/reding-tower-2-racianska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "reding-tower-2-bratislava",
      "id": 318
    }
  },
  "/sk/kancelarie/reding-tower-1-racianska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "reding-tower-1-bratislava",
      "id": 109
    }
  },
  "/sk/kancelarie/panorama-business-iii-landererova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "landererova-12-bratislava",
      "id": 502
    }
  },
  "/sk/kancelarie/gorkeho-4-gorkeho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gorkeho-4-bratislava",
      "id": 477
    }
  },
  "/sk/kancelarie/stein-office-part-blumentalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "steinerka-bratislava",
      "id": 1514
    }
  },
  "/sk/kancelarie/technopol-biznisuite-kutlikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "technopol-biznisuite-bratislava",
      "id": 147
    }
  },
  "/sk/kancelarie/blumental-office-part-phase-ii-radlinskeho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "blumental-office-part-phase-ii-bratislava",
      "id": 370
    }
  },
  "/sk/kancelarie/blumental-office-part-phase-i-mytna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "blumental-office-part-phase-i-bratislava",
      "id": 317
    }
  },
  "/sk/kancelarie/central-high-rise-metodova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "central-high-rise-bratislava",
      "id": 13
    }
  },
  "/sk/kancelarie/office-box-mokran-zahon-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "office-box-bratislava",
      "id": 1458
    }
  },
  "/sk/kancelarie/extrend-tomasikova-tomasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "best-tomasikova-bratislava",
      "id": 19
    }
  },
  "/sk/kancelarie/galvaniho-bc-iii-galvaniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "galvaniho-bc-iii-bratislava",
      "id": 4
    }
  },
  "/sk/kancelarie/podnikatelske-centrum-kriva-i-kosice-kriva-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-kriva-i-kosice-kosice",
      "id": 1341
    }
  },
  "/sk/kancelarie/hpk-engineering-kosice-bozeny-nemcovej-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hpk-engineering-kosice-kosice",
      "id": 242
    }
  },
  "/sk/kancelarie/aston-building-kosice-werferova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aston-building-kosice-kosice",
      "id": 1496
    }
  },
  "/sk/kancelarie/zuckermandel-hill-side-zizkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zuckermandel-hill-side-cx-bratislava",
      "id": 40
    }
  },
  "/sk/kancelarie/castor-pollux-luxor-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "castor-pollux-luxor-bratislava",
      "id": 15
    }
  },
  "/sk/kancelarie/hlavne-namestie-5-kooperativa-building-hlavne-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "roland-offices-bratislava",
      "id": 476
    }
  },
  "/sk/kancelarie/panenska-13-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-13-bratislava",
      "id": 192
    }
  },
  "/sk/kancelarie/westend-plazza-lamacska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "westend-plazza-bratislava",
      "id": 1390
    }
  },
  "/sk/kancelarie/twin-city-tower-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "twin-city-tower-bratislava",
      "id": 1433
    }
  },
  "/sk/kancelarie/twin-city-b-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "twin-city-b-bratislava",
      "id": 480
    }
  },
  "/sk/kancelarie/twin-city-a-karadzicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "twin-city-a-bratislava",
      "id": 454
    }
  },
  "/sk/kancelarie/sky-park-offices-culenova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sky-park-offices-bratislava",
      "id": 1383
    }
  },
  "/sk/kancelarie/einsteinova-business-center-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "einsteinova-business-center-bratislava",
      "id": 46
    }
  },
  "/sk/kancelarie/panenska-8-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-8-bratislava",
      "id": 114
    }
  },
  "/sk/kancelarie/bbc-iv-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pressburg-tower-bratislava",
      "id": 5
    }
  },
  "/sk/kancelarie/namestie-snp-15-namestie-snp-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "namestie-snp-15-bratislava",
      "id": 126
    }
  },
  "/sk/kancelarie/grosslingova-7-grosslingova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "grosslingova-7-bratislava",
      "id": 1548
    }
  },
  "/sk/kancelarie/central-v-sevcenkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "central-v-bratislava",
      "id": 148
    }
  },
  "/sk/kancelarie/hranicna-18-hranicna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hranicna-18-bratislava",
      "id": 1479
    }
  },
  "/sk/kancelarie/kolarska-6-kolarska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kolarska-6-bratislava",
      "id": 14
    }
  },
  "/sk/kancelarie/tower-5-kalinciakova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tower-5-bratislava",
      "id": 34
    }
  },
  "/sk/kancelarie/safranova-zahrada-ii-kosice-skladna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "safranova-zahrada-ii-kosice-kosice",
      "id": 1365
    }
  },
  "/sk/kancelarie/obuvnicka-basta-hviezdoslavovo-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "obuvnicka-basta-bratislava",
      "id": 162
    }
  },
  "/sk/kancelarie/bc-tehelne-pole-trnavska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bc-tehelne-pole-bratislava",
      "id": 138
    }
  },
  "/sk/kancelarie/laurinska-18-laurinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "laurinska-18-bratislava",
      "id": 1441
    }
  },
  "/sk/kancelarie/milton-house-soltesovej-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "milton-house-bratislava",
      "id": 103
    }
  },
  "/sk/kancelarie/office-142-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "office-142-bratislava",
      "id": 1296
    }
  },
  "/sk/kancelarie/administrativne-priestory-masiarska-masiarska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "masiarska-kosice-administrativne-priestory-kosice",
      "id": 1361
    }
  },
  "/sk/kancelarie/kancelarsky-priestor-stanicne-namestie-kosice-stanicne-namestie-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarsky-priestor-stanicne-namestie-kosice-kosice",
      "id": 1358
    }
  },
  "/sk/kancelarie/zilinska-7-9-zilinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zilinska-7-9-bratislava",
      "id": 281
    }
  },
  "/sk/kancelarie/vidor-palace-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vidor-palace-bratislava",
      "id": 102
    }
  },
  "/sk/kancelarie/pressburg-trade-center-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pressburg-trade-center-bratislava",
      "id": 39
    }
  },
  "/sk/kancelarie/michalska-burghers-house-michalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "michalska-burghers-house-bratislava",
      "id": 20
    }
  },
  "/sk/kancelarie/kosicka-56-kosicka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kosicka-56-bratislava",
      "id": 94
    }
  },
  "/sk/kancelarie/forum-bc-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "forum-bc-bratislava",
      "id": 7
    }
  },
  "/sk/kancelarie/fams-kosice-szakkayho-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "fams-kosice-kosice",
      "id": 1488
    }
  },
  "/sk/kancelarie/cubus-fazulova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cubus-bratislava",
      "id": 59
    }
  },
  "/sk/kancelarie/business-center-kosice-iii-sturova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-centre-kosice-etapa-ii-kosice",
      "id": 2223
    }
  },
  "/sk/kancelarie/stefanium-stefanikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stefanium-bratislava",
      "id": 164
    }
  },
  "/sk/kancelarie/administrativne-priestory-rozvojova-kosice-rozvojova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativne-priestory-rozvojova-kosice-kosice",
      "id": 1594
    }
  },
  "/sk/kancelarie/podnikatelske-centrum-gemerska-kosice-gemerska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-gemerska-kosice-kosice",
      "id": 2207
    }
  },
  "/sk/kancelarie/podnikatelske-centrum-skolpo-kosice-popradska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-skolpo-kosice-kosice",
      "id": 1568
    }
  },
  "/sk/kancelarie/podnikatelske-centrum-mei-kosice-letna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "podnikatelske-centrum-mei-kosice-kosice",
      "id": 2203
    }
  },
  "/sk/kancelarie/kancelarie-kysucka-kosice-kysucka-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-kysucka-kosice-kosice",
      "id": 1576
    }
  },
  "/sk/kancelarie/biznis-park-rampova-kosice-rampova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "biznis-park-rampova-kosice-kosice",
      "id": 1362
    }
  },
  "/sk/kancelarie/kancelarske-priestory-hlavna-kosice-hlavna-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hlavna-114-kosice-kosice",
      "id": 1345
    }
  },
  "/sk/kancelarie/ab-onyx-poprad-namestie-sv-egidia-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-onyx-poprad-poprad",
      "id": 1508
    }
  },
  "/sk/kancelarie/ab-slovenska-presov-slovenska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-slovenska-presov-presov",
      "id": 1340
    }
  },
  "/sk/kancelarie/kancelarie-stavoprojekt-presov-duchnovicovo-namestie-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-stavoprojekt-presov-presov",
      "id": 1484
    }
  },
  "/sk/kancelarie/kancelarske-priestory-levocska-poprad-levocska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarske-priestory-levocska-poprad-poprad",
      "id": 1337
    }
  },
  "/sk/kancelarie/administrativne-a-obchodne-priestory-vranov-nad-toplou-namestie-slobody-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativno-obchodne-centrum-vranov-nad-toplou-vranov-nad-toplou",
      "id": 1476
    }
  },
  "/sk/kancelarie/ab-vzorodev-presov-levocska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-center-presov-presov",
      "id": 1349
    }
  },
  "/sk/kancelarie/ab-intes-poprad-namestie-svateho-egidia-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-intes-poprad-poprad",
      "id": 1575
    }
  },
  "/sk/kancelarie/emm-building-sekurisova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "emm-building-bratislava",
      "id": 1537
    }
  },
  "/sk/kancelarie/solar-budatinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "solar-bratislava",
      "id": 1319
    }
  },
  "/sk/kancelarie/carlton-savoy-4-mostova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "carlton-savoy-4-bratislava",
      "id": 1277
    }
  },
  "/sk/kancelarie/river-park-block-f-dvorakovo-nabrezie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "river-park-block-f-bratislava",
      "id": 1258
    }
  },
  "/sk/kancelarie/panenska-5-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-5-bratislava",
      "id": 1529
    }
  },
  "/sk/kancelarie/einpark-offices-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "einpark-offices-bratislava",
      "id": 1243
    }
  },
  "/sk/kancelarie/slavicie-udolie-104-slavicie-udolie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "slavicie-udolie-106-bratislava",
      "id": 1251
    }
  },
  "/sk/kancelarie/za-tehelnou-9-za-tehelnou-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "za-tehelnou-9-bratislava",
      "id": 1352
    }
  },
  "/sk/kancelarie/rybnicna-40-rybnicna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "rybnicna-40-bratislava",
      "id": 54
    }
  },
  "/sk/kancelarie/bittner-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bittner-bratislava",
      "id": 1454
    }
  },
  "/sk/kancelarie/river-park-block-c-dvorakovo-nabrezie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "river-park-block-c-bratislava",
      "id": 25
    }
  },
  "/sk/kancelarie/ab-konventna-9-konventna-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ab-konventna-9-bratislava",
      "id": 1287
    }
  },
  "/sk/kancelarie/pri-suchom-mlyne-pri-suchom-mlyne-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pri-suchom-mlyne-bratislava",
      "id": 1329
    }
  },
  "/sk/kancelarie/hodoninska-25-hodoninska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hodoninska-25-bratislava",
      "id": 1264
    }
  },
  "/sk/kancelarie/pors-west-pluhova-pluhova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pors-west-pluhova-bratislava",
      "id": 1279
    }
  },
  "/sk/kancelarie/sustekova-49-sustekova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sustekova-49-bratislava",
      "id": 1300
    }
  },
  "/sk/kancelarie/ac-petrzalka-rontgenova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ac-petrzalka-bratislava",
      "id": 28
    }
  },
  "/sk/kancelarie/panenska-7-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-7-bratislava",
      "id": 125
    }
  },
  "/sk/kancelarie/postova-office-center-postova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "postova-office-center-bratislava",
      "id": 152
    }
  },
  "/sk/kancelarie/incheba-viedenska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "incheba-bratislava",
      "id": 1395
    }
  },
  "/sk/kancelarie/trade-center-ii-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trade-center-ii-bratislava",
      "id": 451
    }
  },
  "/sk/kancelarie/seberiniho-1-seberiniho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "seberiniho-1-bratislava",
      "id": 429
    }
  },
  "/sk/kancelarie/quadro-prievozska-prievozska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "quadro-prievozska-bratislava",
      "id": 452
    }
  },
  "/sk/kancelarie/michalska-9-michalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "michalska-9-bratislava",
      "id": 405
    }
  },
  "/sk/kancelarie/grosslingova-4-grosslingova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "grosslingova-4-bratislava",
      "id": 1411
    }
  },
  "/sk/kancelarie/lake-building-drienova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "lake-building-bratislava",
      "id": 153
    }
  },
  "/sk/kancelarie/panenska-23-panenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panenska-23-bratislava",
      "id": 1396
    }
  },
  "/sk/kancelarie/aston-bajkalska-22-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "aston-bajkalska-22-bratislava",
      "id": 1570
    }
  },
  "/sk/kancelarie/gogolova-18-gogolova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gogolova-18-bratislava",
      "id": 198
    }
  },
  "/sk/kancelarie/gorkeho-3-gorkeho-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gorkeho-3-bratislava",
      "id": 324
    }
  },
  "/sk/kancelarie/kancelarie-grosslingova-5-grosslingova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "grosslingova-5-bratislava",
      "id": 53
    }
  },
  "/sk/kancelarie/dom-s-pasazou-hviezdoslavovo-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dom-s-pasazou-bratislava",
      "id": 1522
    }
  },
  "/sk/kancelarie/nobelova-34-nobelova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "nobelova-34-bratislava",
      "id": 139
    }
  },
  "/sk/kancelarie/racianska-77-racianska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "racianska-77-bratislava",
      "id": 142
    }
  },
  "/sk/kancelarie/mileticova-21-mileticova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "mileticova-21-bratislava",
      "id": 89
    }
  },
  "/sk/kancelarie/venturska-16-venturska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "venturska-16-bratislava",
      "id": 404
    }
  },
  "/sk/kancelarie/matador-bojnicka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "matador-bratislava",
      "id": 1401
    }
  },
  "/sk/kancelarie/biznis-centrum-jantar-kosice-jantarova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "biznis-centrum-jantar-kosice-kosice",
      "id": 260
    }
  },
  "/sk/kancelarie/ravak-building-einsteinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ravak-bc-bratislava",
      "id": 530
    }
  },
  "/sk/kancelarie/trnavska-cesta-business-center-trnavska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trnavska-cesta-business-center-bratislava",
      "id": 1245
    }
  },
  "/sk/kancelarie/odborarska-23-odborarska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "odborarska-21-bratislava",
      "id": 22
    }
  },
  "/sk/kancelarie/gagarinova-7-c-gagarinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gagarinova-7-c-bratislava",
      "id": 158
    }
  },
  "/sk/kancelarie/nehnutelnost-na-predaj-v-sirsom-centre-kosic-postova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "nehnutelnost-na-predaj-v-sirsom-centre-kosic-kosice",
      "id": 1363
    }
  },
  "/sk/kancelarie/venturska-1-venturska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "venturska-1-bratislava",
      "id": 1327
    }
  },
  "/sk/kancelarie/presovska-ulica-presovska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "presovska-ulica-bratislava",
      "id": 1304
    }
  },
  "/sk/kancelarie/kovoprojekt-ruzova-dolina-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kovoprojekt-bratislava",
      "id": 218
    }
  },
  "/sk/kancelarie/panska-14-panska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "panska-14-house-of-golden-eagle-bratislava",
      "id": 436
    }
  },
  "/sk/kancelarie/hurbanovo-namestie-1-hurbanovo-namestie-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "hurbanovo-namestie-1-bratislava",
      "id": 214
    }
  },
  "/sk/kancelarie/pestovatelska-9-ed-system-pestovatelska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pestovatelska-9-ed-system-bratislava",
      "id": 1328
    }
  },
  "/sk/kancelarie/nivy-tower-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "nivy-tower-bratislava",
      "id": 1270
    }
  },
  "/sk/kancelarie/spilka-blumentalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "spilka-offices-legionarska-bratislava",
      "id": 1400
    }
  },
  "/sk/kancelarie/pradiaren-1900-svatoplukova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pradiaren-1900-bratislava",
      "id": 1280
    }
  },
  "/sk/kancelarie/stara-vajnorska-21-stara-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stara-vajnorska-21-bratislava",
      "id": 1318
    }
  },
  "/sk/kancelarie/devin-palace-gundulicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "devin-palace-bratislava",
      "id": 1292
    }
  },
  "/sk/kancelarie/kappa-vajnorska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vajnorska-137-kapa-bratislava",
      "id": 1374
    }
  },
  "/sk/kancelarie/floreat-piestany-krajinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "floreat-piestany-piestany",
      "id": 1369
    }
  },
  "/sk/kancelarie/postova-3-galeria-delta-postova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "postova-3-galeria-delta-bratislava",
      "id": 1405
    }
  },
  "/sk/kancelarie/klingerka-platenicka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "klingerka-bratislava",
      "id": 1249
    }
  },
  "/sk/kancelarie/nivy-frame-mlynske-nivy-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "apollo-nivy-bratislava",
      "id": 1247
    }
  },
  "/sk/kancelarie/eurovea-2-central-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-40-bratislava",
      "id": 1248
    }
  },
  "/sk/kancelarie/sturova-11-sturova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sturova-11-bratislava",
      "id": 108
    }
  },
  "/sk/kancelarie/cukrova-14-cukrova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cukrova-14-bratislava",
      "id": 1463
    }
  },
  "/sk/kancelarie/michalska-18-20-michalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "michalska-18-20-bratislava",
      "id": 16
    }
  },
  "/sk/kancelarie/palisady-47-palisady-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "palisady-47-bratislava",
      "id": 68
    }
  },
  "/sk/kancelarie/plus-centrum-panonska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plus-centrum-etapa-i-bratislava",
      "id": 1392
    }
  },
  "/sk/kancelarie/zamocka-4-zamocka-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "zamocka-4-bratislava",
      "id": 2219
    }
  },
  "/sk/kancelarie/p3-office-center-partizanska-cesta-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "interpolis-office-center-banska-bystrica",
      "id": 1372
    }
  },
  "/sk/kancelarie/spitalska-spitalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-spitalska-bratislava",
      "id": 1368
    }
  },
  "/sk/kancelarie/ecopoint-office-center-ii-magnezitarska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "ecopoint-office-center-ii-kosice-kosice",
      "id": 1506
    }
  },
  "/sk/kancelarie/business-center-kosice-i-sturova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "business-centre-kosice-etapa-i-kosice",
      "id": 2214
    }
  },
  "/sk/kancelarie/pribinova-x-central-iv-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-34-bratislava",
      "id": 1377
    }
  },
  "/sk/kancelarie/kancelarie-dargov-sturova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-dargov-kosice",
      "id": 1348
    }
  },
  "/sk/kancelarie/kancelarie-dolna-ulica-banska-bystrica-dolna-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-dolna-ulica-banska-bystrica-banska-bystrica",
      "id": 1494
    }
  },
  "/sk/kancelarie/administrativna-budova-janka-krala-janka-krala-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "administrativna-budova-janka-krala-banska-bystrica",
      "id": 2204
    }
  },
  "/sk/kancelarie/vus-zvolenska-cesta-banska-bystrica-1": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vus-banska-bystrica",
      "id": 2206
    }
  },
  "/sk/kancelarie/kancelarie-kuzmanyho-banska-bystrica-kuzmanyho-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-kuzmanyho-banska-bystrica-banska-bystrica",
      "id": 1472
    }
  },
  "/sk/kancelarie/kancelarie-zvolensk8-cesta-banska-bystrica-zvolenska-cesta-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-zvolenska-cesta-banska-bystrica-banska-bystrica",
      "id": 1572
    }
  },
  "/sk/kancelarie/r2n-business-center-sladkovicova-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "r2n-business-center-banska-bystrica",
      "id": 1364
    }
  },
  "/sk/kancelarie/bottova-banska-bystrica-jana-bottu-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bottova-banska-bystrica-banska-bystrica",
      "id": 1469
    }
  },
  "/sk/kancelarie/sklad-c-7-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "sklad-7-bratislava",
      "id": 1385
    }
  },
  "/sk/kancelarie/konventna-6-konventna-ulica-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "konventna-6-bratislava",
      "id": 1288
    }
  },
  "/sk/kancelarie/elektrarenska-6-elektrarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "elektrarenska-6-bratislava",
      "id": 1326
    }
  },
  "/sk/kancelarie/plus-centrum-etapa-ii-panonska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plus-centrum-etapa-ii-bratislava",
      "id": 1291
    }
  },
  "/sk/kancelarie/plus-centrum-etapa-iii-panonska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plus-centrum-etapa-iii-bratislava",
      "id": 2195
    }
  },
  "/sk/kancelarie/plynarenska-6-flexi-box-plynarenska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "plynarenska-6-flexi-box-bratislava",
      "id": 1334
    }
  },
  "/sk/kancelarie/bcm-ii-business-center-moldavska-moldavska-cesta-10-b-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bcm-business-center-moldavska-kosice-kosice",
      "id": 1336
    }
  },
  "/sk/kancelarie/bastion-office-center-kosice-tovarenska-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bastion-office-center-kosice-kosice",
      "id": 1571
    }
  },
  "/sk/kancelarie/gagarinova-5-a-gagarinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "gagarinova-5-a-bratislava",
      "id": 1380
    }
  },
  "/sk/kancelarie/pba-karloveska-karloveska-bratislava-1": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pba-karloveska-bratislava",
      "id": 129
    }
  },
  "/sk/kancelarie/kancelarie-prior-poprad-1-maja-poprad": {
    "name": "offices-slug-id",
    "params": {
      "slug": "kancelarie-prior-poprad-poprad",
      "id": 1501
    }
  },
  "/sk/kancelarie/karpatska-18-karpatska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "karpatska-18-bratislava",
      "id": 1533
    }
  },
  "/sk/kancelarie/strieborne-namestie-banska-bystrica-strieborne-namestie-banska-bystrica": {
    "name": "offices-slug-id",
    "params": {
      "slug": "strieborne-namestie-banska-bystrica-rekonstrukcia-banska-bystrica",
      "id": 1366
    }
  },
  "/sk/kancelarie/perla-ruzinova-kastielska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "perla-ruzinova-bratislava",
      "id": 1516
    }
  },
  "/sk/kancelarie/metropolitan-star-namestie-1-maja-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "metropolitan-star-bratislava",
      "id": 1415
    }
  },
  "/sk/kancelarie/cintorinska-9-cintorinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cintorinska-9-bratislava",
      "id": 1438
    }
  },
  "/sk/kancelarie/absolutio-teplicka-147-piestany-teplicka-piestany": {
    "name": "offices-slug-id",
    "params": {
      "slug": "absolutio-teplicka-147-piestany-piestany",
      "id": 1339
    }
  },
  "/sk/kancelarie/bct2-moldavska-cesta-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bct2-kosice",
      "id": 1499
    }
  },
  "/sk/kancelarie/panorama-business-ii-sublease-or-surrender-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-19-k-dispozicii-ihned-bratislava",
      "id": 1378
    }
  },
  "/sk/kancelarie/bardosova-bardosova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bardosova-2-a-bratislava",
      "id": 1418
    }
  },
  "/sk/kancelarie/laurinska-1-laurinska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "laurinska-1-bratislava",
      "id": 617
    }
  },
  "/sk/kancelarie/dunajska-31-dunajska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dunajska-31-bratislava",
      "id": 1353
    }
  },
  "/sk/kancelarie/vlcie-hrdlo-business-park-ab-ii-vlcie-hrdlo-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-business-park-ab-ii-bratislava",
      "id": 1376
    }
  },
  "/sk/kancelarie/vlcie-hrdlo-business-park-ab-i-vlcie-hrdlo-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-business-park-ab-i-bratislava",
      "id": 1244
    }
  },
  "/sk/kancelarie/bts-cargo-budova-ab-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-ab-budova-bratislava",
      "id": 1462
    }
  },
  "/sk/kancelarie/bts-cargo-budova-ap-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-ap-bratislava",
      "id": 2196
    }
  },
  "/sk/kancelarie/bts-cargo-budova-ac-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-f-bratislava",
      "id": 1461
    }
  },
  "/sk/kancelarie/bts-cargo-budova-ac-ivanska-cesta-bratislava-1": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-ac-bratislava",
      "id": 2208
    }
  },
  "/sk/kancelarie/bts-cargo-budova-bcd-ivanska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "bts-cargo-budova-bcd-bratislava",
      "id": 1332
    }
  },
  "/sk/kancelarie/carlton-savoy-2a-mostova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "carlton-savoy-2a-bratislava",
      "id": 1276
    }
  },
  "/sk/kancelarie/rosum-sublease-bajkalska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "rosum-sublease-bratislava",
      "id": 1286
    }
  },
  "/sk/kancelarie/prazska-11-prazska-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "prazska-11-bratislava",
      "id": 1448
    }
  },
  "/sk/kancelarie/dominant-namestie-hraniciarov-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dominant-bratislava",
      "id": 1290
    }
  },
  "/sk/kancelarie/dca-park-ilava-l-stura-ilava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "dca-park-ilava-ilava",
      "id": 1265
    }
  },
  "/sk/kancelarie/trnavska-cesta-32-trnavska-cesta-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "trnavska-cesta-32-bratislava",
      "id": 1414
    }
  },
  "/sk/kancelarie/stefanovicova-12-14-stefanovicova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stefanovicova-12-14-bratislava",
      "id": 1423
    }
  },
  "/sk/kancelarie/cassovar-business-center-i-sublease-zriedlova-kosice": {
    "name": "offices-slug-id",
    "params": {
      "slug": "cassovar-business-center-sublease-kosice",
      "id": 1289
    }
  },
  "/sk/kancelarie/svatoplukova-31-unicredit-bank-svatoplukova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "svatoplukova-31-bratislava",
      "id": 1235
    }
  },
  "/sk/kancelarie/stengl-campus-stare-podhradie-zizkova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "stengl-campus-stare-podhradie-bratislava",
      "id": 1381
    }
  },
  "/sk/kancelarie/pribinova-19-pribinova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "pribinova-19-bratislava",
      "id": 1379
    }
  },
  "/sk/kancelarie/tomasikova-30-tomasikova-bratislava": {
    "name": "offices-slug-id",
    "params": {
      "slug": "tomasikova-30-bratislava",
      "id": 1283
    }
  },
  "/sk/sklady/prologis-park-senec-dc1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc1-2850-sq-m-senec",
      "id": 1741
    }
  },
  "/en/warehouses/prologis-park-senec-dc1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc1-2850-sq-m-senec",
      "id": 1741
    }
  },
  "/sk/sklady/sklad-ruzinov-bratislava-ii-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-ruzinov-bratislava-ii-bratislava",
      "id": 2627
    }
  },
  "/en/warehouses/sklad-ruzinov-bratislava-ii-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-ruzinov-bratislava-ii-bratislava",
      "id": 2627
    }
  },
  "/sk/sklady/invest4see-park-dubnica-dc2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-dubnica-dc2-dubnica-nad-vahom",
      "id": 2287
    }
  },
  "/en/warehouses/invest4see-park-dubnica-dc2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-dubnica-dc2-dubnica-nad-vahom",
      "id": 2287
    }
  },
  "/sk/sklady/vector-parks-presov-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-presov-hala-b-presov",
      "id": 1660
    }
  },
  "/en/warehouses/vector-parks-presov-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-presov-hala-b-presov",
      "id": 1660
    }
  },
  "/sk/sklady/immopark-kosice-sk-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc11-kosice",
      "id": 1659
    }
  },
  "/en/warehouses/immopark-kosice-en-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc11-kosice",
      "id": 1659
    }
  },
  "/sk/sklady/ctpark-presov-pr1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr1-presov",
      "id": 2374
    }
  },
  "/en/warehouses/ctpark-presov-pr1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr1-presov",
      "id": 2374
    }
  },
  "/sk/sklady/priemyselny-park-dubnica-dc1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "invest4see-park-dubnica-dubnica-nad-vahom",
      "id": 1905
    }
  },
  "/en/warehouses/industrial-park-dubnica-dc1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "invest4see-park-dubnica-dubnica-nad-vahom",
      "id": 1905
    }
  },
  "/sk/sklady/skladovy-priestor-jobelsa-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovy-priestor-kosice-mestska-cast-juh-kosice",
      "id": 2030
    }
  },
  "/en/warehouses/skladovy-priestor-jobelsa-kosice-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovy-priestor-kosice-mestska-cast-juh-kosice",
      "id": 2030
    }
  },
  "/sk/sklady/ctpark-nitra-nit3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-nitra-nit3-nitra",
      "id": 1756
    }
  },
  "/en/warehouses/ctpark-nitra-nit3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-nitra-nit3-nitra",
      "id": 1756
    }
  },
  "/sk/sklady/vgp-park-malacky-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-b-malacky",
      "id": 1974
    }
  },
  "/en/warehouses/vgp-park-malacky-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-b-malacky",
      "id": 1974
    }
  },
  "/sk/sklady/vgp-park-malacky-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-a-malacky",
      "id": 1771
    }
  },
  "/en/warehouses/vgp-park-malacky-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-a-malacky",
      "id": 1771
    }
  },
  "/sk/sklady/vgp-park-malacky-hall-d-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-d-malacky",
      "id": 1770
    }
  },
  "/en/warehouses/vgp-park-malacky-hall-d-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-d-malacky",
      "id": 1770
    }
  },
  "/sk/sklady/prologis-park-senec-dc2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc2-1300-sq-m-senec",
      "id": 1742
    }
  },
  "/en/warehouses/prologis-park-senec-dc2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc2-1300-sq-m-senec",
      "id": 1742
    }
  },
  "/sk/sklady/vgp-park-malacky-hall-e-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-e-malacky",
      "id": 1772
    }
  },
  "/en/warehouses/vgp-park-malacky-hall-e-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-e-malacky",
      "id": 1772
    }
  },
  "/sk/sklady/prologis-park-senec-dc3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc3-senec",
      "id": 1738
    }
  },
  "/en/warehouses/prologis-park-senec-dc3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc3-senec",
      "id": 1738
    }
  },
  "/sk/sklady/prologis-park-senec-dc4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc4-senec",
      "id": 2319
    }
  },
  "/en/warehouses/prologis-park-senec-dc4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc4-senec",
      "id": 2319
    }
  },
  "/sk/sklady/p3-lozorno-dc-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-1-lozorno",
      "id": 2110
    }
  },
  "/en/warehouses/p3-lozorno-dc-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-1-lozorno",
      "id": 2110
    }
  },
  "/sk/sklady/palmira-senec-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-senec-hall-b-senec",
      "id": 1950
    }
  },
  "/en/warehouses/palmira-senec-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-senec-hall-b-senec",
      "id": 1950
    }
  },
  "/sk/sklady/vector-parks-sv-jur-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-sv-jur-hall-a-svaty-jur",
      "id": 1725
    }
  },
  "/en/warehouses/vector-parks-sv-jur-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-sv-jur-hall-a-svaty-jur",
      "id": 1725
    }
  },
  "/sk/sklady/palmira-senec-hall-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-senec-hall-c-senec",
      "id": 1784
    }
  },
  "/en/warehouses/palmira-senec-hall-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-senec-hall-c-senec",
      "id": 1784
    }
  },
  "/sk/sklady/vector-parks-bratislava-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-hala-a-bratislava",
      "id": 1723
    }
  },
  "/en/warehouses/vector-parks-bratislava-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-hala-a-bratislava",
      "id": 1723
    }
  },
  "/sk/sklady/goodman-international-senec-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-a-senec",
      "id": 2115
    }
  },
  "/en/warehouses/goodman-international-senec-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-a-senec",
      "id": 2115
    }
  },
  "/sk/sklady/goodman-international-senec-hall-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-c-senec",
      "id": 1944
    }
  },
  "/en/warehouses/goodman-international-senec-hall-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-c-senec",
      "id": 1944
    }
  },
  "/sk/sklady/goodman-international-senec-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-b-senec",
      "id": 1943
    }
  },
  "/en/warehouses/goodman-international-senec-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-b-senec",
      "id": 1943
    }
  },
  "/sk/sklady/goodman-international-senec-hall-d-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-d-senec",
      "id": 2089
    }
  },
  "/en/warehouses/goodman-international-senec-hall-d-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-d-senec",
      "id": 2089
    }
  },
  "/sk/sklady/p3-lozorno-dc-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-2-lozorno",
      "id": 1774
    }
  },
  "/en/warehouses/p3-lozorno-dc-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-2-lozorno",
      "id": 1774
    }
  },
  "/sk/sklady/p3-lozorno-dc-4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-4-lozorno",
      "id": 2109
    }
  },
  "/en/warehouses/p3-lozorno-dc-4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-4-lozorno",
      "id": 2109
    }
  },
  "/sk/sklady/p3-lozorno-dc-5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-5-lozorno",
      "id": 1775
    }
  },
  "/en/warehouses/p3-lozorno-dc-5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-5-lozorno",
      "id": 1775
    }
  },
  "/sk/sklady/p3-lozorno-dc-6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6-lozorno",
      "id": 2335
    }
  },
  "/en/warehouses/p3-lozorno-dc-6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6-lozorno",
      "id": 2335
    }
  },
  "/sk/sklady/prologis-park-senec-dc5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc5-senec",
      "id": 2091
    }
  },
  "/en/warehouses/prologis-park-senec-dc5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc5-senec",
      "id": 2091
    }
  },
  "/sk/sklady/p3-lozorno-dc-7-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-7-lozorno",
      "id": 2108
    }
  },
  "/en/warehouses/p3-lozorno-dc-7-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-7-lozorno",
      "id": 2108
    }
  },
  "/sk/sklady/prologis-park-senec-dc6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc6-3-750-sq-m-senec",
      "id": 1945
    }
  },
  "/en/warehouses/prologis-park-senec-dc6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc6-3-750-sq-m-senec",
      "id": 1945
    }
  },
  "/sk/sklady/prologis-park-senec-dc7-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc7-8-898-sq-m-senec",
      "id": 1749
    }
  },
  "/en/warehouses/prologis-park-senec-dc7-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc7-8-898-sq-m-senec",
      "id": 1749
    }
  },
  "/sk/sklady/prologis-park-senec-dc8-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc8-senec",
      "id": 2090
    }
  },
  "/en/warehouses/prologis-park-senec-dc8-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc8-senec",
      "id": 2090
    }
  },
  "/sk/sklady/prologis-park-senec-dc9-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc9-senec",
      "id": 2092
    }
  },
  "/en/warehouses/prologis-park-senec-dc9-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc9-senec",
      "id": 2092
    }
  },
  "/sk/sklady/prologis-park-senec-dc-10a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc-10a-senec",
      "id": 1739
    }
  },
  "/en/warehouses/prologis-park-senec-dc-10a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc-10a-senec",
      "id": 1739
    }
  },
  "/sk/sklady/prologis-park-senec-dc-10b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc-10b-5-878-sq-m-senec",
      "id": 2093
    }
  },
  "/en/warehouses/prologis-park-senec-dc-10b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc-10b-5-878-sq-m-senec",
      "id": 2093
    }
  },
  "/sk/sklady/arete-park-nove-mesto-nad-vahom-dc1a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc1-nove-mesto-nad-vahom",
      "id": 2364
    }
  },
  "/en/warehouses/arete-park-nove-mesto-nad-vahom-dc1a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc1-nove-mesto-nad-vahom",
      "id": 2364
    }
  },
  "/sk/sklady/industrial-park-horny-hricov-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "industrial-park-horny-hricov-horny-hricov",
      "id": 2027
    }
  },
  "/en/warehouses/industrial-park-horny-hricov-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "industrial-park-horny-hricov-horny-hricov",
      "id": 2027
    }
  },
  "/sk/sklady/logicor-nove-mesto-building-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "logicor-nove-mesto-building-1-nove-mesto-nad-vahom",
      "id": 2056
    }
  },
  "/en/warehouses/logicor-nove-mesto-building-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "logicor-nove-mesto-building-1-nove-mesto-nad-vahom",
      "id": 2056
    }
  },
  "/sk/sklady/prologis-park-senec-dc-11-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc-11-senec",
      "id": 2095
    }
  },
  "/en/warehouses/prologis-park-senec-dc11-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc-11-senec",
      "id": 2095
    }
  },
  "/sk/sklady/prologis-park-senec-dc12-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc12-senec",
      "id": 1948
    }
  },
  "/en/warehouses/prologis-park-senec-dc12-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc12-senec",
      "id": 1948
    }
  },
  "/sk/sklady/prologis-park-senec-dc13-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc13-senec",
      "id": 1740
    }
  },
  "/en/warehouses/prologis-park-senec-dc13-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc13-senec",
      "id": 1740
    }
  },
  "/sk/sklady/prologis-park-senec-dc14-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc14-senec",
      "id": 1947
    }
  },
  "/en/warehouses/prologis-park-senec-dc14-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc14-senec",
      "id": 1947
    }
  },
  "/sk/sklady/prologis-park-senec-dc15-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc15-senec",
      "id": 1949
    }
  },
  "/en/warehouses/prologis-park-senec-dc15-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc15-senec",
      "id": 1949
    }
  },
  "/sk/sklady/goodman-international-kosice-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-kosice-kosice",
      "id": 1644
    }
  },
  "/en/warehouses/goodman-international-kosice-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-kosice-kosice",
      "id": 1644
    }
  },
  "/sk/sklady/aig-lozorno-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-b-lozorno",
      "id": 1957
    }
  },
  "/en/warehouses/aig-lozorno-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-b-lozorno",
      "id": 1957
    }
  },
  "/sk/sklady/presskam-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-01-bratislava",
      "id": 2303
    }
  },
  "/en/warehouses/presskam-bratislava-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-01-bratislava",
      "id": 2303
    }
  },
  "/sk/sklady/ctpark-bratislava-bra3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra3-bratislava",
      "id": 1981
    }
  },
  "/en/warehouses/ctpark-bratislava-bra3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra3-bratislava",
      "id": 1981
    }
  },
  "/sk/sklady/ctpark-bratislava-bra5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra5-bratislava",
      "id": 1979
    }
  },
  "/en/warehouses/ctpark-bratislava-bra5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra5-bratislava",
      "id": 1979
    }
  },
  "/sk/sklady/ctpark-bratislava-bra6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra6-bratislava",
      "id": 2302
    }
  },
  "/en/warehouses/ctpark-bratislava-bra6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra6-bratislava",
      "id": 2302
    }
  },
  "/sk/sklady/ctpark-bratislava-bra8-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra8-bratislava",
      "id": 2540
    }
  },
  "/en/warehouses/ctpark-bratislava-bra8-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra8-bratislava",
      "id": 2540
    }
  },
  "/sk/sklady/ctpark-bratislava-bra9-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra9-bratislava",
      "id": 1980
    }
  },
  "/en/warehouses/ctpark-bratislava-bra9-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra9-bratislava",
      "id": 1980
    }
  },
  "/sk/sklady/ctpark-bratislava-bra11-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra11-bratislava",
      "id": 1978
    }
  },
  "/en/warehouses/ctpark-bratislava-bra11-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra11-bratislava",
      "id": 1978
    }
  },
  "/sk/sklady/ctpark-bratislava-bra13-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra13-bratislava",
      "id": 2112
    }
  },
  "/en/warehouses/ctpark-bratislava-bra13-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra13-bratislava",
      "id": 2112
    }
  },
  "/sk/sklady/ctpark-bratislava-bra15-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra15-bratislava",
      "id": 2471
    }
  },
  "/en/warehouses/ctpark-bratislava-bra15-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra15-bratislava",
      "id": 2471
    }
  },
  "/sk/sklady/aig-lozorno-hall-d-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-d-lozorno",
      "id": 1958
    }
  },
  "/en/warehouses/aig-lozorno-hall-d-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-d-lozorno",
      "id": 1958
    }
  },
  "/sk/sklady/aig-lozorno-hall-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-c-lozorno",
      "id": 2533
    }
  },
  "/en/warehouses/aig-lozorno-hall-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-c-lozorno",
      "id": 2533
    }
  },
  "/sk/sklady/aig-lozorno-hall-e-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-e-lozorno",
      "id": 1755
    }
  },
  "/en/warehouses/aig-lozorno-hall-e-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-e-lozorno",
      "id": 1755
    }
  },
  "/sk/sklady/ctpark-bratislava-bra14-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra14-bratislava",
      "id": 2620
    }
  },
  "/en/warehouses/ctpark-bratislava-bra14-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra14-bratislava",
      "id": 2620
    }
  },
  "/sk/sklady/ctpark-bratislava-bra10-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra10-bratislava",
      "id": 2496
    }
  },
  "/en/warehouses/ctpark-bratislava-bra10-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra10-bratislava",
      "id": 2496
    }
  },
  "/sk/sklady/ctpark-bratislava-bra7-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra7-bratislava",
      "id": 2111
    }
  },
  "/en/warehouses/ctpark-bratislava-bra7-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra7-bratislava",
      "id": 2111
    }
  },
  "/sk/sklady/ctpark-bratislava-bra4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra4-bratislava",
      "id": 2425
    }
  },
  "/en/warehouses/ctpark-bratislava-bra4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra4-bratislava",
      "id": 2425
    }
  },
  "/sk/sklady/ctpark-bratislava-bra2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra2-bratislava",
      "id": 2330
    }
  },
  "/en/warehouses/ctpark-bratislava-bra2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra2-bratislava",
      "id": 2330
    }
  },
  "/sk/sklady/ctpark-bratislava-bra1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra1-bratislava",
      "id": 1778
    }
  },
  "/en/warehouses/ctpark-bratislava-bra1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra1-bratislava",
      "id": 1778
    }
  },
  "/sk/sklady/auo-trencin-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "redside-park-trencin-trencin",
      "id": 2617
    }
  },
  "/en/warehouses/auo-trencin-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "redside-park-trencin-trencin",
      "id": 2617
    }
  },
  "/sk/sklady/cnic-park-galanta-dc1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc1-galanta",
      "id": 2550
    }
  },
  "/en/warehouses/cnic-park-galanta-dc1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc1-galanta",
      "id": 2550
    }
  },
  "/sk/sklady/ctpark-voderady-vod1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-voderady-vod1-voderady",
      "id": 1759
    }
  },
  "/en/warehouses/ctpark-voderady-vod1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-voderady-vod1-voderady",
      "id": 1759
    }
  },
  "/sk/sklady/ctpark-trnava-trn1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn1-trnava",
      "id": 2518
    }
  },
  "/en/warehouses/ctpark-trnava-trn1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn1-trnava",
      "id": 2518
    }
  },
  "/sk/sklady/falcon-senec-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-senec-hala-a-senec",
      "id": 2060
    }
  },
  "/en/warehouses/falcon-senec-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-senec-hala-a-senec",
      "id": 2060
    }
  },
  "/sk/sklady/p3-lozorno-dc-3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-3-lozorno",
      "id": 1773
    }
  },
  "/en/warehouses/p3-lozorno-dc-3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-3-lozorno",
      "id": 1773
    }
  },
  "/sk/sklady/p3-zilina-dc3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-zilina-dc3-strecno",
      "id": 1880
    }
  },
  "/en/warehouses/p3-zilina-dc3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-zilina-dc3-strecno",
      "id": 1880
    }
  },
  "/sk/sklady/p3-zilina-dc-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-zilina-dc-2-strecno",
      "id": 1642
    }
  },
  "/en/warehouses/p3-zilina-dc-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-zilina-dc-2-strecno",
      "id": 1642
    }
  },
  "/sk/sklady/p3-zilina-dc-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-zilina-dc-1-strecno",
      "id": 2618
    }
  },
  "/en/warehouses/p3-zilina-dc-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-zilina-dc-1-strecno",
      "id": 2618
    }
  },
  "/sk/sklady/ctpark-zilina-zg1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-zg1-gbelany",
      "id": 2022
    }
  },
  "/en/warehouses/ctpark-zilina-zg1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-zg1-gbelany",
      "id": 2022
    }
  },
  "/sk/sklady/vgp-park-malacky-hall-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-c-malacky",
      "id": 1973
    }
  },
  "/en/warehouses/vgp-park-malacky-hall-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-malacky-hall-c-malacky",
      "id": 1973
    }
  },
  "/sk/sklady/immopark-zilina-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z1-dolny-hricov",
      "id": 2034
    }
  },
  "/en/warehouses/immopark-zilina-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z1-dolny-hricov",
      "id": 2034
    }
  },
  "/sk/sklady/vector-parks-bratislava-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-hall-b-bratislava",
      "id": 2081
    }
  },
  "/en/warehouses/vector-parks-bratislava-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-hall-b-bratislava",
      "id": 2081
    }
  },
  "/sk/sklady/vector-park-bratislava-hall-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-hall-c-bratislava",
      "id": 1726
    }
  },
  "/en/warehouses/vector-parks-bratislava-hall-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-hall-c-bratislava",
      "id": 1726
    }
  },
  "/sk/sklady/vector-parks-sv-jur-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-sv-jur-hall-b-svaty-jur",
      "id": 1932
    }
  },
  "/en/warehouses/vector-parks-sv-jur-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-sv-jur-hall-b-svaty-jur",
      "id": 1932
    }
  },
  "/sk/sklady/aig-lozorno-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-a-lozorno",
      "id": 1959
    }
  },
  "/en/warehouses/aig-lozorno-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aig-lozorno-hall-a-lozorno",
      "id": 1959
    }
  },
  "/sk/sklady/sklad-ruzinov-ba-ii-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-ruzinov-ba-ii-bratislava",
      "id": 2358
    }
  },
  "/en/warehouses/sklad-ruzinov-ba-ii-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-ruzinov-ba-ii-bratislava",
      "id": 2358
    }
  },
  "/sk/sklady/ctpark-bratislava-bra12-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra12-bratislava",
      "id": 1777
    }
  },
  "/en/warehouses/ctpark-bratislava-bra12-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra12-bratislava",
      "id": 1777
    }
  },
  "/sk/sklady/pestovatelska-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava",
      "id": 2066
    }
  },
  "/en/warehouses/pestovatelska-bratislava-ii-1455-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava",
      "id": 2066
    }
  },
  "/sk/sklady/ivanka-pri-dunaji-324-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-324-m2-ivanka-pri-dunaji",
      "id": 2116
    }
  },
  "/en/warehouses/ivanka-pri-dunaji-324-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-324-m2-ivanka-pri-dunaji",
      "id": 2116
    }
  },
  "/sk/sklady/ivanska-cesta-bratislava-ii-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanska-cesta-bratislava-ii-bratislava",
      "id": 2123
    }
  },
  "/en/warehouses/ivanska-cesta-bratislava-ii-1157-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanska-cesta-bratislava-ii-bratislava",
      "id": 2123
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-sk-1": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-bratislava",
      "id": 2144
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-360-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-bratislava",
      "id": 2144
    }
  },
  "/sk/sklady/roznavska-bratislava-iii-560-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-560-m2-bratislava",
      "id": 2128
    }
  },
  "/en/warehouses/roznavska-bratislava-iii-560-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-560-m2-bratislava",
      "id": 2128
    }
  },
  "/sk/sklady/udernicka-bratislava-v-520-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-bratislava-v-520-m2-bratislava",
      "id": 2118
    }
  },
  "/en/warehouses/udernicka-bratislava-v-520-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-bratislava-v-520-m2-bratislava",
      "id": 2118
    }
  },
  "/sk/sklady/pestovatelska-bratislava-ii-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-bratislava",
      "id": 2625
    }
  },
  "/en/warehouses/pestovatelska-bratislava-ii-325-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-bratislava",
      "id": 2625
    }
  },
  "/sk/sklady/roznavska-bratislava-iii-8000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-8000-m2-bratislava",
      "id": 1987
    }
  },
  "/en/warehouses/roznavska-bratislava-iii-8000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-8000-m2-bratislava",
      "id": 1987
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-950-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-950-m2-bratislava",
      "id": 2131
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-950-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-950-m2-bratislava",
      "id": 2131
    }
  },
  "/sk/sklady/pestovatelska-bratislava-ii-600-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-600-m2-bratislava",
      "id": 2458
    }
  },
  "/en/warehouses/pestovatelska-bratislava-ii-600-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-600-m2-bratislava",
      "id": 2458
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-6-000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-6-000-m2-bratislava",
      "id": 2424
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-6-000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-6-000-m2-bratislava",
      "id": 2424
    }
  },
  "/sk/sklady/drobneho-bratislava-iv-1-454-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "drobneho-bratislava-iv-1-454-m2-bratislava",
      "id": 2336
    }
  },
  "/en/warehouses/drobneho-bratislava-iv-1-454-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "drobneho-bratislava-iv-1-454-m2-bratislava",
      "id": 2336
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-300-m2-sk-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-300-m2-bratislava",
      "id": 1907
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-300-m2-en-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-300-m2-bratislava",
      "id": 1907
    }
  },
  "/sk/sklady/magnetova-bratislava-iii-1-732-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "magnetova-bratislava-iii-1-732-m2-bratislava",
      "id": 2325
    }
  },
  "/en/warehouses/magnetova-bratislava-iii-1-732-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "magnetova-bratislava-iii-1-732-m2-bratislava",
      "id": 2325
    }
  },
  "/sk/sklady/pestovatelska-bratislava-iii-1368-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-iii-1368-m2-bratislava",
      "id": 1995
    }
  },
  "/en/warehouses/pestovatelska-bratislava-iii-1368-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-iii-1368-m2-bratislava",
      "id": 1995
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-600-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-600-m2-bratislava",
      "id": 1793
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-600-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-600-m2-bratislava",
      "id": 1793
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-980-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-980-m2-bratislava",
      "id": 1709
    }
  },
  "/en/warehouses/vajnorska-bratislava-iii-980-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-980-m2-bratislava",
      "id": 1709
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-600-m2-sk-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-600-m2-bratislava",
      "id": 1691
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-600-m2-en-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-600-m2-bratislava",
      "id": 1691
    }
  },
  "/sk/sklady/stavitelska-bratislava-iii-1-000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-1-000-m2-bratislava",
      "id": 1724
    }
  },
  "/en/warehouses/stavitelska-bratislava-iii-1-000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-1-000-m2-bratislava",
      "id": 1724
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-336-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-336-m2-bratislava",
      "id": 2576
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-336-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-336-m2-bratislava",
      "id": 2576
    }
  },
  "/sk/sklady/turbinova-bratislava-iii-1823-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-1823-m2-bratislava",
      "id": 2130
    }
  },
  "/en/warehouses/turbinova-bratislava-iii-1823-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-1823-m2-bratislava",
      "id": 2130
    }
  },
  "/sk/sklady/skladove-priestory-opatovska-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-opatovska-kosice",
      "id": 2469
    }
  },
  "/en/warehouses/skladove-priestory-opatovska-kosice-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-opatovska-kosice",
      "id": 2469
    }
  },
  "/sk/sklady/skladove-priestory-niklova-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-niklova-kosice",
      "id": 2500
    }
  },
  "/en/warehouses/skladovy-priestor-niklova-kosice-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-niklova-kosice",
      "id": 2500
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-980-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-980-m2-bratislava",
      "id": 1701
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-980-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-980-m2-bratislava",
      "id": 1701
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-1468-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1468-m2-bratislava",
      "id": 1913
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-1468-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1468-m2-bratislava",
      "id": 1913
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-435-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-435-m2-bratislava",
      "id": 2070
    }
  },
  "/en/warehouses/vajnorska-bratislava-iii-435-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-435-m2-bratislava",
      "id": 2070
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-1419-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1419-m2-bratislava",
      "id": 2011
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-1419-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1419-m2-bratislava",
      "id": 2011
    }
  },
  "/sk/sklady/raca-bratislava-iii-1000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-1000-m2-bratislava",
      "id": 2050
    }
  },
  "/en/warehouses/raca-bratislava-iii-1000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-1000-m2-bratislava",
      "id": 2050
    }
  },
  "/sk/sklady/raca-bratislava-iii-900-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-900-m2-bratislava",
      "id": 1679
    }
  },
  "/en/warehouses/raca-bratislava-iii-900-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-900-m2-bratislava",
      "id": 1679
    }
  },
  "/sk/sklady/priemyselny-park-michalovce-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselny-park-michalovce-michalovce",
      "id": 2373
    }
  },
  "/en/warehouses/priemyselny-park-michalovce-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselny-park-michalovce-michalovce",
      "id": 2373
    }
  },
  "/sk/sklady/jarovce-bratislava-1840-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "jarovce-bratislava-1840-m-bratislava",
      "id": 1697
    }
  },
  "/en/warehouses/jarovce-bratislava-1840-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "jarovce-bratislava-1840-m-bratislava",
      "id": 1697
    }
  },
  "/sk/sklady/pri-sajbach-raca-bratislava-iii-695-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pri-sajbach-raca-bratislava-iii-695-m-bratislava",
      "id": 1916
    }
  },
  "/en/warehouses/pri-sajbach-raca-bratislava-iii-695-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pri-sajbach-raca-bratislava-iii-695-m-bratislava",
      "id": 1916
    }
  },
  "/sk/sklady/stara-vajnorska-nove-mesto-bratislava-iii-927-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-nove-mesto-bratislava-iii-927-m-bratislava",
      "id": 1699
    }
  },
  "/en/warehouses/stara-vajnorska-nove-mesto-bratislava-iii-927-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-nove-mesto-bratislava-iii-927-m-bratislava",
      "id": 1699
    }
  },
  "/sk/sklady/na-pantoch-raca-bratislava-iii-350-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-350-m-bratislava",
      "id": 1703
    }
  },
  "/en/warehouses/na-pantoch-raca-bratislava-iii-350-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-350-m-bratislava",
      "id": 1703
    }
  },
  "/sk/sklady/na-pantoch-raca-bratislava-iii-5000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-5000-m-bratislava",
      "id": 1984
    }
  },
  "/en/warehouses/na-pantoch-raca-bratislava-iii-5000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-5000-m-bratislava",
      "id": 1984
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-896-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-896-m2-bratislava",
      "id": 1721
    }
  },
  "/en/warehouses/vajnorska-bratislava-iii-896-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-896-m2-bratislava",
      "id": 1721
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-320-m2-sk-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-320-m-bratislava",
      "id": 1702
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-320-m2-en-2": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-320-m-bratislava",
      "id": 1702
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-619-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-619-m2-bratislava",
      "id": 2134
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-619-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-619-m2-bratislava",
      "id": 2134
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-307-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-307-m2-bratislava",
      "id": 1805
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-307-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-307-m2-bratislava",
      "id": 1805
    }
  },
  "/sk/sklady/pezinok-2400-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-2400-m2-bratislava",
      "id": 2400
    }
  },
  "/en/warehouses/pezinok-2400-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-2400-m2-bratislava",
      "id": 2400
    }
  },
  "/sk/sklady/kopcianska-petrzalka-bratislava-646-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-petrzalka-bratislava-646-m-bratislava",
      "id": 2063
    }
  },
  "/en/warehouses/kopcianska-petrzalka-bratislava-646-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-petrzalka-bratislava-646-m-bratislava",
      "id": 2063
    }
  },
  "/sk/sklady/kopcianska-petrzalka-bratislava-370-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-petrzalka-bratislava-370-m-bratislava",
      "id": 1698
    }
  },
  "/en/warehouses/kopcianska-petrzalka-bratislava-370-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-petrzalka-bratislava-370-m-bratislava",
      "id": 1698
    }
  },
  "/sk/sklady/ivanka-pri-dunaji-senec-600-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-senec-600-m-senec",
      "id": 2563
    }
  },
  "/en/warehouses/ivanka-pri-dunaji-senec-600-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-senec-600-m-senec",
      "id": 2563
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-900-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-900-m2-bratislava",
      "id": 2569
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-900-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-900-m2-bratislava",
      "id": 2569
    }
  },
  "/sk/sklady/turbinova-bratislava-iii-338-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-338-m2-bratislava",
      "id": 1804
    }
  },
  "/en/warehouses/turbinova-bratislava-iii-338-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-338-m2-bratislava",
      "id": 1804
    }
  },
  "/sk/sklady/raca-bratislava-iii-684-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-684-m2-bratislava",
      "id": 1896
    }
  },
  "/en/warehouses/raca-bratislava-iii-684-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-684-m2-bratislava",
      "id": 1896
    }
  },
  "/sk/sklady/pestovatelska-ruzinov-bratislava-ii-1075-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-ruzinov-bratislava-ii-1075-m-bratislava",
      "id": 2446
    }
  },
  "/en/warehouses/pestovatelska-ruzinov-bratislava-ii-1075-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-ruzinov-bratislava-ii-1075-m-bratislava",
      "id": 2446
    }
  },
  "/sk/sklady/priemyselna-hala-trnava-modranka-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselna-hala-trnava-modranka-trnava",
      "id": 2562
    }
  },
  "/en/warehouses/priemyselna-hala-trnava-modranka-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselna-hala-trnava-modranka-trnava",
      "id": 2562
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-403-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-403-m2-bratislava",
      "id": 2079
    }
  },
  "/en/warehouses/vajnorska-bratislava-iii-403-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-403-m2-bratislava",
      "id": 2079
    }
  },
  "/sk/sklady/mlynske-nivy-ruzinov-bratislava-ii-1670-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mlynske-nivy-ruzinov-bratislava-ii-1670-m-bratislava",
      "id": 1800
    }
  },
  "/en/warehouses/mlynske-nivy-ruzinov-bratislava-ii-1670-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mlynske-nivy-ruzinov-bratislava-ii-1670-m-bratislava",
      "id": 1800
    }
  },
  "/sk/sklady/podunajske-biskupice-1567-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "podunajske-biskupice-1567-m2-bratislava",
      "id": 2080
    }
  },
  "/en/warehouses/podunajske-biskupice-1567-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "podunajske-biskupice-1567-m2-bratislava",
      "id": 2080
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-410-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-410-m2-bratislava",
      "id": 1710
    }
  },
  "/en/warehouses/vajnorska-bratislava-iii-410-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-410-m2-bratislava",
      "id": 1710
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-320-m2-sk-1": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-865-m2-bratislava",
      "id": 1920
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-320-m2-en-1": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-865-m2-bratislava",
      "id": 1920
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-324-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-324-m2-bratislava",
      "id": 2067
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-324-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-324-m2-bratislava",
      "id": 2067
    }
  },
  "/sk/sklady/ivanka-pri-dunaji-senec-502-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-senec-502-m-senec",
      "id": 2389
    }
  },
  "/en/warehouses/ivanka-pri-dunaji-senec-502-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-senec-502-m-senec",
      "id": 2389
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-1-500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-1-500-m2-bratislava",
      "id": 2051
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-1-500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-1-500-m2-bratislava",
      "id": 2051
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-1000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-1000-m2-bratislava",
      "id": 2059
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-1000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-1000-m2-bratislava",
      "id": 2059
    }
  },
  "/sk/sklady/puchovska-raca-bratislava-iii-2430-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "puchovska-raca-bratislava-iii-2430-m-bratislava",
      "id": 1799
    }
  },
  "/en/warehouses/puchovska-raca-bratislava-iii-2430-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "puchovska-raca-bratislava-iii-2430-m-bratislava",
      "id": 1799
    }
  },
  "/sk/sklady/rybnicna-bratislava-iii-440-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-440-m2-bratislava",
      "id": 1796
    }
  },
  "/en/warehouses/rybnicna-bratislava-iii-440-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-440-m2-bratislava",
      "id": 1796
    }
  },
  "/sk/sklady/ivanka-pri-dunaji-senec-400-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-senec-400-m-senec",
      "id": 2585
    }
  },
  "/en/warehouses/ivanka-pri-dunaji-senec-400-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-senec-400-m-senec",
      "id": 2585
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-2000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-2000-m2-bratislava",
      "id": 1993
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-2000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-2000-m2-bratislava",
      "id": 1993
    }
  },
  "/sk/sklady/rybnicna-bratislava-iii-480-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-480-m2-bratislava",
      "id": 1792
    }
  },
  "/en/warehouses/rybnicna-bratislava-iii-480-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-480-m2-bratislava",
      "id": 1792
    }
  },
  "/sk/sklady/stara-vajnorska-nove-mesto-bratislava-iii-491-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-nove-mesto-bratislava-iii-491-m-bratislava",
      "id": 2377
    }
  },
  "/en/warehouses/stara-vajnorska-nove-mesto-bratislava-iii-491-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-nove-mesto-bratislava-iii-491-m-bratislava",
      "id": 2377
    }
  },
  "/sk/sklady/na-pantoch-bratislava-iii-350-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-350-m2-bratislava",
      "id": 2120
    }
  },
  "/en/warehouses/na-pantoch-bratislava-iii-350-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-350-m2-bratislava",
      "id": 2120
    }
  },
  "/sk/sklady/stavitelska-bratislava-iii-790-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-790-m2-bratislava",
      "id": 1931
    }
  },
  "/en/warehouses/stavitelska-bratislava-iii-790-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-790-m2-bratislava",
      "id": 1931
    }
  },
  "/sk/sklady/na-pantoch-bratislava-iii-328-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-328-m2-bratislava",
      "id": 2414
    }
  },
  "/en/warehouses/na-pantoch-bratislava-iii-328-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-328-m2-bratislava",
      "id": 2414
    }
  },
  "/sk/sklady/na-pantoch-raca-bratislava-iii-822-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-822-m-bratislava",
      "id": 2506
    }
  },
  "/en/warehouses/na-pantoch-raca-bratislava-iii-822-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-822-m-bratislava",
      "id": 2506
    }
  },
  "/sk/sklady/magnetova-nove-mesto-bratislava-iii-303-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "magnetova-nove-mesto-bratislava-iii-303-m-bratislava",
      "id": 1700
    }
  },
  "/en/warehouses/magnetova-nove-mesto-bratislava-iii-303-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "magnetova-nove-mesto-bratislava-iii-303-m-bratislava",
      "id": 1700
    }
  },
  "/sk/sklady/roznavska-bratislava-2484-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-2484-m2-bratislava",
      "id": 1809
    }
  },
  "/en/warehouses/roznavska-bratislava-iii-2484-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-2484-m2-bratislava",
      "id": 1809
    }
  },
  "/sk/sklady/ivanka-pri-dunaji-400-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-400-m2-ivanka-pri-dunaji",
      "id": 1985
    }
  },
  "/en/warehouses/ivanka-pri-dunaji-400-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-400-m2-ivanka-pri-dunaji",
      "id": 1985
    }
  },
  "/sk/sklady/na-pantoch-raca-bratislava-iii-733-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-733-m-bratislava",
      "id": 2064
    }
  },
  "/en/warehouses/na-pantoch-raca-bratislava-iii-733-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-733-m-bratislava",
      "id": 2064
    }
  },
  "/sk/sklady/odborarska-bratislava-iii-1-718-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "odborarska-bratislava-iii-1-718-m2-bratislava",
      "id": 1704
    }
  },
  "/en/warehouses/odborarska-bratislava-iii-1-718-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "odborarska-bratislava-iii-1-718-m2-bratislava",
      "id": 1704
    }
  },
  "/sk/sklady/ivanka-pri-dunaji-2500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-2100-m2-ivanka-pri-dunaji",
      "id": 1779
    }
  },
  "/en/warehouses/ivanka-pri-dunaji-2500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-2100-m2-ivanka-pri-dunaji",
      "id": 1779
    }
  },
  "/sk/sklady/na-pantoch-raca-bratislava-iii-550-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-550-m-bratislava",
      "id": 1722
    }
  },
  "/en/warehouses/na-pantoch-raca-bratislava-iii-550-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-raca-bratislava-iii-550-m-bratislava",
      "id": 1722
    }
  },
  "/sk/sklady/turbinova-bratislava-iii-751-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-751-m2-bratislava",
      "id": 2126
    }
  },
  "/en/warehouses/turbinova-bratislava-iii-751-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-751-m2-bratislava",
      "id": 2126
    }
  },
  "/sk/sklady/rybnicna-bratislava-iii-450-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-450-m2-bratislava",
      "id": 2329
    }
  },
  "/en/warehouses/rybnicna-bratislava-iii-450-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-450-m2-bratislava",
      "id": 2329
    }
  },
  "/sk/sklady/kopcianska-petrzalka-bratislava-2518-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-petrzalka-bratislava-2518-m-bratislava",
      "id": 2398
    }
  },
  "/en/warehouses/kopcianska-petrzalka-bratislava-2518-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-petrzalka-bratislava-2518-m-bratislava",
      "id": 2398
    }
  },
  "/sk/sklady/puchovska-bratislava-iii-2-430-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "puchovska-bratislava-iii-2-430-m2-bratislava",
      "id": 2353
    }
  },
  "/en/warehouses/puchovska-bratislava-iii-2-430-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "puchovska-bratislava-iii-2-430-m2-bratislava",
      "id": 2353
    }
  },
  "/sk/sklady/uranova-bratislava-iii-328-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "uranova-bratislava-iii-328-m2-bratislava",
      "id": 1711
    }
  },
  "/en/warehouses/uranova-bratislava-iii-382-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "uranova-bratislava-iii-328-m2-bratislava",
      "id": 1711
    }
  },
  "/sk/sklady/pestovatelska-bratislava-iii-1181-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-iii-1181-m2-bratislava",
      "id": 1990
    }
  },
  "/en/warehouses/pestovatelska-bratislava-iii-1181-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-iii-1181-m2-bratislava",
      "id": 1990
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-2700-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-2700-m2-bratislava",
      "id": 1798
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-2700-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-2700-m2-bratislava",
      "id": 1798
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-563-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-563-m2-bratislava",
      "id": 2466
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-563-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-563-m2-bratislava",
      "id": 2466
    }
  },
  "/sk/sklady/topnad-nitra-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "topnad-nitra-nitra",
      "id": 1961
    }
  },
  "/en/warehouses/topnad-nitra-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "topnad-nitra-nitra",
      "id": 1961
    }
  },
  "/sk/sklady/topnad-topolcany-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "topnad-topolcany-topolcany",
      "id": 1952
    }
  },
  "/en/warehouses/topnad-topolcany-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "topnad-topolcany-topolcany",
      "id": 1952
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-577-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-577-m2-bratislava",
      "id": 2061
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-577-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-577-m2-bratislava",
      "id": 2061
    }
  },
  "/sk/sklady/krajna-bratislava-ii-561-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "krajna-bratislava-ii-561-m2-bratislava",
      "id": 2558
    }
  },
  "/en/warehouses/krajna-bratislava-ii-561-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "krajna-bratislava-ii-561-m2-bratislava",
      "id": 2558
    }
  },
  "/sk/sklady/na-pantoch-bratislava-iii-445-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-445-m2-bratislava",
      "id": 2015
    }
  },
  "/en/warehouses/na-pantoch-bratislava-iii-445-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-445-m2-bratislava",
      "id": 2015
    }
  },
  "/sk/sklady/sklad-dubravka-bratislava-iv-1454-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-dubravka-bratislava-iv-1454-m2-bratislava",
      "id": 2346
    }
  },
  "/en/warehouses/dubravka-bratislava-iv-1454-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-dubravka-bratislava-iv-1454-m2-bratislava",
      "id": 2346
    }
  },
  "/sk/sklady/galvaniho-bratislava-ii-300-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "galvaniho-bratislava-ii-300-m2-bratislava",
      "id": 2596
    }
  },
  "/en/warehouses/galvaniho-bratislava-ii-300-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "galvaniho-bratislava-ii-300-m2-bratislava",
      "id": 2596
    }
  },
  "/sk/sklady/devinska-nova-ves-bratislava-iv-600-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "devinska-nova-ves-bratislava-iv-600-m2-bratislava",
      "id": 2133
    }
  },
  "/en/warehouses/devinska-nova-ves-bratislava-iv-600-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "devinska-nova-ves-bratislava-iv-600-m2-bratislava",
      "id": 2133
    }
  },
  "/sk/sklady/dubravka-bratislava-iv-300-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "dubravka-bratislava-iv-300-m2-bratislava",
      "id": 1885
    }
  },
  "/en/warehouses/dubravka-bratislava-iv-300-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "dubravka-bratislava-iv-300-m2-bratislava",
      "id": 1885
    }
  },
  "/sk/sklady/rybnicna-bratislava-iii-2536-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-2536-m2-bratislava",
      "id": 1694
    }
  },
  "/en/warehouses/rybnicna-bratislava-iii-2536-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rybnicna-bratislava-iii-2536-m2-bratislava",
      "id": 1694
    }
  },
  "/sk/sklady/pezinok-3460-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-3460-m2-pezinok",
      "id": 2430
    }
  },
  "/en/warehouses/pezinok-3460-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-3460-m2-pezinok",
      "id": 2430
    }
  },
  "/sk/sklady/vyrobna-hala-koseca-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobna-hala-koseca-koseca",
      "id": 1688
    }
  },
  "/en/warehouses/vyrobna-hala-koseca-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobna-hala-koseca-koseca",
      "id": 1688
    }
  },
  "/sk/sklady/ht-technology-park-vlkanova-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ht-technology-park-vlkanova-vlkanova",
      "id": 1898
    }
  },
  "/en/warehouses/ht-technology-park-vlkanova-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ht-technology-park-vlkanova-vlkanova",
      "id": 1898
    }
  },
  "/sk/sklady/vajnorska-bratislava-ii-890-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-ii-890-m2-bratislava",
      "id": 2082
    }
  },
  "/en/warehouses/vajnorska-bratislava-ii-890-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-ii-890-m2-bratislava",
      "id": 2082
    }
  },
  "/sk/sklady/pezinok-521-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-521-m2-pezinok",
      "id": 1748
    }
  },
  "/en/warehouses/pezinok-521-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-521-m2-pezinok",
      "id": 1748
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-400-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-400-m2-bratislava",
      "id": 1705
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-400-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-400-m2-bratislava",
      "id": 1705
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-576-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-576-m2-bratislava",
      "id": 1994
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-576-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-576-m2-bratislava",
      "id": 1994
    }
  },
  "/sk/sklady/dubravka-bratislava-iv-500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "dubravka-bratislava-iv-500-m2-bratislava",
      "id": 2372
    }
  },
  "/en/warehouses/dubravka-bratislava-iv-500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "dubravka-bratislava-iv-500-m2-bratislava",
      "id": 2372
    }
  },
  "/sk/sklady/ivanska-cesta-bratislava-ii-1160-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanska-cesta-bratislava-ii-1160-m2-bratislava",
      "id": 2127
    }
  },
  "/en/warehouses/ivanska-cesta-bratislava-ii-1160-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanska-cesta-bratislava-ii-1160-m2-bratislava",
      "id": 2127
    }
  },
  "/sk/sklady/pezinok-2600-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-2000-m2-pezinok",
      "id": 2375
    }
  },
  "/en/warehouses/pezinok-2600-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-2000-m2-pezinok",
      "id": 2375
    }
  },
  "/sk/sklady/klincova-bratislava-ii-950-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "klincova-bratislava-ii-950-m2-bratislava",
      "id": 1797
    }
  },
  "/en/warehouses/klincova-bratislava-ii-950-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "klincova-bratislava-ii-950-m2-bratislava",
      "id": 1797
    }
  },
  "/sk/sklady/pezinok-950-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-950-m2-pezinok",
      "id": 1747
    }
  },
  "/en/warehouses/pezinok-950-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-950-m2-pezinok",
      "id": 1747
    }
  },
  "/sk/sklady/kopcianska-bratislava-iv-1190-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-iv-1190-m2-bratislava",
      "id": 2409
    }
  },
  "/en/warehouses/kopcianska-bratislava-iv-1190-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-iv-1190-m2-bratislava",
      "id": 2409
    }
  },
  "/sk/sklady/ivanska-cesta-bratislava-ii-480-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanska-cesta-bratislava-ii-480-m2-bratislava",
      "id": 1802
    }
  },
  "/en/warehouses/ivanska-cesta-bratislava-ii-480-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanska-cesta-bratislava-ii-480-m2-bratislava",
      "id": 1802
    }
  },
  "/sk/sklady/pezinok-3000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-3000-m2-pezinok",
      "id": 1745
    }
  },
  "/en/warehouses/pezinok-3000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pezinok-3000-m2-pezinok",
      "id": 1745
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-1626-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1626-m2-bratislava",
      "id": 2433
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-1626-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1626-m2-bratislava",
      "id": 2433
    }
  },
  "/sk/sklady/puchovska-bratislava-iii-320-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "puchovska-bratislava-iii-320-m2-bratislava",
      "id": 2132
    }
  },
  "/en/warehouses/puchovska-bratislava-iii-320-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "puchovska-bratislava-iii-320-m2-bratislava",
      "id": 2132
    }
  },
  "/sk/sklady/podunajske-biskupice-bratislava-ii-520-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "podunajske-biskupice-bratislava-ii-520-m2-bratislava",
      "id": 2002
    }
  },
  "/en/warehouses/podunajske-biskupice-bratislava-ii-520-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "podunajske-biskupice-bratislava-ii-520-m2-bratislava",
      "id": 2002
    }
  },
  "/sk/sklady/na-pantoch-bratislava-iii-733-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-733-m2-bratislava",
      "id": 2461
    }
  },
  "/en/warehouses/na-pantoch-bratislava-iii-733-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-733-m2-bratislava",
      "id": 2461
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-2770-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-2770-m2-bratislava",
      "id": 1808
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-2770-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-2770-m2-bratislava",
      "id": 1808
    }
  },
  "/sk/sklady/vajnorska-bratislava-ii-473-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-ii-473-m2-bratislava",
      "id": 1927
    }
  },
  "/en/warehouses/vajnorska-bratislava-ii-473-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-ii-473-m2-bratislava",
      "id": 1927
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-1900-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1900-m2-bratislava",
      "id": 1815
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-1900-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1900-m2-bratislava",
      "id": 1815
    }
  },
  "/sk/sklady/pestovatelska-bratislava-ii-648-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-648-m2-bratislava",
      "id": 2537
    }
  },
  "/en/warehouses/pestovatelska-bratislava-ii-648-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-648-m2-bratislava",
      "id": 2537
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-860-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-860-m2-bratislava",
      "id": 2013
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-860-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-860-m2-bratislava",
      "id": 2013
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-2545-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-2545-m2-bratislava",
      "id": 1826
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-2545-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-2545-m2-bratislava",
      "id": 1826
    }
  },
  "/sk/sklady/skladova-hala-licartovce-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladova-hala-licartovce-licartovce",
      "id": 1883
    }
  },
  "/en/warehouses/skladova-hala-licartovce-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladova-hala-licartovce-licartovce",
      "id": 1883
    }
  },
  "/sk/sklady/sihot-park-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sihot-park-chocholna-dc1-trencin",
      "id": 2557
    }
  },
  "/en/warehouses/sihot-park-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sihot-park-chocholna-dc1-trencin",
      "id": 2557
    }
  },
  "/sk/sklady/ctpark-voderady-vod3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-voderady-vod2-voderady",
      "id": 1767
    }
  },
  "/en/warehouses/ctpark-voderady-vod3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-voderady-vod2-voderady",
      "id": 1767
    }
  },
  "/sk/sklady/ctpark-nitra-nit2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-nitra-nit2-nitra",
      "id": 2417
    }
  },
  "/en/warehouses/ctpark-nitra-nit2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-nitra-nit2-nitra",
      "id": 2417
    }
  },
  "/sk/sklady/ctpark-zilina-zg2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-zg2-gbelany",
      "id": 2038
    }
  },
  "/en/warehouses/ctpark-zilina-zg2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-zg2-gbelany",
      "id": 2038
    }
  },
  "/sk/sklady/ctpark-zilina-zg3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-zg3-gbelany",
      "id": 1647
    }
  },
  "/en/warehouses/ctpark-zilina-zg3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-zg3-gbelany",
      "id": 1647
    }
  },
  "/sk/sklady/ctpark-trnava-trn2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn2-trnava",
      "id": 2501
    }
  },
  "/en/warehouses/ctpark-trnava-trn2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn2-trnava",
      "id": 2501
    }
  },
  "/sk/sklady/ctpark-trnava-trn3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn3-trnava",
      "id": 1765
    }
  },
  "/en/warehouses/ctpark-trnava-trn3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn3-trnava",
      "id": 1765
    }
  },
  "/sk/sklady/ctpark-trnava-trn4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn4-trnava",
      "id": 2386
    }
  },
  "/en/warehouses/ctpark-trnava-trn4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn4-trnava",
      "id": 2386
    }
  },
  "/sk/sklady/ctpark-trnava-trn5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn5-trnava",
      "id": 1769
    }
  },
  "/en/warehouses/ctpark-trnava-trn5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn5-trnava",
      "id": 1769
    }
  },
  "/sk/sklady/ctpark-trnava-trn6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn6-trnava",
      "id": 1758
    }
  },
  "/en/warehouses/ctpark-trnava-trn6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn6-trnava",
      "id": 1758
    }
  },
  "/sk/sklady/ctpark-trnava-trn7-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn7-trnava",
      "id": 2583
    }
  },
  "/en/warehouses/ctpark-trnava-trn7-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn7-trnava",
      "id": 2583
    }
  },
  "/sk/sklady/ctpark-trnava-trn8-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn8-trnava",
      "id": 1761
    }
  },
  "/en/warehouses/ctpark-trnava-trn8-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn8-trnava",
      "id": 1761
    }
  },
  "/sk/sklady/ctpark-trnava-trn9-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn9-trnava",
      "id": 2499
    }
  },
  "/en/warehouses/ctpark-trnava-trn9-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn9-trnava",
      "id": 2499
    }
  },
  "/sk/sklady/ctpark-trnava-trn10-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn10-trnava",
      "id": 2429
    }
  },
  "/en/warehouses/ctpark-trnava-trn10-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn10-trnava",
      "id": 2429
    }
  },
  "/sk/sklady/ctpark-trnava-trn11-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn11-trnava",
      "id": 1766
    }
  },
  "/en/warehouses/ctpark-trnava-trn11-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn11-trnava",
      "id": 1766
    }
  },
  "/sk/sklady/ctpark-trnava-trn12-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn12-trnava",
      "id": 1768
    }
  },
  "/en/warehouses/ctpark-trnava-trn12-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn12-trnava",
      "id": 1768
    }
  },
  "/sk/sklady/ctpark-trnava-trn14-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn14-trnava",
      "id": 2100
    }
  },
  "/en/warehouses/ctpark-trnava-trn14-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn14-trnava",
      "id": 2100
    }
  },
  "/sk/sklady/ctpark-trnava-trn15-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn15-trnava",
      "id": 1764
    }
  },
  "/en/warehouses/ctpark-trnava-trn15-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn15-trnava",
      "id": 1764
    }
  },
  "/sk/sklady/ctpark-trnava-trn16-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn16-trnava",
      "id": 1970
    }
  },
  "/en/warehouses/ctpark-trnava-trn16-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-trnava-trn16-trnava",
      "id": 1970
    }
  },
  "/sk/sklady/ctpark-presov-pr2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr2-presov",
      "id": 2190
    }
  },
  "/en/warehouses/ctpark-presov-pr2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr2-presov",
      "id": 2190
    }
  },
  "/sk/sklady/ctpark-presov-pr3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr3-presov",
      "id": 2462
    }
  },
  "/en/warehouses/ctpark-presov-pr3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr3-presov",
      "id": 2462
    }
  },
  "/sk/sklady/ctpark-presov-pr4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr4-presov",
      "id": 1657
    }
  },
  "/en/warehouses/ctpark-presov-pr4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr4-presov",
      "id": 1657
    }
  },
  "/sk/sklady/ctpark-presov-pr5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr5-presov",
      "id": 2037
    }
  },
  "/en/warehouses/ctpark-presov-pr5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr5-presov",
      "id": 2037
    }
  },
  "/sk/sklady/ctpark-presov-pr6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr6-presov",
      "id": 2355
    }
  },
  "/en/warehouses/ctpark-presov-pr6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr6-presov",
      "id": 2355
    }
  },
  "/sk/sklady/ctpark-presov-pr7-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr7-presov",
      "id": 1870
    }
  },
  "/en/warehouses/ctpark-presov-pr7-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr7-presov",
      "id": 1870
    }
  },
  "/sk/sklady/ctpark-presov-pr8-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr8-presov",
      "id": 1640
    }
  },
  "/en/warehouses/ctpark-presov-pr8-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-pr8-presov",
      "id": 1640
    }
  },
  "/sk/sklady/goodman-international-senec-hall-e-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-e-senec",
      "id": 2094
    }
  },
  "/en/warehouses/goodman-international-senec-hall-e-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-senec-hall-e-senec",
      "id": 2094
    }
  },
  "/sk/sklady/vector-parks-presov-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-presov-hala-a-presov",
      "id": 1874
    }
  },
  "/en/warehouses/vector-parks-presov-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-presov-hala-a-presov",
      "id": 1874
    }
  },
  "/sk/sklady/karimpol-senec-hall-d-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "karimpol-senec-hall-d-senec",
      "id": 1951
    }
  },
  "/en/warehouses/karimpol-senec-hall-d-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "karimpol-senec-hall-d-senec",
      "id": 1951
    }
  },
  "/sk/sklady/mountpark-sered-building-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-a-sered",
      "id": 2507
    }
  },
  "/en/warehouses/mountpark-sered-building-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-a-sered",
      "id": 2507
    }
  },
  "/sk/sklady/mountpark-sered-building-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-b-sered",
      "id": 2378
    }
  },
  "/en/warehouses/mountpark-sered-building-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-b-sered",
      "id": 2378
    }
  },
  "/sk/sklady/mountpark-sered-building-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-c-sered",
      "id": 1972
    }
  },
  "/en/warehouses/mountpark-sered-building-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-c-sered",
      "id": 1972
    }
  },
  "/sk/sklady/mountpark-sered-building-d-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-e-sered",
      "id": 1966
    }
  },
  "/en/warehouses/mountpark-sered-building-d-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-sered-building-e-sered",
      "id": 1966
    }
  },
  "/sk/sklady/cehip-vrable-hall-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-1-vrable",
      "id": 1954
    }
  },
  "/en/warehouses/cehip-vrable-hall-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-1-vrable",
      "id": 1954
    }
  },
  "/sk/sklady/cehip-vrable-hall-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-2-vrable",
      "id": 1753
    }
  },
  "/en/warehouses/cehip-vrable-hall-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-2-vrable",
      "id": 1753
    }
  },
  "/sk/sklady/cehip-vrable-hall-3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-3-vrable",
      "id": 1956
    }
  },
  "/en/warehouses/cehip-vrable-hall-3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-3-vrable",
      "id": 1956
    }
  },
  "/sk/sklady/cehip-vrable-hall-4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-4-vrable",
      "id": 1960
    }
  },
  "/en/warehouses/cehip-vrable-hall-4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-4-vrable",
      "id": 1960
    }
  },
  "/sk/sklady/arete-park-nove-mesto-nad-vahom-dc4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc2-nove-mesto-nad-vahom",
      "id": 2360
    }
  },
  "/en/warehouses/arete-park-nove-mesto-nad-vahom-dc4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc2-nove-mesto-nad-vahom",
      "id": 2360
    }
  },
  "/sk/sklady/arete-park-nove-mesto-nad-vahom-dc5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc4-nove-mesto-nad-vahom",
      "id": 2049
    }
  },
  "/en/warehouses/arete-park-nove-mesto-nad-vahom-dc5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc4-nove-mesto-nad-vahom",
      "id": 2049
    }
  },
  "/sk/sklady/arete-park-nove-mesto-nad-vahom-dc3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc3-nove-mesto-nad-vahom",
      "id": 1676
    }
  },
  "/en/warehouses/arete-park-nove-mesto-nad-vahom-dc3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc3-nove-mesto-nad-vahom",
      "id": 1676
    }
  },
  "/sk/sklady/arete-park-nove-mesto-nad-vahom-dc5b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc5-nove-mesto-nad-vahom",
      "id": 2399
    }
  },
  "/en/warehouses/arete-park-nove-mesto-nad-vahom-dc5b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc5-nove-mesto-nad-vahom",
      "id": 2399
    }
  },
  "/sk/sklady/arete-park-nove-mesto-nad-vahom-dc6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc6-nove-mesto-nad-vahom",
      "id": 1675
    }
  },
  "/en/warehouses/arete-park-nove-mesto-nad-vahom-dc6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc6-nove-mesto-nad-vahom",
      "id": 1675
    }
  },
  "/sk/sklady/logicor-nove-mesto-building-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "logicor-nove-mesto-building-2-nove-mesto-nad-vahom",
      "id": 1902
    }
  },
  "/en/warehouses/logicor-nove-mesto-building-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "logicor-nove-mesto-building-2-nove-mesto-nad-vahom",
      "id": 1902
    }
  },
  "/sk/sklady/prologis-park-nitra-dc1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-nitra-dc1-nitra",
      "id": 1955
    }
  },
  "/en/warehouses/prologis-park-nitra-dc1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-nitra-dc1-nitra",
      "id": 1955
    }
  },
  "/sk/sklady/prologis-park-nitra-dc2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-nitra-dc2-nitra",
      "id": 1757
    }
  },
  "/en/warehouses/prologis-park-nitra-dc2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-nitra-dc2-nitra",
      "id": 1757
    }
  },
  "/sk/sklady/prologis-park-nitra-dc3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-nitra-dc3-nitra",
      "id": 2099
    }
  },
  "/en/warehouses/prologis-park-nitra-dc3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-nitra-dc3-nitra",
      "id": 2099
    }
  },
  "/sk/sklady/cnic-park-galanta-dc2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc2-galanta",
      "id": 2344
    }
  },
  "/en/warehouses/cnic-park-galanta-dc2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc2-galanta",
      "id": 2344
    }
  },
  "/sk/sklady/cnic-park-galanta-dc3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc3-galanta",
      "id": 1964
    }
  },
  "/en/warehouses/cnic-park-galanta-dc3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc3-galanta",
      "id": 1964
    }
  },
  "/sk/sklady/cnic-park-galanta-dc5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc5-galanta",
      "id": 2541
    }
  },
  "/en/warehouses/cnic-park-galanta-dc5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cgl-park-galanta-dc5-galanta",
      "id": 2541
    }
  },
  "/sk/sklady/prologis-park-senec-dc16-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc16-senec",
      "id": 1942
    }
  },
  "/en/warehouses/prologis-park-senec-dc16-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc16-senec",
      "id": 1942
    }
  },
  "/sk/sklady/prologis-park-senec-dc17-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc17-senec",
      "id": 2573
    }
  },
  "/en/warehouses/prologis-park-senec-dc17-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc17-senec",
      "id": 2573
    }
  },
  "/sk/sklady/prologis-park-senec-dc18-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc18-senec",
      "id": 1946
    }
  },
  "/en/warehouses/prologis-park-senec-dc18-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc18-senec",
      "id": 1946
    }
  },
  "/sk/sklady/prologis-park-senec-dc19-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc19-senec",
      "id": 1743
    }
  },
  "/en/warehouses/prologis-park-senec-dc19-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc19-senec",
      "id": 1743
    }
  },
  "/sk/sklady/prologis-park-senec-dc20-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc20-senec",
      "id": 1744
    }
  },
  "/en/warehouses/prologis-park-senec-dc20-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc20-senec",
      "id": 1744
    }
  },
  "/sk/sklady/prologis-park-senec-dc21-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc21-senec",
      "id": 2324
    }
  },
  "/en/warehouses/prologis-park-senec-dc21-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc21-senec",
      "id": 2324
    }
  },
  "/sk/sklady/link-nitra-park-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nitra-park-hall-1-luzianky",
      "id": 2560
    }
  },
  "/en/warehouses/link-nitra-park-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nitra-park-hall-1-luzianky",
      "id": 2560
    }
  },
  "/sk/sklady/invest4see-park-dubnica-dc1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-dubnica-dc1-dubnica-nad-vahom",
      "id": 2286
    }
  },
  "/en/warehouses/invest4see-park-dubnica-dc1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-dubnica-dc1-dubnica-nad-vahom",
      "id": 2286
    }
  },
  "/sk/sklady/invest4see-park-dubnica-dc3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "invest4see-park-dubnica-dc3-dubnica-nad-vahom",
      "id": 2459
    }
  },
  "/en/warehouses/invest4see-park-dubnica-dc3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "invest4see-park-dubnica-dc3-dubnica-nad-vahom",
      "id": 2459
    }
  },
  "/sk/sklady/industrial-zone-kechnec-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "industrial-zone-kechnec-kosice",
      "id": 1655
    }
  },
  "/en/warehouses/industrial-zone-kechnec-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "industrial-zone-kechnec-kosice",
      "id": 1655
    }
  },
  "/sk/sklady/ppg-industrial-park-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ppg-industrial-park-rimavska-sobota",
      "id": 1900
    }
  },
  "/en/warehouses/ppg-industrial-park-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ppg-industrial-park-rimavska-sobota",
      "id": 1900
    }
  },
  "/sk/sklady/roznavska-bratislava-iii-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-bratislava",
      "id": 2543
    }
  },
  "/en/warehouses/roznavska-bratislava-iii-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-bratislava",
      "id": 2543
    }
  },
  "/sk/sklady/turbinova-bratislava-iii-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-bratislava",
      "id": 1998
    }
  },
  "/en/warehouses/turbinova-bratislava-iii-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-bratislava",
      "id": 1998
    }
  },
  "/sk/sklady/skladovo-vyrobne-priestory-strojnicka-presov-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobne-priestory-strojnicka-presov-presov",
      "id": 1639
    }
  },
  "/en/warehouses/strojnicka-presov-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobne-priestory-strojnicka-presov-presov",
      "id": 1639
    }
  },
  "/sk/sklady/turbinova-city-logistics-bratislava-iii-6-000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-city-logistics-bratislava-iii-1-100-m2-bratislava",
      "id": 2457
    }
  },
  "/en/warehouses/turbinova-city-logistics-bratislava-iii-6-000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-city-logistics-bratislava-iii-1-100-m2-bratislava",
      "id": 2457
    }
  },
  "/sk/sklady/pnk-park-sered-building-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-sered-dc31-sered",
      "id": 2105
    }
  },
  "/en/warehouses/pnk-park-sered-building1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-sered-dc31-sered",
      "id": 2105
    }
  },
  "/sk/sklady/pnk-park-sered-building-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-sered-dc32-sered",
      "id": 2107
    }
  },
  "/en/warehouses/pnk-park-sered-building-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "reico-park-sered-dc32-sered",
      "id": 2107
    }
  },
  "/sk/sklady/prologis-park-ziar-nad-hronom-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "tam-park-ziar-nad-hronom-ziar-nad-hronom",
      "id": 2052
    }
  },
  "/en/warehouses/prologis-park-ziar-nad-hronom-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "tam-park-ziar-nad-hronom-ziar-nad-hronom",
      "id": 2052
    }
  },
  "/sk/sklady/link-nitra-park-hall-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nitra-park-hall-2-nitra",
      "id": 2369
    }
  },
  "/en/warehouses/link-nitra-park-hall-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nitra-park-hall-2-nitra",
      "id": 2369
    }
  },
  "/sk/sklady/vbp-mlynarce-park-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vbp-mlynarce-park-hall-a-nitra",
      "id": 2098
    }
  },
  "/en/warehouses/vbp-mlynarce-park-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vbp-mlynarce-park-hall-a-nitra",
      "id": 2098
    }
  },
  "/sk/sklady/vbp-mlynarce-park-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vbp-mlynarce-park-hall-b-nitra",
      "id": 2326
    }
  },
  "/en/warehouses/vbp-mlynarce-park-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vbp-mlynarce-park-hall-b-nitra",
      "id": 2326
    }
  },
  "/sk/sklady/vyrobna-hala-cabajska-nitra-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobna-hala-cabajska-nitra-nitra",
      "id": 1752
    }
  },
  "/en/warehouses/vyrobna-hala-cabajska-nitra-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobna-hala-cabajska-nitra-nitra",
      "id": 1752
    }
  },
  "/sk/sklady/odborarska-bratislava-iii-1-777-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "odborarska-bratislava-iii-1-777-m2-bratislava",
      "id": 1696
    }
  },
  "/en/warehouses/odborarska-bratislava-iii-1-777-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "odborarska-bratislava-iii-1-777-m2-bratislava",
      "id": 1696
    }
  },
  "/sk/sklady/stavitelska-bratislava-iii-784m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-784m2-bratislava",
      "id": 1929
    }
  },
  "/en/warehouses/stavitelska-bratislava-iii-784m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-784m2-bratislava",
      "id": 1929
    }
  },
  "/sk/sklady/stavitelska-bratislava-iii-581-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-581-m2-bratislava",
      "id": 2357
    }
  },
  "/en/warehouses/stavitelska-bratislava-iii-581-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stavitelska-bratislava-iii-581-m2-bratislava",
      "id": 2357
    }
  },
  "/sk/sklady/cab-priemyselny-pozemok-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cab-priemyselny-pozemok-na-predaj-cab",
      "id": 1953
    }
  },
  "/en/warehouses/cab-industrial-landplot-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cab-priemyselny-pozemok-na-predaj-cab",
      "id": 1953
    }
  },
  "/sk/sklady/rimavska-sobota-pozemok-na-priemysel-a-obchod-na-predaj-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rimavska-sobota-pozemok-na-priemysel-a-obchod-na-predaj-rimavska-sobota",
      "id": 2331
    }
  },
  "/en/warehouses/rimavska-sobota-industrial-and-retail-landplot-for-sale-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rimavska-sobota-pozemok-na-priemysel-a-obchod-na-predaj-rimavska-sobota",
      "id": 2331
    }
  },
  "/sk/sklady/nitra-pozemok-na-priemysel-a-obchod-na-predaj-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "velka-lomnica-pozemok-na-priemysel-na-predaj-velka-lomnica",
      "id": 1650
    }
  },
  "/en/warehouses/nitra-industrial-and-retail-landplot-for-sale-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "velka-lomnica-pozemok-na-priemysel-na-predaj-velka-lomnica",
      "id": 1650
    }
  },
  "/sk/sklady/besico-park-martin-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "besico-park-martin-martin",
      "id": 1656
    }
  },
  "/en/warehouses/besico-park-martin-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "besico-park-martin-martin",
      "id": 1656
    }
  },
  "/sk/sklady/titrans-nitra-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "titrans-nitra-nitra",
      "id": 2096
    }
  },
  "/en/warehouses/titrans-nitra-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "titrans-nitra-nitra",
      "id": 2096
    }
  },
  "/sk/sklady/roznavska-bratislava-iii-2-273-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-1-612-m2-bratislava",
      "id": 2450
    }
  },
  "/en/warehouses/roznavska-bratislava-iii-2-273-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-1-612-m2-bratislava",
      "id": 2450
    }
  },
  "/sk/sklady/invest4see-park-dubnica-rdc-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "invest4see-park-dubnica-r-d-center-dubnica-nad-vahom",
      "id": 1685
    }
  },
  "/en/warehouses/invest4see-park-dubnica-rdc-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "invest4see-park-dubnica-r-d-center-dubnica-nad-vahom",
      "id": 1685
    }
  },
  "/sk/sklady/cehip-vrable-hall-5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-5-vrable",
      "id": 1750
    }
  },
  "/en/warehouses/cehip-vrable-hall-5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cehip-vrable-hall-5-vrable",
      "id": 1750
    }
  },
  "/sk/sklady/campus-namestovo-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "campus-namestovo-namestovo",
      "id": 2033
    }
  },
  "/en/warehouses/campus-namestovo-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "campus-namestovo-namestovo",
      "id": 2033
    }
  },
  "/sk/sklady/aah-park-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aah-park-hall-a-topolcany",
      "id": 1754
    }
  },
  "/en/warehouses/aah-park-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aah-park-hall-a-topolcany",
      "id": 1754
    }
  },
  "/sk/sklady/aah-park-sk-1": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aah-park-hall-c-topolcany",
      "id": 2097
    }
  },
  "/en/warehouses/aah-park-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "aah-park-hall-c-topolcany",
      "id": 2097
    }
  },
  "/sk/sklady/p3-lozorno-dc-7b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-7b-lozorno",
      "id": 1976
    }
  },
  "/en/warehouses/p3-lozorno-dc-7b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-7b-lozorno",
      "id": 1976
    }
  },
  "/sk/sklady/p3-farna-dc-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-dca-ivanka-pri-dunaji",
      "id": 2406
    }
  },
  "/en/warehouses/p3-farna-dc-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-dca-ivanka-pri-dunaji",
      "id": 2406
    }
  },
  "/sk/sklady/p3-bratislava-airport-dcb-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-dcb-ivanka-pri-dunaji",
      "id": 1977
    }
  },
  "/en/warehouses/p3-bratislava-airport-dcb-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-dcb-ivanka-pri-dunaji",
      "id": 1977
    }
  },
  "/sk/sklady/p3-bratislava-airport-dcd-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-dcd-ivanka-pri-dunaji",
      "id": 1776
    }
  },
  "/en/warehouses/p3-bratislava-airport-dcd-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-dcd-ivanka-pri-dunaji",
      "id": 1776
    }
  },
  "/sk/sklady/p3-kosice-park-dc2b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc2b-nova-polhora",
      "id": 2447
    }
  },
  "/en/warehouses/p3-kosice-park-dc2b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc2b-nova-polhora",
      "id": 2447
    }
  },
  "/sk/sklady/ctpark-nitra-nit1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-nitra-nit1-nitra",
      "id": 2624
    }
  },
  "/en/warehouses/ctpark-nitra-nit1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-nitra-nit1-nitra",
      "id": 2624
    }
  },
  "/sk/sklady/p3-kosice-park-dc6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc6-nova-polhora",
      "id": 2188
    }
  },
  "/en/warehouses/p3-kosice-park-dc6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc6-nova-polhora",
      "id": 2188
    }
  },
  "/sk/sklady/presskam-bratislava-hala-02-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-02-bratislava",
      "id": 1677
    }
  },
  "/en/warehouses/presskam-bratislava-hall-02-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-02-bratislava",
      "id": 1677
    }
  },
  "/sk/sklady/presskam-bratislava-hala-03-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-03-bratislava",
      "id": 2421
    }
  },
  "/en/warehouses/presskam-bratislava-hall-03-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-03-bratislava",
      "id": 2421
    }
  },
  "/sk/sklady/presskam-bratislava-hala-04-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-04-bratislava",
      "id": 2352
    }
  },
  "/en/warehouses/presskam-bratislava-hall-04-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-04-bratislava",
      "id": 2352
    }
  },
  "/sk/sklady/presskam-bratislava-hala-05-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-05-bratislava",
      "id": 1751
    }
  },
  "/en/warehouses/presskam-bratislava-hall-05-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "presskam-bratislava-hala-05-bratislava",
      "id": 1751
    }
  },
  "/sk/sklady/vgp-park-bratislava-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hall-a-bernolakovo",
      "id": 1762
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hall-a-bernolakovo",
      "id": 1762
    }
  },
  "/sk/sklady/vgp-park-bratislava-hala-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-b-bernolakovo",
      "id": 1965
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-b-bernolakovo",
      "id": 1965
    }
  },
  "/sk/sklady/vgp-park-bratislava-hala-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-c-bernolakovo",
      "id": 2101
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-c-bernolakovo",
      "id": 2101
    }
  },
  "/sk/sklady/vgp-park-bratislava-hala-d-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-d-bernolakovo",
      "id": 1763
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-d-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-d-bernolakovo",
      "id": 1763
    }
  },
  "/sk/sklady/vgp-park-bratislava-hala-e-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-e-bernolakovo",
      "id": 1968
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-e-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-e-bernolakovo",
      "id": 1968
    }
  },
  "/sk/sklady/vgp-park-bratislava-hala-f-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-f-bernolakovo",
      "id": 1760
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-f-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-f-bernolakovo",
      "id": 1760
    }
  },
  "/sk/sklady/vgp-park-bratislava-hala-g-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-g-bernolakovo",
      "id": 2102
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-g-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-g-bernolakovo",
      "id": 2102
    }
  },
  "/sk/sklady/vgp-park-bratislava-hala-h-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-h-bernolakovo",
      "id": 1967
    }
  },
  "/en/warehouses/vgp-park-bratislava-hall-h-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-bratislava-hala-h-bernolakovo",
      "id": 1967
    }
  },
  "/sk/sklady/pnk-park-sered-dc35-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pnk-park-sered-dc35-sered",
      "id": 2104
    }
  },
  "/en/warehouses/pnk-park-sered-dc35-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pnk-park-sered-dc35-sered",
      "id": 2104
    }
  },
  "/sk/sklady/pnk-park-sered-dc33-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pnk-park-sered-dc33-sered",
      "id": 2588
    }
  },
  "/en/warehouses/pnk-park-sered-dc33-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pnk-park-sered-dc33-sered",
      "id": 2588
    }
  },
  "/sk/sklady/pnk-park-sered-dc34-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pnk-park-sered-dc34-sered",
      "id": 2103
    }
  },
  "/en/warehouses/pnk-park-sered-dc34-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pnk-park-sered-dc34-sered",
      "id": 2103
    }
  },
  "/sk/sklady/vgp-park-zvolen-hala-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-zvolen-hala-a-zvolen",
      "id": 1899
    }
  },
  "/en/warehouses/vgp-park-zvolen-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-zvolen-hala-a-zvolen",
      "id": 1899
    }
  },
  "/sk/sklady/vgp-park-zvolen-hala-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-zvolen-hala-b-zvolen",
      "id": 2055
    }
  },
  "/en/warehouses/vgp-park-zvolen-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-zvolen-hala-b-zvolen",
      "id": 2055
    }
  },
  "/sk/sklady/sihot-park-chocholna-dc2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sihot-park-chocholna-dc2-trencin",
      "id": 1686
    }
  },
  "/en/warehouses/sihot-park-chocholna-dc2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sihot-park-chocholna-dc2-trencin",
      "id": 1686
    }
  },
  "/sk/sklady/p3-kosice-park-dc2a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc2a-nova-polhora",
      "id": 1643
    }
  },
  "/en/warehouses/p3-kosice-park-dc2a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc2a-nova-polhora",
      "id": 1643
    }
  },
  "/sk/sklady/p3-kosice-park-dc3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc3-nova-polhora",
      "id": 2629
    }
  },
  "/en/warehouses/p3-kosice-park-dc3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc3-nova-polhora",
      "id": 2629
    }
  },
  "/sk/sklady/p3-kosice-park-dc4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc4-nova-polhora",
      "id": 1872
    }
  },
  "/en/warehouses/p3-kosice-park-dc4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc4-nova-polhora",
      "id": 1872
    }
  },
  "/sk/sklady/p3-kosice-park-dc5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc5-nova-polhora",
      "id": 1881
    }
  },
  "/en/warehouses/p3-kosice-park-dc5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc5-nova-polhora",
      "id": 1881
    }
  },
  "/sk/sklady/p3-kosice-park-dc7a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc7a-nova-polhora",
      "id": 2026
    }
  },
  "/en/warehouses/p3-kosice-park-dc7a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc7a-nova-polhora",
      "id": 2026
    }
  },
  "/sk/sklady/p3-kosice-park-dc7b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc7b-nova-polhora",
      "id": 2385
    }
  },
  "/en/warehouses/p3-kosice-park-dc7b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-kosice-park-dc7b-nova-polhora",
      "id": 2385
    }
  },
  "/sk/sklady/immopark-kosice-hala-ksc12-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc12-kosice",
      "id": 1636
    }
  },
  "/en/warehouses/immopark-kosice-hall-ksc12-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc12-kosice",
      "id": 1636
    }
  },
  "/sk/sklady/immopark-kosice-hala-ksc10-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc10-kosice",
      "id": 2572
    }
  },
  "/en/warehouses/immopark-kosice-hall-ksc10-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc10-kosice",
      "id": 2572
    }
  },
  "/sk/sklady/immopark-kosice-hala-ksc2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc2-kosice",
      "id": 2403
    }
  },
  "/en/warehouses/immopark-kosice-hall-ksc2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc2-kosice",
      "id": 2403
    }
  },
  "/sk/sklady/immopark-kosice-hala-ksc9-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc9-kosice",
      "id": 2359
    }
  },
  "/en/warehouses/immopark-kosice-hall-ksc9-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc9-kosice",
      "id": 2359
    }
  },
  "/sk/sklady/immopark-kosice-hala-ksc13-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc13-kosice",
      "id": 1873
    }
  },
  "/en/warehouses/immopark-kosice-hall-ksc13-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kosice-hala-ksc13-kosice",
      "id": 1873
    }
  },
  "/sk/sklady/immopark-zilina-hala-z2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z2-dolny-hricov",
      "id": 2468
    }
  },
  "/en/warehouses/immopark-zilina-hall-z2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z2-dolny-hricov",
      "id": 2468
    }
  },
  "/sk/sklady/immopark-zilina-hala-z3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z3-dolny-hricov",
      "id": 2024
    }
  },
  "/en/warehouses/immopark-zilina-hall-z3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z3-dolny-hricov",
      "id": 2024
    }
  },
  "/sk/sklady/immopark-zilina-hala-z4-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z4-dolny-hricov",
      "id": 2023
    }
  },
  "/en/warehouses/immopark-zilina-hall-z4-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z4-dolny-hricov",
      "id": 2023
    }
  },
  "/sk/sklady/immopark-zilina-hala-z5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z5-dolny-hricov",
      "id": 2551
    }
  },
  "/en/warehouses/immopark-zilina-hall-z5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z5-dolny-hricov",
      "id": 2551
    }
  },
  "/sk/sklady/immopark-zilina-hala-z6-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z6-dolny-hricov",
      "id": 1875
    }
  },
  "/en/warehouses/immopark-zilina-hall-z6-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z6-dolny-hricov",
      "id": 1875
    }
  },
  "/sk/sklady/immopark-zilina-hala-z7-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z7-dolny-hricov",
      "id": 2542
    }
  },
  "/en/warehouses/immopark-zilina-hall-z7-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z7-dolny-hricov",
      "id": 2542
    }
  },
  "/sk/sklady/immopark-zilina-hala-z8-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z8-dolny-hricov",
      "id": 2394
    }
  },
  "/en/warehouses/immopark-zilina-hall-z8-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z8-dolny-hricov",
      "id": 2394
    }
  },
  "/sk/sklady/immopark-zilina-hala-z9-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z9-dolny-hricov",
      "id": 2616
    }
  },
  "/en/warehouses/immopark-zilina-hall-z9-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z9-dolny-hricov",
      "id": 2616
    }
  },
  "/sk/sklady/immopark-zilina-hala-z10-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z10-dolny-hricov",
      "id": 1652
    }
  },
  "/en/warehouses/immopark-zilina-hall-z10-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z10-dolny-hricov",
      "id": 1652
    }
  },
  "/sk/sklady/immopark-zilina-hala-z11-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z10-dolny-hricov",
      "id": 2368
    }
  },
  "/en/warehouses/immopark-zilina-hall-z11-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z10-dolny-hricov",
      "id": 2368
    }
  },
  "/sk/sklady/immopark-zilina-hala-z12-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z12-dolny-hricov",
      "id": 1884
    }
  },
  "/en/warehouses/immopark-zilina-hall-z12-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-zilina-airport-hall-z12-dolny-hricov",
      "id": 1884
    }
  },
  "/sk/sklady/zlate-moravce-sklad-na-prenajom-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "zlate-moravce-tovarenska-sklad-na-prenajom-zlate-moravce",
      "id": 2631
    }
  },
  "/en/warehouses/zlate-moravce-warehouse-for-rent-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "zlate-moravce-tovarenska-sklad-na-prenajom-zlate-moravce",
      "id": 2631
    }
  },
  "/sk/sklady/panattoni-park-kosice-hall-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-kosice-hall-a-kosice",
      "id": 2564
    }
  },
  "/en/warehouses/panattoni-park-kosice-hall-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-kosice-hall-a-kosice",
      "id": 2564
    }
  },
  "/sk/sklady/panattoni-park-kosice-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-kosice-hall-b-kosice",
      "id": 2381
    }
  },
  "/en/warehouses/panattoni-park-kosice-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-kosice-hall-b-kosice",
      "id": 2381
    }
  },
  "/sk/sklady/panattoni-park-trnava-hall-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-trnava-hall-b-trnava",
      "id": 2106
    }
  },
  "/en/warehouses/panattoni-park-trnava-hall-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-trnava-hall-b-trnava",
      "id": 2106
    }
  },
  "/sk/sklady/log-center-r7-hall-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-1-kostolne-kracany",
      "id": 1727
    }
  },
  "/en/warehouses/log-center-r7-hall-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-1-kostolne-kracany",
      "id": 1727
    }
  },
  "/sk/sklady/log-center-r7-hall-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-2-kostolne-kracany",
      "id": 2083
    }
  },
  "/en/warehouses/log-center-r7-hall-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-2-kostolne-kracany",
      "id": 2083
    }
  },
  "/sk/sklady/log-center-r7-hall-3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-3-kostolne-kracany",
      "id": 1934
    }
  },
  "/en/warehouses/log-center-r7-hall-3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-3-kostolne-kracany",
      "id": 1934
    }
  },
  "/sk/sklady/topa-stav-s-r-o-sk-1": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "topa-stav-s-r-o-cadca",
      "id": 2032
    }
  },
  "/en/warehouses/topa-stav-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "topa-stav-s-r-o-cadca",
      "id": 2032
    }
  },
  "/sk/sklady/save-tech-s-r-o-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "save-tech-s-r-o-trencin",
      "id": 1904
    }
  },
  "/en/warehouses/save-tech-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "save-tech-s-r-o-trencin",
      "id": 1904
    }
  },
  "/sk/sklady/samsung-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "samsung-electronics-slovakia-galanta",
      "id": 1969
    }
  },
  "/en/warehouses/samsung-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "samsung-electronics-slovakia-galanta",
      "id": 1969
    }
  },
  "/sk/sklady/arete-park-nove-mesto-nad-vahom-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc7-nove-mesto-nad-vahom",
      "id": 2048
    }
  },
  "/en/warehouses/arete-park-nove-mesto-nad-vahom-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "cromwell-park-nove-mesto-nad-vahom-dc7-nove-mesto-nad-vahom",
      "id": 2048
    }
  },
  "/sk/sklady/gefco-unit-kosice-hala-ksc12-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "gefco-unit-kosice-hala-ksc12-kosice",
      "id": 1869
    }
  },
  "/en/warehouses/gefco-unit-kosice-hall-ksc12-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "gefco-unit-kosice-hala-ksc12-kosice",
      "id": 1869
    }
  },
  "/sk/sklady/pestovatelska-bratislava-ii-552-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-552-m2-bratislava",
      "id": 1712
    }
  },
  "/en/warehouses/pestovatelska-bratislava-ii-552-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-552-m2-bratislava",
      "id": 1712
    }
  },
  "/sk/sklady/ivanka-logistics-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-logistics-ivanka-pri-dunaji",
      "id": 1781
    }
  },
  "/en/warehouses/ivanka-logistics-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-logistics-ivanka-pri-dunaji",
      "id": 1781
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-504-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-504-m2-bratislava",
      "id": 2072
    }
  },
  "/en/warehouses/vajnorska-bratislava-iii-504-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-504-m2-bratislava",
      "id": 2072
    }
  },
  "/sk/sklady/slovnaftska-bratislava-ii-190-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "slovnaftska-bratislava-ii-190-m2-bratislava",
      "id": 1713
    }
  },
  "/en/warehouses/slovnaftska-bratislava-ii-190-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "slovnaftska-bratislava-ii-190-m2-bratislava",
      "id": 1713
    }
  },
  "/sk/sklady/racianska-bratislava-iii-1-160-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-1-160-m2-bratislava",
      "id": 2452
    }
  },
  "/en/warehouses/racianska-bratislava-iii-1-160-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-1-160-m2-bratislava",
      "id": 2452
    }
  },
  "/sk/sklady/racianska-bratislava-iii-1-970-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-1-970-m2-bratislava",
      "id": 1720
    }
  },
  "/en/warehouses/racianska-bratislava-iii-1-970-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-1-970-m2-bratislava",
      "id": 1720
    }
  },
  "/sk/sklady/racianska-bratislava-iii-140-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-140-m2-bratislava",
      "id": 1717
    }
  },
  "/en/warehouses/racianska-bratislava-iii-140-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-140-m2-bratislava",
      "id": 1717
    }
  },
  "/sk/sklady/p3-bratislava-airport-3-440-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-3-440-m2-ivanka-pri-dunaji",
      "id": 2114
    }
  },
  "/en/warehouses/p3-bratislava-airport-3440-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-bratislava-airport-3-440-m2-ivanka-pri-dunaji",
      "id": 2114
    }
  },
  "/sk/sklady/prologis-park-senec-dc2-2500-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc2-2500-sq-m-senec",
      "id": 2536
    }
  },
  "/en/warehouses/prologis-park-senec-dc2-2500-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prologis-park-senec-dc2-2500-sq-m-senec",
      "id": 2536
    }
  },
  "/sk/sklady/pestovatelska-bratislava-ii-2-124-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-bratislava",
      "id": 1982
    }
  },
  "/en/warehouses/pestovatelska-bratislava-ii-2-124-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-bratislava",
      "id": 1982
    }
  },
  "/sk/sklady/stara-vajnorska-nove-mesto-bratislava-iii-1-171-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-nove-mesto-bratislava-iii-1-171-m-bratislava",
      "id": 2603
    }
  },
  "/en/warehouses/stara-vajnorska-nove-mesto-bratislava-iii-1-171-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-nove-mesto-bratislava-iii-1-171-m-bratislava",
      "id": 2603
    }
  },
  "/sk/sklady/prijazdna-brtislava-iii-494-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prijazdna-bratislava-iii-494-m2-bratislava",
      "id": 2482
    }
  },
  "/en/warehouses/prijazdna-brtislava-iii-494-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prijazdna-bratislava-iii-494-m2-bratislava",
      "id": 2482
    }
  },
  "/sk/sklady/lieskovska-cesta-bratislava-ii-3-920-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "lieskovska-cesta-bratislava-ii-3-920-m2-bratislava",
      "id": 2427
    }
  },
  "/en/warehouses/lieskovska-cesta-bratislava-ii-3-920-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "lieskovska-cesta-bratislava-ii-3-920-m2-bratislava",
      "id": 2427
    }
  },
  "/sk/sklady/mlynske-nivy-bratislava-ii-496-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mlynske-nivy-bratislava-ii-496-m2-bratislava",
      "id": 2445
    }
  },
  "/en/warehouses/mlynske-nivy-bratislava-ii-496-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mlynske-nivy-bratislava-ii-496-m2-bratislava",
      "id": 2445
    }
  },
  "/sk/sklady/kosice-investicna-prilezitost-pozemku-o-velkosti-na-vystavbu-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kosice-investicna-prilezitost-pozemku-o-velkosti-20-ha-na-predaj-kosice",
      "id": 2410
    }
  },
  "/en/warehouses/kosice-investment-opportunity-of-20ha-of-land-for-development-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kosice-investicna-prilezitost-pozemku-o-velkosti-20-ha-na-predaj-kosice",
      "id": 2410
    }
  },
  "/sk/sklady/north-gate-martin-hala-t42-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-martin",
      "id": 1879
    }
  },
  "/en/warehouses/north-gate-martin-hala-t42-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-martin",
      "id": 1879
    }
  },
  "/sk/sklady/na-pantoch-bratislava-iii-1-500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-1-500-m2-bratislava",
      "id": 1921
    }
  },
  "/en/warehouses/na-pantoch-bratislava-iii-1-500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-1-500-m2-bratislava",
      "id": 1921
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-938-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-938-m2-bratislava",
      "id": 2075
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-938-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-938-m2-bratislava",
      "id": 2075
    }
  },
  "/sk/sklady/bojnicka-bratislava-iii-1-143-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-1-143-m2-bratislava",
      "id": 1715
    }
  },
  "/en/warehouses/bojnicka-bratislava-iii-1-143-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-bratislava-iii-1-143-m2-bratislava",
      "id": 1715
    }
  },
  "/sk/sklady/stupava-1500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stupava-1500-m2-stupava",
      "id": 2441
    }
  },
  "/en/warehouses/stupava-1500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stupava-1500-m2-stupava",
      "id": 2441
    }
  },
  "/sk/sklady/prievozska-bratislava-ii-1300-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prievozska-bratislava-ii-1300-m2-bratislava",
      "id": 1922
    }
  },
  "/en/warehouses/prievozska-bratislava-ii-1300-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "prievozska-bratislava-ii-1300-m2-bratislava",
      "id": 1922
    }
  },
  "/sk/sklady/glp-park-dunajska-streda-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-park-dunajska-streda-dunajska-streda",
      "id": 1971
    }
  },
  "/en/warehouses/glp-park-dunajska-streda-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-park-dunajska-streda-dunajska-streda",
      "id": 1971
    }
  },
  "/sk/sklady/ctpark-presov-south-prp1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-south-prp1-presov",
      "id": 1654
    }
  },
  "/en/warehouses/ctpark-presov-south-prp1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-south-prp1-presov",
      "id": 1654
    }
  },
  "/sk/sklady/ctpark-presov-south-prp2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-south-prp2-presov",
      "id": 1862
    }
  },
  "/en/warehouses/ctpark-presov-south-prp2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-presov-south-prp2-presov",
      "id": 1862
    }
  },
  "/sk/sklady/na-pantoch-bratislava-iii-1490m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-1490m2-bratislava",
      "id": 2567
    }
  },
  "/en/warehouses/na-pantoch-bratislava-iii-1490m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "na-pantoch-bratislava-iii-1490m2-bratislava",
      "id": 2567
    }
  },
  "/sk/sklady/roznavska-bratislava-iii-438-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-438-m2-bratislava",
      "id": 2575
    }
  },
  "/en/warehouses/roznavska-bratislava-iii-438-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-bratislava-iii-438-m2-bratislava",
      "id": 2575
    }
  },
  "/sk/sklady/pestovatelska-bratislava-ii-1071m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-1071m2-bratislava",
      "id": 2584
    }
  },
  "/en/warehouses/pestovatelska-bratislava-ii-1071m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pestovatelska-bratislava-ii-1071m2-bratislava",
      "id": 2584
    }
  },
  "/sk/sklady/turbinova-bratislava-iii-390m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-390m2-bratislava",
      "id": 2323
    }
  },
  "/en/warehouses/turbinova-bratislava-iii-390m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "turbinova-bratislava-iii-390m2-bratislava",
      "id": 2323
    }
  },
  "/sk/sklady/rainbow-center-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rainbow-center-banska-bystrica",
      "id": 2317
    }
  },
  "/en/warehouses/rainbow-center-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "rainbow-center-banska-bystrica",
      "id": 2317
    }
  },
  "/sk/sklady/devinska-nova-ves-bratislava-iv-455m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "devinska-nova-ves-bratislava-iv-455m2-bratislava",
      "id": 2455
    }
  },
  "/en/warehouses/devinska-nova-ves-bratislava-iv-455m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "devinska-nova-ves-bratislava-iv-455m2-bratislava",
      "id": 2455
    }
  },
  "/sk/sklady/devinska-nova-ves-bratislava-iv-910m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "devinska-nova-ves-bratislava-iv-910m2-bratislava",
      "id": 2074
    }
  },
  "/en/warehouses/devinska-nova-ves-bratislava-iv-910m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "devinska-nova-ves-bratislava-iv-910m2-bratislava",
      "id": 2074
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-375m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-375m2-bratislava",
      "id": 1941
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-375m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-375m2-bratislava",
      "id": 1941
    }
  },
  "/sk/sklady/stara-vajnorska-bratislava-iii-640m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-640m2-bratislava",
      "id": 1737
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-640m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-640m2-bratislava",
      "id": 1737
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-1200m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-1200m2-bratislava",
      "id": 1733
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-1200m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-1200m2-bratislava",
      "id": 1733
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-880m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-880m2-bratislava",
      "id": 2086
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-880m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-880m2-bratislava",
      "id": 2086
    }
  },
  "/sk/sklady/kopcianska-bratislava-v-230m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-230m2-bratislava",
      "id": 2354
    }
  },
  "/en/warehouses/kopcianska-bratislava-v-230m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "kopcianska-bratislava-v-230m2-bratislava",
      "id": 2354
    }
  },
  "/sk/sklady/panattoni-park-plavecky-stvrtok-hall-eh303-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-plavecky-stvrtok-hall-eh303-plavecky-stvrtok",
      "id": 1736
    }
  },
  "/en/warehouses/panattoni-park-plavecky-stvrtok-hall-eh303-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-plavecky-stvrtok-hall-eh303-plavecky-stvrtok",
      "id": 1736
    }
  },
  "/sk/sklady/panattoni-park-plavecky-stvrtok-hall-eh304-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-plavecky-stvrtok-hall-eh304-plavecky-stvrtok",
      "id": 1940
    }
  },
  "/en/warehouses/panattoni-park-plavecky-stvrtok-hall-eh304-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-plavecky-stvrtok-hall-eh304-plavecky-stvrtok",
      "id": 1940
    }
  },
  "/sk/sklady/panattoni-park-plavecky-stvrtok-hall-eh301-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-plavecky-stvrtok-hall-eh301-plavecky-stvrtok",
      "id": 2534
    }
  },
  "/en/warehouses/panattoni-park-plavecky-stvrtok-hall-eh301-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "panattoni-park-plavecky-stvrtok-hall-eh301-plavecky-stvrtok",
      "id": 2534
    }
  },
  "/sk/sklady/mountpark-triblavina-building-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-bratislava-building-a-bernolakovo",
      "id": 2370
    }
  },
  "/en/warehouses/mountpark-triblavina-building-a-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-bratislava-building-a-bernolakovo",
      "id": 2370
    }
  },
  "/sk/sklady/mountpark-triblavina-building-b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-bratislava-building-b-bernolakovo",
      "id": 2401
    }
  },
  "/en/warehouses/mountpark-triblavina-building-b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "mountpark-bratislava-building-b-bernolakovo",
      "id": 2401
    }
  },
  "/sk/sklady/racianska-bratislava-iii-1750m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-1750m2-bratislava",
      "id": 2334
    }
  },
  "/en/warehouses/racianska-bratislava-iii-1750m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-1750m2-bratislava",
      "id": 2334
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-1712m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1712m2-bratislava",
      "id": 1718
    }
  },
  "/en/warehouses/stara-vajnorska-bratislava-iii-1712m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-bratislava-iii-1712m2-bratislava",
      "id": 1718
    }
  },
  "/sk/sklady/krajna-bratislava-iii-154m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "krajna-bratislava-iii-154m2-bratislava",
      "id": 2318
    }
  },
  "/en/warehouses/krajna-bratislava-iii-154m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "krajna-bratislava-iii-154m2-bratislava",
      "id": 2318
    }
  },
  "/sk/sklady/chemicka-bratislava-iii-140m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "chemicka-bratislava-iii-140m2-bratislava",
      "id": 2408
    }
  },
  "/en/warehouses/chemicka-bratislava-iii-140m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "chemicka-bratislava-iii-140m2-bratislava",
      "id": 2408
    }
  },
  "/sk/sklady/slovnaftska-bratislava-ii-450-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "slovnaftska-bratislava-ii-450-m2-bratislava",
      "id": 2595
    }
  },
  "/en/warehouses/slovnaftska-bratislava-ii-450-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "slovnaftska-bratislava-ii-450-m2-bratislava",
      "id": 2595
    }
  },
  "/sk/sklady/galvaniho-bratislava-ii-450m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "galvaniho-bratislava-ii-450m2-bratislava",
      "id": 1939
    }
  },
  "/en/warehouses/galvaniho-bratislava-ii-450m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "galvaniho-bratislava-ii-450m2-bratislava",
      "id": 1939
    }
  },
  "/sk/sklady/ivanka-pri-dunaji-370m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-370m2-ivanka-pri-dunaji",
      "id": 1735
    }
  },
  "/en/warehouses/ivanka-pri-dunaji-370m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ivanka-pri-dunaji-370m2-ivanka-pri-dunaji",
      "id": 1735
    }
  },
  "/sk/sklady/garbe-green-park-piestany-d1-hall-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "garbe-green-park-piestany-d1-hall-1-piestany",
      "id": 2561
    }
  },
  "/en/warehouses/garbe-green-park-piestany-d1-hall-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "garbe-green-park-piestany-d1-hall-1-piestany",
      "id": 2561
    }
  },
  "/sk/sklady/garbe-green-park-piestany-d1-hall-2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "garbe-green-park-piestany-d1-hall-2-piestany",
      "id": 2597
    }
  },
  "/en/warehouses/garbe-green-park-piestany-d1-hall-2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "garbe-green-park-piestany-d1-hall-2-piestany",
      "id": 2597
    }
  },
  "/sk/sklady/zohor-697-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "zohor-697-m2-zohor",
      "id": 1734
    }
  },
  "/en/warehouses/zohor-697-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "zohor-697-m2-zohor",
      "id": 1734
    }
  },
  "/sk/sklady/zohor-1185-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "zohor-1185-m2-zohor",
      "id": 2087
    }
  },
  "/en/warehouses/zohor-1185-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "zohor-1185-m2-zohor",
      "id": 2087
    }
  },
  "/sk/sklady/pri-sajbach-1150-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pri-sajbach-1150-m2-bratislava",
      "id": 1925
    }
  },
  "/en/warehouses/pri-sajbach-1150-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pri-sajbach-1150-m2-bratislava",
      "id": 1925
    }
  },
  "/sk/sklady/p3-lozorno-dc-6-8-500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6-8-500-m2-lozorno",
      "id": 2356
    }
  },
  "/en/warehouses/p3-lozorno-dc-6b-8500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6-8-500-m2-lozorno",
      "id": 2356
    }
  },
  "/sk/sklady/p3-lozorno-dc-6b-3500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6b-3500-m2-lozorno",
      "id": 2376
    }
  },
  "/en/warehouses/p3-lozorno-dc-6b-3500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6b-3500-m2-lozorno",
      "id": 2376
    }
  },
  "/sk/sklady/p3-lozorno-dc-6b-5000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6b-5000-m2-lozorno",
      "id": 2485
    }
  },
  "/en/warehouses/p3-lozorno-dc-6b-5000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-6b-5000-m2-lozorno",
      "id": 2485
    }
  },
  "/sk/sklady/p3-lozorno-dc-7a-5-320-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-7a-5320-m2-lozorno",
      "id": 2535
    }
  },
  "/en/warehouses/p3-lozorno-dc-7a-5-320-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "p3-lozorno-dc-7a-5320-m2-lozorno",
      "id": 2535
    }
  },
  "/sk/sklady/vlcie-hrdlo-bratislava-ii-4126-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-bratislava-ii-4126-m2-bratislava",
      "id": 1938
    }
  },
  "/en/warehouses/vlcie-hrdlo-bratislava-ii-4126-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-bratislava-ii-4126-m2-bratislava",
      "id": 1938
    }
  },
  "/sk/sklady/odborarska-bratislava-ii-468m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "odborarska-bratislava-ii-468m2-bratislava",
      "id": 1937
    }
  },
  "/en/warehouses/odborarska-bratislava-ii-468m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "odborarska-bratislava-ii-468m2-bratislava",
      "id": 1937
    }
  },
  "/sk/sklady/nehnutelnost-na-predaj-prenajom-kostany-nad-turcom-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nehnutelnost-na-predaj-prenajom-kostany-nad-turcom-martin",
      "id": 1865
    }
  },
  "/en/warehouses/nehnutelnost-na-predaj-prenajom-kostany-nad-turcom-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nehnutelnost-na-predaj-prenajom-kostany-nad-turcom-martin",
      "id": 1865
    }
  },
  "/sk/sklady/dubnica-nad-vahom-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "dubnica-nad-vahom-dubnica-nad-vahom",
      "id": 1689
    }
  },
  "/en/warehouses/dubnica-nad-vahom-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "dubnica-nad-vahom-dubnica-nad-vahom",
      "id": 1689
    }
  },
  "/sk/sklady/udernicka-petrzalka-bratislava-2-800-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-petrzalka-bratislava-2-029-m2-bratislava",
      "id": 2084
    }
  },
  "/en/warehouses/udernicka-petrzalka-bratislava-2-800-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-petrzalka-bratislava-2-029-m2-bratislava",
      "id": 2084
    }
  },
  "/sk/sklady/nehnutelnost-na-predaj-martin-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nehnutelnost-na-predaj-martin-martin",
      "id": 2343
    }
  },
  "/en/warehouses/nehnutelnost-na-predaj-martin-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "nehnutelnost-na-predaj-martin-martin",
      "id": 2343
    }
  },
  "/sk/sklady/vgp-park-zvolen-hala-c-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-zvolen-hala-c-zvolen",
      "id": 2054
    }
  },
  "/en/warehouses/vgp-park-zvolen-hall-c-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-park-zvolen-hala-c-zvolen",
      "id": 2054
    }
  },
  "/sk/sklady/north-gate-t42-4-244-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-4-244-m2-martin",
      "id": 2387
    }
  },
  "/en/warehouses/north-gate-t42-4-244-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-4-244-m2-martin",
      "id": 2387
    }
  },
  "/sk/sklady/north-gate-t42-4-225-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-hala-t42-4-225-m2-martin",
      "id": 1882
    }
  },
  "/en/warehouses/north-gate-t42-4-225-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-hala-t42-4-225-m2-martin",
      "id": 1882
    }
  },
  "/sk/sklady/north-gate-t42-6-337-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-6-337-m2-martin",
      "id": 2436
    }
  },
  "/en/warehouses/north-gate-t42-6-337-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-6-337-m2-martin",
      "id": 2436
    }
  },
  "/sk/sklady/north-gate-t42-4-912-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-4-912-m2-martin",
      "id": 2349
    }
  },
  "/en/warehouses/north-gate-t42-4-912-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-4-912-m2-martin",
      "id": 2349
    }
  },
  "/sk/sklady/north-gate-t42-11-313-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-11-313-m2-martin",
      "id": 2593
    }
  },
  "/en/warehouses/north-gate-t42-11-313-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-11-313-m2-martin",
      "id": 2593
    }
  },
  "/sk/sklady/north-gate-martin-hala-t42-8-509-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-8-509-m2-martin",
      "id": 1868
    }
  },
  "/en/warehouses/north-gate-martin-hala-t42-8-509-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-8-509-m2-martin",
      "id": 1868
    }
  },
  "/sk/sklady/north-gate-martin-hala-t42-2-147-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-2-147-m2-martin",
      "id": 1866
    }
  },
  "/en/warehouses/north-gate-martin-hala-t42-2-147-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "north-gate-martin-hala-t42-2-147-m2-martin",
      "id": 1866
    }
  },
  "/sk/sklady/priemyselno-logisticky-park-nitra-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a1-2-land-luzianky",
      "id": 2516
    }
  },
  "/en/warehouses/priemyselno-logisticky-park-nitra-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a1-2-land-luzianky",
      "id": 2516
    }
  },
  "/sk/sklady/skladove-priestory-michalovce-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselny-sklad-michalovce-michalovce",
      "id": 1646
    }
  },
  "/en/warehouses/skladove-priestory-michalovce-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselny-sklad-michalovce-michalovce",
      "id": 1646
    }
  },
  "/sk/sklady/west-park-nitra-a1-1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a1-2-land-luzianky",
      "id": 2555
    }
  },
  "/en/warehouses/west-park-nitra-a1-1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a1-2-land-luzianky",
      "id": 2555
    }
  },
  "/sk/sklady/west-park-nitra-a1-3-a1-5-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a1-3-a1-5-land-luzianky",
      "id": 2347
    }
  },
  "/en/warehouses/west-park-nitra-a1-3-a1-5-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a1-3-a1-5-land-luzianky",
      "id": 2347
    }
  },
  "/sk/sklady/west-park-nitra-a3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a3-land-luzianky",
      "id": 2043
    }
  },
  "/en/warehouses/west-park-nitra-a3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-a3-land-luzianky",
      "id": 2043
    }
  },
  "/sk/sklady/west-park-nitra-b1-1b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-b1-1b-land-luzianky",
      "id": 2623
    }
  },
  "/en/warehouses/west-park-nitra-b1-1b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-b1-1b-land-luzianky",
      "id": 2623
    }
  },
  "/sk/sklady/west-park-nitra-b2-2b-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-b2-2b-land-luzianky",
      "id": 2444
    }
  },
  "/en/warehouses/west-park-nitra-b2-2b-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-b2-2b-land-luzianky",
      "id": 2444
    }
  },
  "/sk/sklady/west-park-nitra-d1-d3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-d1-d3-land-luzianky",
      "id": 1667
    }
  },
  "/en/warehouses/west-park-nitra-d1-d3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-d1-d3-land-luzianky",
      "id": 1667
    }
  },
  "/sk/sklady/west-park-nitra-f1-f8-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-f1-f8-land-luzianky",
      "id": 2545
    }
  },
  "/en/warehouses/west-park-nitra-f1-f8-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "west-park-nitra-f1-f8-land-luzianky",
      "id": 2545
    }
  },
  "/sk/sklady/priemyselna-areal-moldava-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselna-areal-moldava-mokrance",
      "id": 2418
    }
  },
  "/en/warehouses/priemyselny-areal-moldava-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselna-areal-moldava-mokrance",
      "id": 2418
    }
  },
  "/sk/sklady/skladove-priestory-presov-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-presov-presov",
      "id": 2029
    }
  },
  "/en/warehouses/skladove-priestory-presov-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-presov-presov",
      "id": 2029
    }
  },
  "/sk/sklady/skladove-a-vyrobne-priestory-zaborske-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-a-vyrobne-priestory-zaborske-zaborske",
      "id": 2351
    }
  },
  "/en/warehouses/skladove-a-vyrobne-priestory-zaborske-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-a-vyrobne-priestory-zaborske-zaborske",
      "id": 2351
    }
  },
  "/sk/sklady/skladovo-vyrobny-priestor-petrovany-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobny-priestor-petrovany-petrovany",
      "id": 1863
    }
  },
  "/en/warehouses/skladovo-vyrobny-priestor-petrovany-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobny-priestor-petrovany-petrovany",
      "id": 1863
    }
  },
  "/sk/sklady/vyrobno-skladova-hala-zaborske-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobno-skladova-hala-zaborske-zaborske",
      "id": 1635
    }
  },
  "/en/warehouses/vyrobno-skladova-hala-zaborske-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobno-skladova-hala-zaborske-zaborske",
      "id": 1635
    }
  },
  "/sk/sklady/trnava-14-000-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "trnava-14-000-m2-trnava",
      "id": 1731
    }
  },
  "/en/warehouses/trnava-14-000-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "trnava-14-000-m2-trnava",
      "id": 1731
    }
  },
  "/sk/sklady/obchodno-skladove-priestory-p1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p1-1-017-98-m2-bratislava",
      "id": 2077
    }
  },
  "/en/warehouses/obchodno-skladove-priestory-p1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p1-1-017-98-m2-bratislava",
      "id": 2077
    }
  },
  "/sk/sklady/obchodno-skladove-priestory-p2-1-346-74-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p2-1-346-74-m2-bratislava",
      "id": 1707
    }
  },
  "/en/warehouses/obchodno-skladove-priestory-p2-1-346-74-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p2-1-346-74-m2-bratislava",
      "id": 1707
    }
  },
  "/sk/sklady/obchodno-skladove-priestory-p3-1-1991-66-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p3-1-1991-66-m2-bratislava",
      "id": 2438
    }
  },
  "/en/warehouses/obchodno-skladove-priestory-p3-1-1991-66-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p3-1-1991-66-m2-bratislava",
      "id": 2438
    }
  },
  "/sk/sklady/obchodno-skladove-priestory-p4-2-013-33-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p4-2-013-33-m2-bratislava",
      "id": 2380
    }
  },
  "/en/warehouses/obchodno-skladove-priestory-p4-2-013-33-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p4-2-013-33-m2-bratislava",
      "id": 2380
    }
  },
  "/sk/sklady/obchodno-skladove-priestory-p5-1-576-272-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p5-1-576-272-m2-bratislava",
      "id": 1719
    }
  },
  "/en/warehouses/obchodno-skladove-priestory-p5-1-576-272-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p5-1-576-272-m2-bratislava",
      "id": 1719
    }
  },
  "/sk/sklady/obchodno-skladove-priestory-p6-1-316-78-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p6-1-316-78-m2-bratislava",
      "id": 2470
    }
  },
  "/en/warehouses/obchodno-skladove-priestory-p6-1-316-78-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p6-1-316-78-m2-bratislava",
      "id": 2470
    }
  },
  "/sk/sklady/obchodno-skladove-priestory-p8-784-518-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p8-784-518-m2-bratislava",
      "id": 2405
    }
  },
  "/en/warehouses/obchodno-skladove-priestory-p8-784-518-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "obchodno-skladove-priestory-p8-784-518-m2-bratislava",
      "id": 2405
    }
  },
  "/sk/sklady/maloobchodno-skladove-priestory-bratislava-400m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-400m2-bratislava",
      "id": 2350
    }
  },
  "/en/warehouses/maloobchodno-skladove-priestory-bratislava-400m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-400m2-bratislava",
      "id": 2350
    }
  },
  "/sk/sklady/maloobchodno-skladove-priestory-bratislava-800m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-800m2-bratislava",
      "id": 1918
    }
  },
  "/en/warehouses/maloobchodno-skladove-priestory-bratislava-800m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-800m2-bratislava",
      "id": 1918
    }
  },
  "/sk/sklady/maloobchodno-skladove-priestory-bratislava-1-200m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-1-200m2-bratislava",
      "id": 2592
    }
  },
  "/en/warehouses/maloobchodno-skladove-priestory-bratislava-1-200m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-1-200m2-bratislava",
      "id": 2592
    }
  },
  "/sk/sklady/maloobchodno-skladove-priestory-bratislava-2-000m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-2-000m2-bratislava",
      "id": 1729
    }
  },
  "/en/warehouses/maloobchodno-skladove-priestory-bratislava-2-000m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "maloobchodno-skladove-priestory-bratislava-2-000m2-bratislava",
      "id": 1729
    }
  },
  "/sk/sklady/skladove-priestory-trencin-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-trencin-trencin",
      "id": 2342
    }
  },
  "/en/warehouses/skladove-priestory-trencin-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-trencin-trencin",
      "id": 2342
    }
  },
  "/sk/sklady/galvaniho-bratislava-ii-980-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "galvaniho-bratislava-ii-980-m2-bratislava",
      "id": 2577
    }
  },
  "/en/warehouses/galvaniho-bratislava-ii-980-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "galvaniho-bratislava-ii-980-m2-bratislava",
      "id": 2577
    }
  },
  "/sk/sklady/racianska-bratislava-iii-950-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-950-m2-bratislava",
      "id": 2547
    }
  },
  "/en/warehouses/racianska-bratislava-iii-950-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "racianska-bratislava-iii-950-m2-bratislava",
      "id": 2547
    }
  },
  "/sk/sklady/ctpark-banska-bystrica-bb1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-banska-bystrica-bb1-banska-bystrica",
      "id": 2337
    }
  },
  "/en/warehouses/ctpark-banska-bystrica-bb1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-banska-bystrica-bb1-banska-bystrica",
      "id": 2337
    }
  },
  "/sk/sklady/besico-park-banska-bystrica-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "besico-park-banska-bystrica-banska-bystrica",
      "id": 1681
    }
  },
  "/en/warehouses/besico-park-banska-bystrica-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "besico-park-banska-bystrica-banska-bystrica",
      "id": 1681
    }
  },
  "/sk/sklady/vrakuna-14-509-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vrakuna-14-509-m2-bratislava",
      "id": 2338
    }
  },
  "/en/warehouses/vrakuna-14-509-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vrakuna-14-509-m2-bratislava",
      "id": 2338
    }
  },
  "/sk/sklady/business-box-trnava-5-120-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "business-box-trnava-5-120-m2-bratislava",
      "id": 2463
    }
  },
  "/en/warehouses/business-box-trnava-5-120-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "business-box-trnava-5-120-m2-bratislava",
      "id": 2463
    }
  },
  "/sk/sklady/vgp-malacky-hala-b-2-121-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-malacky-hala-b-2-121-m2-malacky",
      "id": 1730
    }
  },
  "/en/warehouses/vgp-malacky-hala-b-2-121-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-malacky-hala-b-2-121-m2-malacky",
      "id": 1730
    }
  },
  "/sk/sklady/vgp-malacky-hala-c-10-245-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-malacky-hala-c-10-245-m2-malacky",
      "id": 2538
    }
  },
  "/en/warehouses/vgp-malacky-hala-c-10-245-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vgp-malacky-hala-c-10-245-m2-malacky",
      "id": 2538
    }
  },
  "/sk/sklady/skladovo-vyrobna-hala-a-drienov-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-a-drienov-drienov",
      "id": 1648
    }
  },
  "/en/warehouses/skladovo-vyrobna-hala-a-drienov-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-a-drienov-drienov",
      "id": 1648
    }
  },
  "/sk/sklady/skladovo-vyrobna-hala-b-drienov-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-b-drienov-drienov",
      "id": 2440
    }
  },
  "/en/warehouses/skladovo-vyrobna-hala-b-drienov-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-b-drienov-drienov",
      "id": 2440
    }
  },
  "/sk/sklady/skladovo-vyrobna-hala-a-licartovce-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-a-licartovce-licartovce",
      "id": 2339
    }
  },
  "/en/warehouses/skladovo-vyrobna-hala-a-licartovce-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-a-licartovce-licartovce",
      "id": 2339
    }
  },
  "/sk/sklady/skladovo-vyrobna-hala-b-licartovce-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-b-licartovce-licartovce",
      "id": 2590
    }
  },
  "/en/warehouses/skladovo-vyrobna-hala-b-licartovce-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladovo-vyrobna-hala-b-licartovce-licartovce",
      "id": 2590
    }
  },
  "/sk/sklady/roznavska-hala-na-skladovanie-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-hala-na-skladovanie-bratislava",
      "id": 2322
    }
  },
  "/en/warehouses/roznavska-hala-na-skladovanie-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "roznavska-hala-na-skladovanie-bratislava",
      "id": 2322
    }
  },
  "/sk/sklady/exeter-park-bratislava-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "exeter-park-bratislava-senec",
      "id": 2416
    }
  },
  "/en/warehouses/exeter-park-bratislava-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "exeter-park-bratislava-senec",
      "id": 2416
    }
  },
  "/sk/sklady/hala-pri-sajbach-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "hala-pri-sajbach-bratislava",
      "id": 1935
    }
  },
  "/en/warehouses/hala-pri-sajbach-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "hala-pri-sajbach-bratislava",
      "id": 1935
    }
  },
  "/sk/sklady/udernicka-petrzalka-bratislava-1-810-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-petrzalka-bratislava-1-810-m2-bratislava",
      "id": 1926
    }
  },
  "/en/warehouses/udernicka-petrzalka-bratislava-1-810-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-petrzalka-bratislava-1-810-m2-bratislava",
      "id": 1926
    }
  },
  "/sk/sklady/udernicka-petrzalka-bratislava-3-900-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-petrzalka-bratislava-3-900-m2-bratislava",
      "id": 2456
    }
  },
  "/en/warehouses/udernicka-petrzalka-bratislava-3-900-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "udernicka-petrzalka-bratislava-3-900-m2-bratislava",
      "id": 2456
    }
  },
  "/sk/sklady/skladove-priestory-pribylinska-od-500-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-pribylinska-od-500-m2-bratislava",
      "id": 2554
    }
  },
  "/en/warehouses/skladove-priestory-pribylinska-od-500-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladove-priestory-pribylinska-od-500-m2-bratislava",
      "id": 2554
    }
  },
  "/sk/sklady/firemny-areal-snina-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "firemny-areal-snina-snina",
      "id": 2520
    }
  },
  "/en/warehouses/firemny-areal-snina-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "firemny-areal-snina-snina",
      "id": 2520
    }
  },
  "/sk/sklady/vyrobno-logisticka-hala-vrakuna-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobno-logisticka-hala-vrakuna-bratislava",
      "id": 2327
    }
  },
  "/en/warehouses/vyrobno-logisticka-hala-vrakuna-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobno-logisticka-hala-vrakuna-bratislava",
      "id": 2327
    }
  },
  "/sk/sklady/luka-logistics-park-piestany-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "luka-logistics-park-piestany-luka",
      "id": 2321
    }
  },
  "/en/warehouses/luka-logistics-park-piestany-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "luka-logistics-park-piestany-luka",
      "id": 2321
    }
  },
  "/sk/sklady/squarebizz-nitra-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "squarebizz-nitra-luzianky",
      "id": 1728
    }
  },
  "/en/warehouses/squarebizz-nitra-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "squarebizz-nitra-luzianky",
      "id": 1728
    }
  },
  "/sk/sklady/priemyselny-pozemok-nitra-juh-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselny-pozemok-nitra-juh-nitra",
      "id": 2464
    }
  },
  "/en/warehouses/priemyselny-pozemok-nitra-juh-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "priemyselny-pozemok-nitra-juh-nitra",
      "id": 2464
    }
  },
  "/sk/sklady/skladova-hala-kosice-1440-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladova-hala-kosice-1440-m2-kosice",
      "id": 1638
    }
  },
  "/en/warehouses/skladova-hala-kosice-1440-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "skladova-hala-kosice-1440-m2-kosice",
      "id": 1638
    }
  },
  "/sk/sklady/contera-park-bratislava-hala-a-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-cold-units-bratislava",
      "id": 1911
    }
  },
  "/en/warehouses/contera-park-bratislava-cold-units-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-cold-units-bratislava",
      "id": 1911
    }
  },
  "/sk/sklady/contera-park-bratislava-small-units-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-small-units-bratislava",
      "id": 2514
    }
  },
  "/en/warehouses/contera-park-bratislava-small-units-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-small-units-bratislava",
      "id": 2514
    }
  },
  "/sk/sklady/vlcie-hrdlo-370-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-370-sq-m-bratislava",
      "id": 1909
    }
  },
  "/en/warehouses/vlcie-hrdlo-370-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vlcie-hrdlo-370-sq-m-bratislava",
      "id": 1909
    }
  },
  "/sk/sklady/contera-park-bratislava-e3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-e3-bratislava",
      "id": 1695
    }
  },
  "/en/warehouses/contera-park-bratislava-e3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-e3-bratislava",
      "id": 1695
    }
  },
  "/sk/sklady/contera-park-bratislava-e2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-e2-bratislava",
      "id": 1910
    }
  },
  "/en/warehouses/contera-park-bratislava-e2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-e2-bratislava",
      "id": 1910
    }
  },
  "/sk/sklady/contera-park-bratislava-e1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-e1-bratislava",
      "id": 2510
    }
  },
  "/en/warehouses/contera-park-bratislava-e1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-e1-bratislava",
      "id": 2510
    }
  },
  "/sk/sklady/contera-park-bratislava-h1-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-h1-bratislava",
      "id": 2396
    }
  },
  "/en/warehouses/contera-park-bratislava-h1-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-h1-bratislava",
      "id": 2396
    }
  },
  "/sk/sklady/contera-park-bratislava-h2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-h2-bratislava",
      "id": 2371
    }
  },
  "/en/warehouses/contera-park-bratislava-h2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-h2-bratislava",
      "id": 2371
    }
  },
  "/sk/sklady/contera-park-bratislava-h3-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-h3-bratislava",
      "id": 2628
    }
  },
  "/en/warehouses/contera-park-bratislava-h3-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-h3-bratislava",
      "id": 2628
    }
  },
  "/sk/sklady/pri-sajbach-3233-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pri-sajbach-3233-m2-bratislava",
      "id": 1897
    }
  },
  "/en/warehouses/pri-sajbach-3233-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "pri-sajbach-3233-m2-bratislava",
      "id": 1897
    }
  },
  "/sk/sklady/log-center-r7-hall-1-11-041-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-1-11-041-sq-m-kostolne-kracany",
      "id": 2553
    }
  },
  "/en/warehouses/log-center-r7-hall-1-11-041-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "log-center-r7-hall-1-11-041-sq-m-kostolne-kracany",
      "id": 2553
    }
  },
  "/sk/sklady/povazska-bystrica-63-424-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "povazska-bystrica-63-424-sq-m-povazska-bystrica",
      "id": 1664
    }
  },
  "/en/warehouses/povazska-bystrica-63-424-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "povazska-bystrica-63-424-sq-m-povazska-bystrica",
      "id": 1664
    }
  },
  "/sk/sklady/bystricany-40-000-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bystricany-40-000-sq-m-prievidza",
      "id": 2383
    }
  },
  "/en/warehouses/bystricany-40-000-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bystricany-40-000-sq-m-prievidza",
      "id": 2383
    }
  },
  "/sk/sklady/vyhne-hall-4-381-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyhne-hall-4-381-sq-m-vyhne",
      "id": 2498
    }
  },
  "/en/warehouses/vyhne-hall-4-381-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyhne-hall-4-381-sq-m-vyhne",
      "id": 2498
    }
  },
  "/sk/sklady/liaz-hall-8-512-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "liaz-hall-10-200-sq-m-velky-krtis",
      "id": 2040
    }
  },
  "/en/warehouses/liaz-hall-8-512-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "liaz-hall-10-200-sq-m-velky-krtis",
      "id": 2040
    }
  },
  "/sk/sklady/velka-ida-logistics-park-9-870-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "velka-ida-logistics-park-3-2-ha-velka-ida",
      "id": 2189
    }
  },
  "/en/warehouses/velka-ida-hall-9-870-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "velka-ida-logistics-park-3-2-ha-velka-ida",
      "id": 2189
    }
  },
  "/sk/sklady/sklad-ruzinov-840-sq-m-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-ruzinov-400-sq-m-bratislava",
      "id": 2412
    }
  },
  "/en/warehouses/sklad-ruzinov-840-sq-m-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "sklad-ruzinov-400-sq-m-bratislava",
      "id": 2412
    }
  },
  "/sk/sklady/vyrobna-hala-s-najomcom-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobna-hala-s-najomcom-v-presove-presov",
      "id": 2328
    }
  },
  "/en/warehouses/investicna-prilezitost-predaj-vyrobnej-haly-s-najomcom-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vyrobna-hala-s-najomcom-v-presove-presov",
      "id": 2328
    }
  },
  "/sk/sklady/stara-vajnorska-621-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-621-m2-bratislava",
      "id": 2578
    }
  },
  "/en/warehouses/stara-vajnorska-621-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "stara-vajnorska-621-m2-bratislava",
      "id": 2578
    }
  },
  "/sk/sklady/contera-park-bratislava-911-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-911-m2-bratislava",
      "id": 2415
    }
  },
  "/en/warehouses/contera-park-bratislava-911-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "contera-park-bratislava-911-m2-bratislava",
      "id": 2415
    }
  },
  "/sk/sklady/raca-bratislava-iii-800-m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-400-m-bratislava",
      "id": 2552
    }
  },
  "/en/warehouses/raca-bratislava-iii-800-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "raca-bratislava-iii-400-m-bratislava",
      "id": 2552
    }
  },
  "/sk/sklady/vajnorska-bratislava-iii-532-m2-sk-1": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-316-m2-bratislava",
      "id": 2362
    }
  },
  "/en/warehouses/vajnorska-bratislava-iii-316-m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "vajnorska-bratislava-iii-316-m2-bratislava",
      "id": 2362
    }
  },
  "/sk/sklady/ctpark-kittsee-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kittsee-bratislava",
      "id": 2549
    }
  },
  "/en/warehouses/ctpark-kittsee-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-kittsee-bratislava",
      "id": 2549
    }
  },
  "/sk/sklady/glp-airport-bratislava-130-000m2-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-airport-bratislava-130-000m2-bratislava",
      "id": 2587
    }
  },
  "/en/warehouses/glp-airport-bratislava-130-000m2-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "glp-airport-bratislava-130-000m2-bratislava",
      "id": 2587
    }
  },
  "/sk/sklady/ctpark-bratislava-bra16-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra16-bratislava",
      "id": 2539
    }
  },
  "/en/warehouses/ctpark-bratislava-bra16-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "ctpark-bratislava-bra16-bratislava",
      "id": 2539
    }
  },
  "/sk/sklady/bojnicka-skladovy-priestor-sk": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-skladovy-priestor-bratislava",
      "id": 2292
    }
  },
  "/en/warehouses/bojnicka-656-m2-skladovy-priestor-en": {
    "name": "warehouses-slug-id",
    "params": {
      "slug": "bojnicka-skladovy-priestor-bratislava",
      "id": 2292
    }
  },
  "/sk/obchodne-priestory/aupark-zilina-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-zilina-zilina",
      "id": 1832
    }
  },
  "/en/retail/aupark-zilina-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-zilina-zilina",
      "id": 1832
    }
  },
  "/sk/obchodne-priestory/oc-max-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-max-poprad-poprad",
      "id": 1858
    }
  },
  "/en/retail/oc-max-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-max-poprad-poprad",
      "id": 1858
    }
  },
  "/sk/obchodne-priestory/oc-laugaricio-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-laugaricio-trencin",
      "id": 2157
    }
  },
  "/en/retail/oc-laugaricio-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-laugaricio-trencin",
      "id": 2157
    }
  },
  "/sk/obchodne-priestory/max-trencin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-trencin-trencin",
      "id": 2668
    }
  },
  "/en/retail/max-trencin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-trencin-trencin",
      "id": 2668
    }
  },
  "/sk/obchodne-priestory/ozc-juzanka-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "ozc-juzanka-trencin",
      "id": 2294
    }
  },
  "/en/retail/ozc-juzanka-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "ozc-juzanka-trencin",
      "id": 2294
    }
  },
  "/sk/obchodne-priestory/m-park-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "m-park-povazska-bystrica",
      "id": 2664
    }
  },
  "/en/retail/m-park-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "m-park-povazska-bystrica",
      "id": 2664
    }
  },
  "/sk/obchodne-priestory/oc-korzo-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-korzo-prievidza",
      "id": 1634
    }
  },
  "/en/retail/oc-korzo-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-korzo-prievidza",
      "id": 1634
    }
  },
  "/sk/obchodne-priestory/galeria-martin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-martin-martin",
      "id": 2313
    }
  },
  "/en/retail/galeria-martin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-martin-martin",
      "id": 2313
    }
  },
  "/sk/obchodne-priestory/procentrum-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "procentrum-prievidza",
      "id": 1609
    }
  },
  "/en/retail/procentrum-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "procentrum-prievidza",
      "id": 1609
    }
  },
  "/sk/obchodne-priestory/oc-atrum-duben-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-atrium-duben-zilina",
      "id": 2636
    }
  },
  "/en/retail/oc-atrum-duben-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-atrium-duben-zilina",
      "id": 2636
    }
  },
  "/sk/obchodne-priestory/mlyny-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-mlyny-nitra",
      "id": 1860
    }
  },
  "/en/retail/mlyny-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-mlyny-nitra",
      "id": 1860
    }
  },
  "/sk/obchodne-priestory/oc-centro-nitra-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "n-centro-oc-centro-nitra-nitra",
      "id": 1608
    }
  },
  "/en/retail/oc-centro-nitra-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "n-centro-oc-centro-nitra-nitra",
      "id": 1608
    }
  },
  "/sk/obchodne-priestory/aupark-piestany-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-piestany-piestany",
      "id": 2293
    }
  },
  "/en/retail/aupark-piestany-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-piestany-piestany",
      "id": 2293
    }
  },
  "/sk/obchodne-priestory/city-arena-trnava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-arena-trnava-trnava",
      "id": 2677
    }
  },
  "/en/retail/city-arena-trnava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-arena-trnava-trnava",
      "id": 2677
    }
  },
  "/sk/obchodne-priestory/eperia-shopping-mall-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "eperia-shopping-mall-presov",
      "id": 2297
    }
  },
  "/en/retail/eperia-shopping-mall-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "eperia-shopping-mall-presov",
      "id": 2297
    }
  },
  "/sk/obchodne-priestory/europa-sc-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "europa-sc-banska-bystrica",
      "id": 1831
    }
  },
  "/en/retail/europa-sc-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "europa-sc-banska-bystrica",
      "id": 1831
    }
  },
  "/sk/obchodne-priestory/tulip-center-martin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-tulip-martin",
      "id": 2652
    }
  },
  "/en/retail/tulip-center-martin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-tulip-martin",
      "id": 2652
    }
  },
  "/sk/obchodne-priestory/mirage-shopping-center-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "mirage-shopping-center-zilina",
      "id": 2158
    }
  },
  "/en/retail/mirage-shopping-center-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "mirage-shopping-center-zilina",
      "id": 2158
    }
  },
  "/sk/obchodne-priestory/zoc-max-zilina-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-zilina-zilina",
      "id": 2692
    }
  },
  "/en/retail/zoc-max-zilina-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-zilina-zilina",
      "id": 2692
    }
  },
  "/sk/obchodne-priestory/aquario-shopping-center-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aquario-shopping-center-nove-zamky-nove-zamky",
      "id": 2639
    }
  },
  "/en/retail/aquario-shopping-center-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aquario-shopping-center-nove-zamky-nove-zamky",
      "id": 2639
    }
  },
  "/sk/obchodne-priestory/rgb-liptov-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "rgb-liptov-liptovsky-mikulas",
      "id": 2296
    }
  },
  "/en/retail/rgb-liptov-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "rgb-liptov-liptovsky-mikulas",
      "id": 2296
    }
  },
  "/sk/obchodne-priestory/slnecnice-market-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "slnecnice-market-bratislava",
      "id": 2192
    }
  },
  "/en/retail/slnecnice-market-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "slnecnice-market-bratislava",
      "id": 2192
    }
  },
  "/sk/obchodne-priestory/stop-shop-bratislava-raca-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-bratislava-raca-bratislava",
      "id": 1843
    }
  },
  "/en/retail/stop-shop-bratislava-raca-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-bratislava-raca-bratislava",
      "id": 1843
    }
  },
  "/sk/obchodne-priestory/stop-shop-ruzomberok-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-ruzomberok-ruzomberok",
      "id": 2306
    }
  },
  "/en/retail/stop-shop-ruzomberok-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-ruzomberok-ruzomberok",
      "id": 2306
    }
  },
  "/sk/obchodne-priestory/stop-shop-zvolen-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-zvolen-zvolen",
      "id": 2308
    }
  },
  "/en/retail/stop-shop-zvolen-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-zvolen-zvolen",
      "id": 2308
    }
  },
  "/sk/obchodne-priestory/stop-shop-bardejov-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-bardejov-bardejov",
      "id": 2309
    }
  },
  "/en/retail/stop-shop-bardejov-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-bardejov-bardejov",
      "id": 2309
    }
  },
  "/sk/obchodne-priestory/stop-shop-nove-zamky-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-nove-zamky-nove-zamky",
      "id": 2683
    }
  },
  "/en/retail/stop-shop-nove-zamky-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-nove-zamky-nove-zamky",
      "id": 2683
    }
  },
  "/sk/obchodne-priestory/zvolen-retail-park-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-park-zvolen-zvolen",
      "id": 2179
    }
  },
  "/en/retail/zvolen-retail-park-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-park-zvolen-zvolen",
      "id": 2179
    }
  },
  "/sk/obchodne-priestory/stop-shop-puchov-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-puchov-puchov",
      "id": 1622
    }
  },
  "/en/retail/stop-shop-puchov-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-puchov-puchov",
      "id": 1622
    }
  },
  "/sk/obchodne-priestory/rgb-javorina-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "rgb-javorina-nove-mesto-nad-vahom",
      "id": 2312
    }
  },
  "/en/retail/rgb-javorina-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "rgb-javorina-nove-mesto-nad-vahom",
      "id": 2312
    }
  },
  "/sk/obchodne-priestory/bratislava-business-center-v-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "bratislava-business-center-v-bratislava",
      "id": 2182
    }
  },
  "/en/retail/bratislava-business-center-v-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "bratislava-business-center-v-bratislava",
      "id": 2182
    }
  },
  "/sk/obchodne-priestory/premiiere-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "premiere-bratislava",
      "id": 1854
    }
  },
  "/en/retail/premiiere-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "premiere-bratislava",
      "id": 1854
    }
  },
  "/sk/obchodne-priestory/the-mall-trnava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "the-mall-trnava-trnava",
      "id": 1841
    }
  },
  "/en/retail/the-mall-trnava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "the-mall-trnava-trnava",
      "id": 1841
    }
  },
  "/sk/obchodne-priestory/stop-shop-liptovsky-mikulas-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-liptovsky-mikulas-liptovsky-mikulas",
      "id": 1623
    }
  },
  "/en/retail/stop-shop-liptovsky-mikulas-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-liptovsky-mikulas-liptovsky-mikulas",
      "id": 1623
    }
  },
  "/sk/obchodne-priestory/stop-shop-roznava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-roznava-roznava",
      "id": 1613
    }
  },
  "/en/retail/stop-shop-roznava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-roznava-roznava",
      "id": 1613
    }
  },
  "/sk/obchodne-priestory/stop-shop-dolny-kubin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-dolny-kubin-dolny-kubin",
      "id": 1605
    }
  },
  "/en/retail/stop-shop-dolny-kubin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-dolny-kubin-dolny-kubin",
      "id": 1605
    }
  },
  "/sk/obchodne-priestory/stop-shop-dubnica-nad-vahom-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-dubnica-nad-vahom-dubnica-nad-vahom",
      "id": 2660
    }
  },
  "/en/retail/stop-shop-dubnica-nad-vahom-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-dubnica-nad-vahom-dubnica-nad-vahom",
      "id": 2660
    }
  },
  "/sk/obchodne-priestory/stop-shop-presov-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-presov-presov",
      "id": 2657
    }
  },
  "/en/retail/stop-shop-presov-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-presov-presov",
      "id": 2657
    }
  },
  "/sk/obchodne-priestory/stop-shop-lucenec-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-lucenec-lucenec",
      "id": 1606
    }
  },
  "/en/retail/stop-shop-lucenec-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-lucenec-lucenec",
      "id": 1606
    }
  },
  "/sk/obchodne-priestory/stop-shop-michalovce-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-michalovce-michalovce",
      "id": 2161
    }
  },
  "/en/retail/stop-shop-michalovce-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-michalovce-michalovce",
      "id": 2161
    }
  },
  "/sk/obchodne-priestory/stop-shop-trencin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-trencin-trencin",
      "id": 2642
    }
  },
  "/en/retail/stop-shop-trencin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-trencin-trencin",
      "id": 2642
    }
  },
  "/sk/obchodne-priestory/rgb-radvan-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "rgb-radvan-banska-bystrica",
      "id": 2154
    }
  },
  "/en/retail/rgb-radvan-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "rgb-radvan-banska-bystrica",
      "id": 2154
    }
  },
  "/sk/obchodne-priestory/retail-park-liptovsky-mikulas-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-park-liptovsky-mikulas-liptovsky-mikulas",
      "id": 2638
    }
  },
  "/en/retail/retail-park-liptovsky-mikulas-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-park-liptovsky-mikulas-liptovsky-mikulas",
      "id": 2638
    }
  },
  "/sk/obchodne-priestory/stop-shop-poprad-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-poprad-poprad",
      "id": 1834
    }
  },
  "/en/retail/stop-shop-poprad-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-poprad-poprad",
      "id": 1834
    }
  },
  "/sk/obchodne-priestory/aupark-bratislava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-bratislava-bratislava",
      "id": 1855
    }
  },
  "/en/retail/aupark-bratislava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-bratislava-bratislava",
      "id": 1855
    }
  },
  "/sk/obchodne-priestory/aupark-kosice-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-kosice-kosice",
      "id": 2304
    }
  },
  "/en/retail/aupark-kosice-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "aupark-kosice-kosice",
      "id": 2304
    }
  },
  "/sk/obchodne-priestory/avion-shopping-park-bratislava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "avion-shopping-park-bratislava-bratislava",
      "id": 2183
    }
  },
  "/en/retail/avion-shopping-park-bratislava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "avion-shopping-park-bratislava-bratislava",
      "id": 2183
    }
  },
  "/sk/obchodne-priestory/bory-mall-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "bory-mall-bratislava",
      "id": 1614
    }
  },
  "/en/retail/bory-mall-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "bory-mall-bratislava",
      "id": 1614
    }
  },
  "/sk/obchodne-priestory/cassovia-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-cassovia-kosice",
      "id": 1611
    }
  },
  "/en/retail/cassovia-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-cassovia-kosice",
      "id": 1611
    }
  },
  "/sk/obchodne-priestory/central-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "central-bratislava",
      "id": 2653
    }
  },
  "/en/retail/central-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "central-bratislava",
      "id": 2653
    }
  },
  "/sk/obchodne-priestory/eurovea-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "eurovea-bratislava",
      "id": 2164
    }
  },
  "/en/retail/eurovea-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "eurovea-bratislava",
      "id": 2164
    }
  },
  "/sk/obchodne-priestory/optima-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "atrium-optima-kosice-kosice",
      "id": 2156
    }
  },
  "/en/retail/optima-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "atrium-optima-kosice-kosice",
      "id": 2156
    }
  },
  "/sk/obchodne-priestory/vivo-bratislava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "vivo-bratislava-bratislava",
      "id": 2310
    }
  },
  "/en/retail/vivo-bratislava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "vivo-bratislava-bratislava",
      "id": 2310
    }
  },
  "/sk/obchodne-priestory/stara-sladovna-bratislava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stara-sladovna-bratislava-bratislava",
      "id": 2307
    }
  },
  "/en/retail/stara-sladovna-bratislava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stara-sladovna-bratislava-bratislava",
      "id": 2307
    }
  },
  "/sk/obchodne-priestory/od-prior-trencin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "od-prior-trencin-trencin",
      "id": 2655
    }
  },
  "/en/retail/od-prior-trencin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "od-prior-trencin-trencin",
      "id": 2655
    }
  },
  "/sk/obchodne-priestory/obchodne-centrum-myjava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "storia-myjava-myjava",
      "id": 1628
    }
  },
  "/en/retail/obchodne-centrum-myjava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "storia-myjava-myjava",
      "id": 1628
    }
  },
  "/sk/obchodne-priestory/nc-partizanske-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "intercora-partizanske-partizanske",
      "id": 1837
    }
  },
  "/en/retail/nc-partizanske-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "intercora-partizanske-partizanske",
      "id": 1837
    }
  },
  "/sk/obchodne-priestory/family-center-povazska-bystrica-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-povazska-bystrica-povazska-bystrica",
      "id": 1629
    }
  },
  "/en/retail/family-center-povazska-bystrica-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-povazska-bystrica-povazska-bystrica",
      "id": 1629
    }
  },
  "/sk/obchodne-priestory/stop-shop-prievidza-sk-1": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-prievidza-prievidza",
      "id": 2678
    }
  },
  "/en/retail/stop-shop-prievidza-en-1": {
    "name": "retail-slug-id",
    "params": {
      "slug": "stop-shop-prievidza-prievidza",
      "id": 2678
    }
  },
  "/sk/obchodne-priestory/campo-di-martin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "campo-di-martin-martin",
      "id": 2301
    }
  },
  "/en/retail/campo-di-martin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "campo-di-martin-martin",
      "id": 2301
    }
  },
  "/sk/obchodne-priestory/oc-max-nitra-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-max-nitra-nitra",
      "id": 1633
    }
  },
  "/en/retail/oc-max-nitra-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-max-nitra-nitra",
      "id": 1633
    }
  },
  "/sk/obchodne-priestory/oc-galeria-nitra-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-nitra-nitra",
      "id": 2184
    }
  },
  "/en/retail/oc-galeria-nitra-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-nitra-nitra",
      "id": 2184
    }
  },
  "/sk/obchodne-priestory/oc-family-centre-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-nitra-nitra",
      "id": 1853
    }
  },
  "/en/retail/oc-family-centre-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-nitra-nitra",
      "id": 1853
    }
  },
  "/sk/obchodne-priestory/dituria-levice-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "dituria-levice-levice",
      "id": 2654
    }
  },
  "/en/retail/dituria-levice-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "dituria-levice-levice",
      "id": 2654
    }
  },
  "/sk/obchodne-priestory/oc-zemplin-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-zemplin-michalovce",
      "id": 2647
    }
  },
  "/en/retail/oc-zemplin-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-zemplin-michalovce",
      "id": 2647
    }
  },
  "/sk/obchodne-priestory/terminal-banska-bystrica-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "terminal-banska-bystrica-banska-bystrica",
      "id": 2634
    }
  },
  "/en/retail/terminal-banska-bystrica-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "terminal-banska-bystrica-banska-bystrica",
      "id": 2634
    }
  },
  "/sk/obchodne-priestory/point-sc-banska-bystrica-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-point-banska-bystrica-banska-bystrica",
      "id": 1627
    }
  },
  "/en/retail/point-sc-banska-bystrica-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-point-banska-bystrica-banska-bystrica",
      "id": 1627
    }
  },
  "/sk/obchodne-priestory/danubia-bratislava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "danubia-bratislava-bratislava",
      "id": 2690
    }
  },
  "/en/retail/danubia-bratislava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "danubia-bratislava-bratislava",
      "id": 2690
    }
  },
  "/sk/obchodne-priestory/oc-malavia-malacky-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-malavia-malacky-malacky",
      "id": 2643
    }
  },
  "/en/retail/oc-malavia-malacky-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-malavia-malacky-malacky",
      "id": 2643
    }
  },
  "/sk/obchodne-priestory/city-park-rovinka-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-park-rovinka-rovinka",
      "id": 1835
    }
  },
  "/en/retail/city-park-rovinka-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-park-rovinka-rovinka",
      "id": 1835
    }
  },
  "/sk/obchodne-priestory/family-center-dunajska-streda-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-dunajska-streda-dunajska-streda",
      "id": 2659
    }
  },
  "/en/retail/family-center-dunajska-streda-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-dunajska-streda-dunajska-streda",
      "id": 2659
    }
  },
  "/sk/obchodne-priestory/oc-galeria-trnava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-trnava-trnava",
      "id": 2170
    }
  },
  "/en/retail/oc-galeria-trnava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-trnava-trnava",
      "id": 2170
    }
  },
  "/sk/obchodne-priestory/family-center-piestany-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-piestany-piestany",
      "id": 1616
    }
  },
  "/en/retail/family-center-piestany-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-piestany-piestany",
      "id": 1616
    }
  },
  "/sk/obchodne-priestory/family-center-senica-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-senica-senica",
      "id": 2669
    }
  },
  "/en/retail/family-center-senica-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-senica-senica",
      "id": 2669
    }
  },
  "/sk/obchodne-priestory/oc-forum-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-forum-poprad",
      "id": 2295
    }
  },
  "/en/retail/oc-forum-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-forum-poprad",
      "id": 2295
    }
  },
  "/sk/obchodne-priestory/galeria-lucenec-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-lucenec-lucenec",
      "id": 1612
    }
  },
  "/en/retail/galeria-lucenec-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-lucenec-lucenec",
      "id": 1612
    }
  },
  "/sk/obchodne-priestory/oc-galeria-kosice-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-kosice-kosice",
      "id": 2160
    }
  },
  "/en/retail/oc-galeria-kosice-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-kosice-kosice",
      "id": 2160
    }
  },
  "/sk/obchodne-priestory/cassovar-galeria-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "cassovar-galeria-kosice",
      "id": 2298
    }
  },
  "/en/retail/cassovar-galeria-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "cassovar-galeria-kosice",
      "id": 2298
    }
  },
  "/sk/obchodne-priestory/arkadia-trnava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "arkadia-trnava-trnava",
      "id": 1833
    }
  },
  "/en/retail/arkadia-trnava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "arkadia-trnava-trnava",
      "id": 1833
    }
  },
  "/sk/obchodne-priestory/zvolenska-europa-shopping-center-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zvolenska-europa-shopping-center-zvolen",
      "id": 2299
    }
  },
  "/en/retail/zvolenska-europa-shopping-center-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zvolenska-europa-shopping-center-zvolen",
      "id": 2299
    }
  },
  "/sk/obchodne-priestory/shopping-palace-zlate-piesky-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "shopping-palace-zlate-piesky-bratislava",
      "id": 1631
    }
  },
  "/en/retail/shopping-palace-zlate-piesky-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "shopping-palace-zlate-piesky-bratislava",
      "id": 1631
    }
  },
  "/sk/obchodne-priestory/zoc-max-presov-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-presov-presov",
      "id": 2680
    }
  },
  "/en/retail/zoc-max-presov-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-presov-presov",
      "id": 2680
    }
  },
  "/sk/obchodne-priestory/city-market-ruzomberok-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-market-ruzomberok-ruzomberok",
      "id": 1615
    }
  },
  "/en/retail/city-market-ruzomberok-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-market-ruzomberok-ruzomberok",
      "id": 1615
    }
  },
  "/sk/obchodne-priestory/retail-box-samorin-kasarenska-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-box-samorin-kasarenska-samorin",
      "id": 1850
    }
  },
  "/en/retail/retail-box-samorin-kasarenska-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-box-samorin-kasarenska-samorin",
      "id": 1850
    }
  },
  "/sk/obchodne-priestory/retail-box-samorin-galantska-cesta-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-box-samorin-galantska-cesta-samorin",
      "id": 2178
    }
  },
  "/en/retail/retail-box-samorin-galantska-cesta-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retail-box-samorin-galantska-cesta-samorin",
      "id": 2178
    }
  },
  "/sk/obchodne-priestory/s1-center-cadca-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "s1-center-cadca-cadca",
      "id": 1845
    }
  },
  "/en/retail/s1-center-cadca-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "s1-center-cadca-cadca",
      "id": 1845
    }
  },
  "/sk/obchodne-priestory/city-park-levice-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-park-levice-levice",
      "id": 2163
    }
  },
  "/en/retail/city-park-levice-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-park-levice-levice",
      "id": 2163
    }
  },
  "/sk/obchodne-priestory/obchodne-centrum-s1-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "s1-center-sala-sala",
      "id": 1844
    }
  },
  "/en/retail/obchodne-centrum-s1-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "s1-center-sala-sala",
      "id": 1844
    }
  },
  "/sk/obchodne-priestory/family-center-vranov-nad-toplou-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-vranov-nad-toplou-vranov-nad-toplou",
      "id": 2305
    }
  },
  "/en/retail/family-center-vranov-nad-toplou-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "citymarket-vranov-nad-toplou-vranov-nad-toplou",
      "id": 2305
    }
  },
  "/sk/obchodne-priestory/family-center-komarno-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-komarno-komarno",
      "id": 1617
    }
  },
  "/en/retail/family-center-komarno-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-komarno-komarno",
      "id": 1617
    }
  },
  "/sk/obchodne-priestory/od-dargov-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "od-dargov-kosice",
      "id": 2635
    }
  },
  "/en/retail/od-dargov-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "od-dargov-kosice",
      "id": 2635
    }
  },
  "/sk/obchodne-priestory/shopbox-kosice-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "shopbox-kosice-kosice",
      "id": 2645
    }
  },
  "/en/retail/shopbox-kosice-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "shopbox-kosice-kosice",
      "id": 2645
    }
  },
  "/sk/obchodne-priestory/od-prior-banska-bystrica-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "od-prior-banska-bystrica-banska-bystrica",
      "id": 2175
    }
  },
  "/en/retail/od-prior-banska-bystrica-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "od-prior-banska-bystrica-banska-bystrica",
      "id": 2175
    }
  },
  "/sk/obchodne-priestory/s1-center-brezno-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "s1-center-brezno-brezno",
      "id": 2173
    }
  },
  "/en/retail/s1-center-brezno-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "s1-center-brezno-brezno",
      "id": 2173
    }
  },
  "/sk/obchodne-priestory/retail-box-detva-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-detva-detva",
      "id": 1630
    }
  },
  "/en/retail/retail-box-detva-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-detva-detva",
      "id": 1630
    }
  },
  "/sk/obchodne-priestory/galeria-komin-rimavska-sobota-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-komin-rimavska-sobota-rimavska-sobota",
      "id": 2311
    }
  },
  "/en/retail/galeria-komin-rimavska-sobota-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-komin-rimavska-sobota-rimavska-sobota",
      "id": 2311
    }
  },
  "/sk/obchodne-priestory/family-center-rimavska-sobota-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-rimavska-sobota-rimavska-sobota",
      "id": 2673
    }
  },
  "/en/retail/family-center-rimavska-sobota-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "family-center-rimavska-sobota-rimavska-sobota",
      "id": 2673
    }
  },
  "/sk/obchodne-priestory/korzo-shopping-park-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "korzo-shopping-park-bratislava",
      "id": 1618
    }
  },
  "/en/retail/korzo-shopping-park-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "korzo-shopping-park-bratislava",
      "id": 1618
    }
  },
  "/sk/obchodne-priestory/intercora-dubnicka-obchodna-galeria-primum-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "intercora-dubnicka-obchodna-galeria-primum-banovce-nad-bebravou",
      "id": 2165
    }
  },
  "/en/retail/intercora-dubnicka-obchodna-galeria-primum-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "intercora-dubnicka-obchodna-galeria-primum-banovce-nad-bebravou",
      "id": 2165
    }
  },
  "/sk/obchodne-priestory/intercora-nove-mesto-nad-vahom-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "intercora-nove-mesto-nad-vahom-nove-mesto-nad-vahom",
      "id": 1836
    }
  },
  "/en/retail/intercora-nove-mesto-nad-vahom-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "intercora-nove-mesto-nad-vahom-nove-mesto-nad-vahom",
      "id": 1836
    }
  },
  "/sk/obchodne-priestory/oc-galeria-lamac-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-lamac-bratislava",
      "id": 1857
    }
  },
  "/en/retail/oc-galeria-lamac-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-lamac-bratislava",
      "id": 1857
    }
  },
  "/sk/obchodne-priestory/zoc-max-skalica-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-skalica-skalica",
      "id": 1856
    }
  },
  "/en/retail/zoc-max-skalica-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-skalica-skalica",
      "id": 1856
    }
  },
  "/sk/obchodne-priestory/zoc-max-trnava-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-max-trnava-trnava",
      "id": 2686
    }
  },
  "/en/retail/zoc-max-trnava-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-max-trnava-trnava",
      "id": 2686
    }
  },
  "/sk/obchodne-priestory/oc-madaras-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-madaras-spisska-nova-ves",
      "id": 1859
    }
  },
  "/en/retail/oc-madaras-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-madaras-spisska-nova-ves",
      "id": 1859
    }
  },
  "/sk/obchodne-priestory/sc-point-brezno-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-point-brezno-brezno",
      "id": 2185
    }
  },
  "/en/retail/sc-point-brezno-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-point-brezno-brezno",
      "id": 2185
    }
  },
  "/sk/obchodne-priestory/oc-rspark-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-rspark-rimavska-sobota",
      "id": 2672
    }
  },
  "/en/retail/oc-rspark-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-rspark-rimavska-sobota",
      "id": 2672
    }
  },
  "/sk/obchodne-priestory/podchod-trnavske-myto-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "podchod-trnavske-myto-bratislava",
      "id": 2646
    }
  },
  "/en/retail/podchod-trnavske-myto-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "podchod-trnavske-myto-bratislava",
      "id": 2646
    }
  },
  "/sk/obchodne-priestory/oc-solivaria-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-solivaria-presov",
      "id": 2300
    }
  },
  "/en/retail/oc-solivaria-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-solivaria-presov",
      "id": 2300
    }
  },
  "/sk/obchodne-priestory/styla-shopping-center-former-shopping-palace-extension-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "styla-shopping-center-former-shopping-palace-extension-bratislava",
      "id": 2656
    }
  },
  "/en/retail/styla-shopping-center-former-shopping-palace-extension-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "styla-shopping-center-former-shopping-palace-extension-bratislava",
      "id": 2656
    }
  },
  "/sk/obchodne-priestory/oc-galeria-dunajska-streda-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-dunajska-streda-dunajska-streda",
      "id": 2637
    }
  },
  "/en/retail/oc-galeria-dunajska-streda-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-galeria-dunajska-streda-dunajska-streda",
      "id": 2637
    }
  },
  "/sk/obchodne-priestory/oc-kocka-modra-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-kocka-modra-modra",
      "id": 2667
    }
  },
  "/en/retail/oc-kocka-modra-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-kocka-modra-modra",
      "id": 2667
    }
  },
  "/sk/obchodne-priestory/oc-vajnoria-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-vajnoria-bratislava",
      "id": 1626
    }
  },
  "/en/retail/oc-vajnoria-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-vajnoria-bratislava",
      "id": 1626
    }
  },
  "/sk/obchodne-priestory/oc-cubicon-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-cubicon-bratislava",
      "id": 1848
    }
  },
  "/en/retail/oc-cubicon-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "oc-cubicon-bratislava",
      "id": 1848
    }
  },
  "/sk/obchodne-priestory/atrium-saratov-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "atrium-saratov-bratislava",
      "id": 2162
    }
  },
  "/en/retail/atrium-saratov-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "atrium-saratov-bratislava",
      "id": 2162
    }
  },
  "/sk/obchodne-priestory/city-gate-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-gate-bratislava",
      "id": 2288
    }
  },
  "/en/retail/city-gate-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "city-gate-bratislava",
      "id": 2288
    }
  },
  "/sk/obchodne-priestory/tesco-kamenne-namestie-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "tesco-kamenne-namestie-bratislava",
      "id": 2172
    }
  },
  "/en/retail/tesco-kamenne-namestie-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "tesco-kamenne-namestie-bratislava",
      "id": 2172
    }
  },
  "/sk/obchodne-priestory/retro-shopping-gallery-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retro-shopping-gallery-bratislava",
      "id": 1624
    }
  },
  "/en/retail/retro-shopping-gallery-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "retro-shopping-gallery-bratislava",
      "id": 1624
    }
  },
  "/sk/obchodne-priestory/vienna-gate-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "vienna-gate-bratislava",
      "id": 1621
    }
  },
  "/en/retail/vienna-gate-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "vienna-gate-bratislava",
      "id": 1621
    }
  },
  "/sk/obchodne-priestory/nc-hron-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "nc-hron-bratislava",
      "id": 2675
    }
  },
  "/en/retail/nc-hron-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "nc-hron-bratislava",
      "id": 2675
    }
  },
  "/sk/obchodne-priestory/zoc-max-dunajska-streda-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-dunajska-streda-dunajska-streda",
      "id": 1632
    }
  },
  "/en/retail/zoc-max-dunajska-streda-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "zoc-max-dunajska-streda-dunajska-streda",
      "id": 1632
    }
  },
  "/sk/obchodne-priestory/retail-box-nove-zamky-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-nove-zamky-nove-zamky",
      "id": 2186
    }
  },
  "/en/retail/retail-box-nove-zamky-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-nove-zamky-nove-zamky",
      "id": 2186
    }
  },
  "/sk/obchodne-priestory/lipapark-poprad-i-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-poprad-i-poprad",
      "id": 2166
    }
  },
  "/en/retail/lipapark-poprad-i-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-poprad-i-poprad",
      "id": 2166
    }
  },
  "/sk/obchodne-priestory/lipapark-poprad-ii-sk": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-poprad-ii-poprad",
      "id": 2670
    }
  },
  "/en/retail/lipapark-poprad-ii-en": {
    "name": "retail-slug-id",
    "params": {
      "slug": "lipapark-poprad-ii-poprad",
      "id": 2670
    }
  },
  "/en/offices/about-us": {
    "name": "about-us"
  },
  "/en/offices/about-us/our-clients": {
    "name": "about-us"
  },
  "/en/retail/about-us": {
    "name": "about-us"
  },
  "/sk/kancelare/o-nas": {
    "name": "about-us"
  },
  "/sk/kancelare/o-nas/komu-pomahame": {
    "name": "about-us"
  },
  "/sk/kancelare/o-nas/komu-pomuzeme": {
    "name": "about-us"
  },
  "/sk/kancelarie/o-nas": {
    "name": "about-us"
  },
  "/sk/kancelarie/o-nas/komu-pomahame": {
    "name": "about-us"
  },
  "/sk/kancelarie/o-nas/komu-pomuzeme": {
    "name": "about-us"
  },
  "/sk/obchodne-priestory/o-nas": {
    "name": "about-us"
  },
  "/en/offices/about-us/our-services": {
    "name": "our-services"
  },
  "/en/offices/about-us/our-services/tenant-representation": {
    "name": "our-services"
  },
  "/en/retail/about-us/our-services": {
    "name": "our-services"
  },
  "/sk/kancelare/o-nas/nase-sluzby": {
    "name": "our-services"
  },
  "/sk/kancelarie/o-nas/nase-sluzby": {
    "name": "our-services"
  },
  "/sk/obchodne-priestory/o-nas/nase-sluzby": {
    "name": "our-services"
  },
  "/en/offices/contact": {
    "name": "contact"
  },
  "/en/retail/contact": {
    "name": "contact"
  },
  "/en/warehouses/contact": {
    "name": "contact"
  },
  "/sk/kancelare/kontakt": {
    "name": "contact"
  },
  "/sk/kancelarie/kontakt": {
    "name": "contact"
  },
  "/sk/obchodne-priestory/kontakt": {
    "name": "contact"
  },
  "/sk/sklady/kontakt": {
    "name": "contact"
  },
  "/en/offices/about-us/our-team": {
    "name": "contact"
  },
  "/en/retail/about-us/our-team": {
    "name": "contact"
  },
  "/en/warehouses/about-us/our-team": {
    "name": "contact"
  },
  "/sk/kancelare/o-nas/nas-tim": {
    "name": "contact"
  },
  "/sk/kancelare/o-nas/nas-tym": {
    "name": "contact"
  },
  "/sk/kancelarie/o-nas/nas-tim": {
    "name": "contact"
  },
  "/sk/kancelarie/o-nas/nas-tym": {
    "name": "contact"
  },
  "/sk/obchodne-priestory/o-nas/nas-tim": {
    "name": "contact"
  },
  "/sk/sklady/o-nas/nas-tim": {
    "name": "contact"
  },
  "/sk/sklady/o-nas/nas-tym": {
    "name": "contact"
  },
  "/en/offices/favorites": {
    "name": "favorites"
  },
  "/en/warehouses/favorites": {
    "name": "favorites"
  },
  "/sk/kancelare/oblibene": {
    "name": "favorites"
  },
  "/sk/kancelare/oblubene": {
    "name": "favorites"
  },
  "/sk/kancelarie/oblibene": {
    "name": "favorites"
  },
  "/sk/kancelarie/oblubene": {
    "name": "favorites"
  },
  "/sk/sklady/oblibene": {
    "name": "favorites"
  },
  "/sk/sklady/oblubene": {
    "name": "favorites"
  },
  "/sk": {
    "name": "index"
  },
  "/sk/kancelare": {
    "name": "offices"
  },
  "/sk/kancelare/": {
    "name": "offices"
  },
  "/sk/kancelarie": {
    "name": "offices"
  },
  "/sk/kancelarie/": {
    "name": "offices"
  },
  "/sk/kancelarie/lokality": {
    "name": "offices"
  },
  "/en/offices/locations": {
    "name": "offices"
  },
  "/sk/kancelare/hladaj": {
    "name": "offices"
  },
  "/sk/kancelare/hledej": {
    "name": "offices"
  },
  "/sk/kancelarie/hladaj": {
    "name": "offices"
  },
  "/en/offices/offices-search": {
    "name": "offices"
  },
  "/sk/kancelarie/prenajom-kancelarie-banska-bystrica": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Bansk%C3%A1%20Bystrica%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-bratislava": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Bratislava%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-kosice": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Ko%C5%A1ice%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-kosice-juh": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Ko%C5%A1ice%20-%20Juh%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-nove-mesto": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Nov%C3%A9%20Mesto%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-petrzalka": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Petr%C5%BEalka%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-piestany": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Pie%C5%A1%C5%A5any%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-poprad": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Poprad%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-presov": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Pre%C5%A1ov%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-stare-mesto-kosice": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Star%C3%A9%20Mesto%20-%20Ko%C5%A1ice%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-stary-ruzinov": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22Star%C3%BD%20Ru%C5%BEinov%22%7D"
    }
  },
  "/sk/kancelarie/prenajom-kancelarie-zilina": {
    "name": "offices",
    "query": {
      "filter": "%7B%22search%22%3A%22%C5%BDilina%22%7D"
    }
  },
  "/sk/sklady": {
    "name": "warehouses"
  },
  "/sk/sklady/lokality": {
    "name": "warehouses"
  },
  "/en/warehouses/locations": {
    "name": "warehouses"
  },
  "/sk/sklady/prenajom-skladu-banska-bystrica": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Bansk%C3%A1%20Bystrica%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-bratislava": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Bratislava%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-bratislavsky-kraj": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Bratislavsk%C3%BD%20kraj%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-kosice": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Ko%C5%A1ice%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-nitra": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Nitra%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-presov": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Pre%C5%A1ov%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-trencin": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Tren%C4%8D%C3%ADn%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-trnava": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22Trnava%22%7D"
    }
  },
  "/sk/sklady/prenajom-skladu-zilina": {
    "name": "warehouses",
    "query": {
      "filter": "%7B%22search%22%3A%22%C5%BDilina%22%7D"
    }
  },
  "/sk/sklady/hladaj": {
    "name": "warehouses"
  },
  "/sk/sklady/hledej": {
    "name": "warehouses"
  },
  "/en/warehouses/search": {
    "name": "warehouses"
  },
  "/sk/obchodne-priestory": {
    "name": "retail"
  },
  "/sk/obchodne-priestory/lokality": {
    "name": "retail"
  },
  "/en/retail/locations": {
    "name": "retail"
  },
  "/sk/obchodne-priestory/hladaj": {
    "name": "retail"
  },
  "/en/retail/retail-search": {
    "name": "retail"
  },
  "/sk/kancelarie/novinky": {
    "name": "news"
  },
  "/en/offices/news": {
    "name": "news"
  },
  "/sk/sklady/novinky": {
    "name": "news"
  },
  "/en/warehouses/news": {
    "name": "news"
  },
  "/sk/obchodne-priestory/novinky": {
    "name": "news"
  },
  "/en/retail/news": {
    "name": "news"
  }
}
export type PreparedRedirects = typeof preparedRedirects
